import { makeStyles, createStyles } from '@material-ui/core/styles';

const DebugPopupStyles = makeStyles((theme: any) =>
  createStyles({
    debugPopup: {
      width: 320,
      minHeight: 150,
      height: "auto",
      position: "absolute",
      top: 50,
      right: 430,
      zIndex: 10,
      maxHeight: "80vh",
      overflowY: "auto",
      overflowX: "hidden",
      borderRadius: 10,
      display: "inline-block",
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.down("md")]: {
        width: 260,
      },
      [theme.breakpoints.down("sm")]: {
        width: 240,
      },
    },
    debugPopupTransition: {},
    debugPopupRightOpen: {
      right: 430,
      [theme.breakpoints.down("md")]: {
        right: 390,
      },
      [theme.breakpoints.down("sm")]: {
        right: 340,
      },
    },
    debugPopupHeaderHolder: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: 30,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      backgroundColor: theme.palette.plain.main,
    },
    debugPopupHeader: {
      fontSize: 14,
      fontWeight: 500,
      position: "absolute",
      left: 20,
      top: 8,
    },
    deviceHeader: {
      fontSize: 14,
      fontWeight: 700,
      marginBottom: 10,
      color: "#FFCE00",
    },
    deviceName: {
      fontSize: 14,
      fontWeight: 400,
      position: "relative",
      marginBottom: 7,
    },
    deviceNameSelected: {
      marginBottom: 7,
      width: "91%",
      [theme.breakpoints.down("md")]: {
        width: "85%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "91%",
      },
    },
    selectedDeviceIcon: {
      color: "green",
      position: "relative",
      fontSize: 20,
      top: 5,
      left: 10,
      marginTop: -10,
    },
    selectedDeviceIconDisabled: {
      color: "red",
    },
    loadingSpinner: {
      color: "grey",
      position: "absolute",
      left: "50%",
      marginLeft: -20,
      top: 84,
    },
    minimizeButton: {
      padding: 5,
      position: "absolute",
      top: -1,
      right: 10,
    },
    debugPopupMinimize: {
      height: 30,
      overflowY: "hidden",
      minHeight: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
    advancedLoggingToggle: {
      position: "absolute",
      left: 4,
      top: 38,
      [theme.breakpoints.down("md")]: {
        left: -1
      }
    },
    advancedLoggingLabel: {
      fontSize: 14,
      fontWeight: 700,
      marginBottom: 10,
      color: "#FFCE00",
      position: "relative",
      top: 4,
    },
    advancedLoggingSwitch: {
      position: "relative",
      left: 100,
      [theme.breakpoints.down("md")]: {
        left: 45,
      },
      [theme.breakpoints.down("sm")]: {
        left: 25,
      }
    }
  })
);

export { DebugPopupStyles };