import { makeStyles, createStyles } from '@material-ui/core/styles';

const ChatSidebarStyles = makeStyles((theme: any) => createStyles({
  chatHeaderHolder: {
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    height: 40,
    position: "relative",
  },
  chatIcon: {
    position: "absolute",
    left: 10,
    top: 7,
    color: "white"
  },
  chatHeader: {
    fontSize: 16,
    fontWeight: 400,
    position: "absolute",
    left: 43,
    top: 10,
    color: "white"
  },
  closeIconButton: {
    padding: 4,
    position: 'absolute',
    top: 3,
    right: 7,
    color: "white"
  },
  chatMessageInputHolder: {
    backgroundColor: theme.palette.controlCenterButton.main,
    position: "absolute",
    bottom: -5,
    left: -5,
    width: 290,
    height: 128,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    zIndex: 15,
  },
  chatInputHolderBackdrop: {
    backgroundColor: theme.palette.controlCenterButton.main,
    position: "absolute",
    width: "100%",
    bottom: 0,
    left: 0,
    height: 123,
    zIndex: 15,
  },
  chatHelperText: {
    fontSize: 12,
    color: theme.palette.text.main,
    position: "absolute",
    left: 10,
    bottom: 12,
  },
  chatTextField: {
    width: 280,
    height: 74,
    top: 15,
    border: "none",
    outline: "none",
    backgroundColor: theme.palette.chatInputBackground.main,
    borderRadius: 4,
    fontSize: 14,
    
  },
  noBorder: {
    border: "none"
  },
  emojiIcon: {
    position: "relative",
    bottom: 16,
    color: theme.palette.emojiIcon.main,
    "&:hover": {
      cursor: "pointer"
    }
  },
  chatInput: {
    fontSize: 14,
    paddingTop: 14
  },
  chatInputAdornedEnd: {
    paddingRight: 5,
  }
})
)

export { ChatSidebarStyles };