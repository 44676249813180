import { makeStyles, createStyles } from '@material-ui/core/styles';

const AttendeeMuteIconStyles = makeStyles((theme: any) => createStyles({
  muteIcon: {
    fontSize: 24,
    color: theme.palette.text.main,
    position: "absolute",
    top: 6,
    right: 42,
    [theme.breakpoints.down('sm')]: {
      right: 37,
    }
  },
  muteIconVoiceActivity: {
    color: "#FF0092"
  }
})
)

export { AttendeeMuteIconStyles };