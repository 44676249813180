import { makeStyles, createStyles } from '@material-ui/core/styles';

const PageLayoutStyles = makeStyles((theme: any) => createStyles({
  intakeWrapper: {
    backgroundColor: theme.palette.intakeBackground.main,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    boxShadow: "none",
    textAlign: "center",
    overflowY: "auto",
    overflowX: "hidden",
    [theme.breakpoints.down('sm')]: {
    },
  },
  intakeContentHolder: {
    position: "relative",
    float: "right",
    /* top: 100, */
    top: 133,
    width: 560,
    minHeight: 50,
    backgroundColor: theme.palette.intakePaper.main,
    textAlign: "left",
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      width: 400,
    }
  },
  errorTestButton: {
    position: "absolute",
    left: "50%",
    top: 20,
    zIndex: 20,
    marginLeft: -50,
  },
  eventEndingTestButton: {
    position: "absolute",
    left: "50%",
    top: 40,
    zIndex: 20,
    marginLeft: -50,
  },
  eventEndedTestButton: {
    position: "absolute",
    left: "50%",
    top: 100,
    zIndex: 20,
    marginLeft: -50,
  },
  eventEndedTestButton2: {
    top: 140,
  },
  newRoomTestButton: {
    position: "absolute",
    left: "50%",
    top: 180,
    zIndex: 20,
    marginLeft: -50,
  },
  newRoomTestButton2: {
    top: 220
  },
  messageTestButton: {
    position: "absolute",
    left: "50%",
    zIndex: 20,
    marginLeft: -50,
    top: 180,
  },
  testButtonTwo: {
    position: "absolute",
    zIndex: 10,
    right: 0,
    top: 0,
  },
  testButtonThree: {
    position: "absolute",
    top: 0,
    zIndex: 10,
    right: 0,
  },
  box: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  errorPopup: {
    width: 400,
    position: "absolute",
    top: 20,
    left: "50%",
    marginLeft: -200,
    display: "inline-block",
    textAlign: "center"
  },
  errorPopupContent: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  dismissButton: {
    position: "absolute",
    right: 10,
    top: 6,
    width: 93,
    height: 36,
  }
  
})
)

export { PageLayoutStyles };