import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const EventEndingPopupStyles = makeStyles((theme: Theme) => createStyles({
  eventEndingPopup: {
    position: "absolute",
    textAlign: "left",
    marginBottom: 10,
    top: 10,
    left: 0,
    width: 311,
    height: 90,
    display: "none",
    background: theme.palette.background.paper,
    pointerEvents: "all",
    borderRadius: 10,
  },
  eventEndingText: {
    fontSize: 15,
    fontWeight: 400,
    color: theme.palette.text.primary,
    position: "absolute",
    width: 200,
    wordWrap: "break-word",
    left: 24,
    top: 15,
  },
  dismissButton: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },

})
)

export { EventEndingPopupStyles };