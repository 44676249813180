import React, { useState, useEffect } from 'react';
import { StringVariableHelper } from '../Utilities/StringVariableHelper';

export interface PresenterContextType {
  isAudienceMuted : boolean,
  toggleIsAudienceMuted : (isAudienceMuted : boolean) => void,
  isEventMuted : boolean,
  toggleIsEventMuted : (isEventMuted : boolean) => void,
  isEventBroadcast : boolean,
  toggleIsEventBroadcast : (isEventBroadcast) => void,
  isOnStage : boolean,
  toggleIsOnStage : (isOnStage : boolean) => void,
  isMenuOpen : boolean,
  toggleIsMenuOpen : (isMenuOpen : boolean) => void,
  isSharingScreen : boolean,
  toggleIsSharingScreen : (isSharingScreen : boolean) => void,
  playLocalVideo : boolean,
  togglePlayLocalVideo : (playLocalVideo : boolean) => void,
  currentSlide : number,
  setCurrentSlide : (slideIndex : number) => void,
  isPresentingSlides : boolean,
  toggleIsPresentingSlides : (isPresentingSlides : boolean) => void,
  currentMode : string,
  setCurrentMode : (mode : string) => void,
  contextHandleNextSlideButtonClick : () => void,
  contextHandlePreviousSlideButtonClick : () => void,
  laserPointer : boolean,
  toggleLaserPointer : (isLaserPointerActivated : boolean) => void,
  isBackstage : boolean,
  toggleIsBackstage : (isBackstage : boolean) => void,
  isPresentingWebcam : boolean,
  toggleIsPresentingWebcam : (isPresentingWebcam : boolean) => void,
  slidePreview : boolean,
  toggleSlidePreview : (showSlidePreview : boolean) => void,
  webcamPreview : boolean,
  toggleWebcamPreview : (showWebcamPreview : boolean) => void,
  collapsePresenterMenu : boolean,
  toggleCollapsePresenterMenu : (collapseMenu : boolean) => void,
  jumbotronView : boolean,
  toggleJumbotronView : (showJumbotronView : boolean) => void,
  showShowboatConnectTool : boolean,
  toggleShowShowboatConnectTool : (showShowboatConnect : boolean) => void,
  streamShowboatConnect : boolean,
  toggleStreamShowboatConnect : (streamShowboatConnect : boolean) => void,
  infiniteAudio : boolean,
  toggleInfiniteAudio : (activateInfiniteAudio : boolean) => void,
  defaultScreen : boolean,
  toggleDefaultScreen : (showDefaultScreen : boolean) => void,
  shareLivestream : boolean,
  toggleShareLivestream: (shareLivestream : boolean) => void,
  currentPresenterTool: string,
  setCurrentPresenterTool : (presenterTool : string) => void,
  optimizeScreenshareForVideo : boolean,
  toggleOptimizeScreenshareForVideo: ( optimize : boolean) => void,
  livestreamURLContext: string,
  setLivestreamURLContext: (livestreamURL: string) => void,
  selectedPresentation: any,
  setSelectedPresentation: (presentation : any) => void,
  localVideoVolume : number,
  setLocalVideoVolume : (volume : number) => void,
  viewAllSlides : boolean,
  toggleViewAllSlides : (viewAllSlides : boolean) => void,
  autoBubble: boolean,
  toggleAutoBubble: (autoBubble: boolean) => void,
  advancedLoggingUserIDs: Array<string>,
  setAdvancedLoggingUserIDs: (userIDs : Array<string>) => void
}

export const PresenterContext = React.createContext(null);

export const PresenterContextProvider = (props) => {
  const [isAudienceMuted, toggleIsAudienceMuted] = useState(false);
  const [isEventMuted, toggleIsEventMuted] = useState(false);
  const [isEventBroadcast, toggleIsEventBroadcast] = useState(false);
  const [isOnStage, toggleIsOnStage] = useState(false);
  const [isMenuOpen, toggleIsMenuOpen] = useState(true);
  const [isSharingScreen, toggleIsSharingScreen] = useState(false);
  const [isPresentingSlides, toggleIsPresentingSlides] = useState(false);
  const [playLocalVideo, togglePlayLocalVideo] = useState(false);
  const [ shareLivestream, toggleShareLivestream ] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [currentMode, setCurrentMode] = useState(StringVariableHelper.ShowboatModeType.attendees);
  const [laserPointer, toggleLaserPointer] = useState(false);
  const [isBackstage, toggleIsBackstage] = useState(false);
  const [isPresentingWebcam, toggleIsPresentingWebcam] = useState(false);
  const [slidePreview, toggleSlidePreview] = useState(true);
  const [webcamPreview, toggleWebcamPreview] = useState(true);
  const [collapsePresenterMenu, toggleCollapsePresenterMenu] = useState(false);
  const [jumbotronView, toggleJumbotronView] = useState(false);
  const [showShowboatConnectTool, toggleShowShowboatConnectTool] = useState(false);
  const [streamShowboatConnect, toggleStreamShowboatConnect] = useState(false);
  const [infiniteAudio, toggleInfiniteAudio] = useState(false);
  const [defaultScreen, toggleDefaultScreen] = useState(false);
  const [ livestreamURLContext, setLivestreamURLContext ] = useState("");

  const [advancedLoggingUserIDs, setAdvancedLoggingUserIDs] = useState([]);

  const [ selectedPresentation, setSelectedPresentation ] = useState(null);

  const [ optimizeScreenshareForVideo, toggleOptimizeScreenshareForVideo ] = useState(false); 

  const [ currentPresenterTool, setCurrentPresenterTool ] = useState("");

  const [ localVideoVolume, setLocalVideoVolume ] = useState(null);

  const [ viewAllSlides, toggleViewAllSlides ] = useState(false);

  const [ autoBubble, toggleAutoBubble ] = useState(SHOWBOAT.RemotePlayersZoneConfig.useAutoBubble);
  
  useEffect(() => {

    //get initial value of SilenceAudience variable and assign to isAudienceMuted
    SHOWBOAT.ServerVariableManager.getEventVariable(StringVariableHelper.SilenceAudienceEventName, { muted: false })
      .then(response => {
        toggleIsAudienceMuted(response.muted);
      })
      .catch(response => {
        return;
      });

    //Get initial value of lsconect variable and assign to streamShowboatConnect
    SHOWBOAT.ServerVariableManager.getEventVariable(StringVariableHelper.PresentationEventName, { mode: SHOWBOAT.JumbotronMode.off, slide: "", userID: SHOWBOAT.LocalAvatarDataManager.userID })
      .then(response => {
        if (response.mode && response.mode === "lsconnect") {
          toggleStreamShowboatConnect(true);
        } else {
          toggleStreamShowboatConnect(false);
        }

        if (response.mode && response.mode === SHOWBOAT.JumbotronMode.off) {
          toggleDefaultScreen(true);
        } else {
          toggleDefaultScreen(false);
        }
      })
      .catch(() => {
        return;
      });

    //Get initial value of SpacialAudio variable and assign its inverse to infiniteAudio
    SHOWBOAT.ServerVariableManager.getEventVariable(StringVariableHelper.SpatialAudioEventName, { value: true })
      .then(response => {
        //If spacialAudio is false, set zone to 9999. Otherwise, set to default size
        if (response.value === false) {
          SHOWBOAT.RemotePlayersZoneConfig.Distance_Visible = 99999;
        } else {
          SHOWBOAT.RemotePlayersZoneConfig.Distance_Visible = SHOWBOAT.RemotePlayersZoneConfig.Default_Distance_Visible;
        }
        //use opposite of value because when SpacialAudio is true, infinite audio is false (vice-versa)
        toggleInfiniteAudio(!response.value);
      })
      .catch(() => {
        return;
      })


    //listen for another presenter silencing the audience
    SHOWBOAT.ServerVariableManager.OnEventVariableUpdate.Add(StringVariableHelper.SilenceAudienceEventName, handleSilenceAudienceChange);

    //listen for another presenter toggling off ShowboatConnect stream
    SHOWBOAT.ServerVariableManager.OnEventVariableUpdate.Add(StringVariableHelper.PresentationEventName, handlePresentationVariables);

    //Listen for another presenter toggling SpacialAudio
    SHOWBOAT.ServerVariableManager.OnEventVariableUpdate.Add(StringVariableHelper.SpatialAudioEventName, handleInfiniteAudioChange);


    return function cleanup() {
      SHOWBOAT.ServerVariableManager.OnEventVariableUpdate.Remove(StringVariableHelper.SilenceAudienceEventName, handleSilenceAudienceChange);
      SHOWBOAT.ServerVariableManager.OnEventVariableUpdate.Remove(StringVariableHelper.PresentationEventName, handlePresentationVariables);
      SHOWBOAT.ServerVariableManager.OnEventVariableUpdate.Remove(StringVariableHelper.SpatialAudioEventName, handleInfiniteAudioChange);
    }

  }, [])

  const handleSilenceAudienceChange = (newValue : any) : void => {
    toggleIsAudienceMuted(newValue.muted);
  }

  const handleInfiniteAudioChange = (newValue: any): void => {
    //If spacialAudio is false, set zone to 9999. Otherwise, set to default size
    if (newValue.value === false) {
      SHOWBOAT.RemotePlayersZoneConfig.Distance_Visible = 99999;
    } else {
      SHOWBOAT.RemotePlayersZoneConfig.Distance_Visible =
        SHOWBOAT.RemotePlayersZoneConfig.Default_Distance_Visible;
    }

    //Use inverse of SpatialAudio, as when SpacialAudio is true, InfiniteAudio is false
    toggleInfiniteAudio(!newValue.value);
  };

  const handlePresentationVariables = (newValue: any): void => {
    if (newValue && newValue.mode) {
      //Check if mode is lsconnect
      if (newValue.mode === "lsconnect") {
        toggleStreamShowboatConnect(true);
      } else {
        toggleStreamShowboatConnect(false);
      }

      //Check if mode is an off (default screen)
      if (newValue.mode === SHOWBOAT.JumbotronMode.off) {
        toggleDefaultScreen(true);
      } else {
        toggleDefaultScreen(false);
      }
    }
  };

  const contextHandleNextSlideButtonClick = () => {
    setCurrentSlide(currentSlide + 1);
  }

  const contextHandlePreviousSlideButtonClick = () => {
    setCurrentSlide(currentSlide - 1);
  }

  const presenterProviderValue : PresenterContextType = {
    isAudienceMuted,
    toggleIsAudienceMuted,
    isEventMuted,
    toggleIsEventMuted,
    isEventBroadcast,
    toggleIsEventBroadcast,
    isOnStage,
    toggleIsOnStage,
    isMenuOpen,
    toggleIsMenuOpen,
    isSharingScreen,
    toggleIsSharingScreen,
    playLocalVideo,
    togglePlayLocalVideo,
    currentSlide,
    setCurrentSlide,
    isPresentingSlides,
    toggleIsPresentingSlides,
    currentMode,
    setCurrentMode,
    contextHandleNextSlideButtonClick,
    contextHandlePreviousSlideButtonClick,
    laserPointer,
    toggleLaserPointer,
    isBackstage,
    toggleIsBackstage,
    isPresentingWebcam,
    toggleIsPresentingWebcam,
    slidePreview,
    toggleSlidePreview,
    webcamPreview,
    toggleWebcamPreview,
    collapsePresenterMenu,
    toggleCollapsePresenterMenu,
    jumbotronView,
    toggleJumbotronView,
    showShowboatConnectTool,
    toggleShowShowboatConnectTool,
    streamShowboatConnect,
    toggleStreamShowboatConnect,
    infiniteAudio,
    toggleInfiniteAudio,
    defaultScreen,
    toggleDefaultScreen,
    shareLivestream,
    toggleShareLivestream,
    currentPresenterTool,
    setCurrentPresenterTool,
    optimizeScreenshareForVideo,
    toggleOptimizeScreenshareForVideo,
    livestreamURLContext,
    setLivestreamURLContext,
    selectedPresentation,
    setSelectedPresentation,
    localVideoVolume,
    setLocalVideoVolume,
    viewAllSlides,
    toggleViewAllSlides,
    autoBubble,
    toggleAutoBubble,
    advancedLoggingUserIDs,
    setAdvancedLoggingUserIDs
  }

  return (
    <PresenterContext.Provider
      value={presenterProviderValue}
    >
      {props.children}
    </PresenterContext.Provider>
  )
}
