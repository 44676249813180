import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileAndTabletErrorPageStyles = makeStyles((theme: any) => createStyles({
  mobileAndTabletErrorMessageWrapper: {
    width: "100%",
    height: "100%",
    display: "inline-block",
    backgroundColor: "#1B1B1D",
    overscrollBehaviorY: "none"
  },
  errorText: {
    opacity: .87,
    color: "#FFFFFF",
    fontSize: 32,
    position: "relative",
    top: 40,
    '@media(max-width: 400px)': {
      fontSize: 24,
    }
  }
})
)

export { MobileAndTabletErrorPageStyles };