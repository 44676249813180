import { makeStyles, createStyles } from '@material-ui/core/styles';

const LivestreamScreenshareVolumeStyles = makeStyles((theme: any) =>
  createStyles({
    volumeHolder: {
      width: "100%",
      height: "12%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-evenly",
      backgroundColor: "rgb(0, 0, 0, 0.84)",
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      position: "absolute",
      minHeight: 47,
      bottom: 0,
      left: 0,
      borderRadius: 0,
      "&:hover": {
        cursor: "default",
      },
    },
    volumeSlider: {
      
    },
    volumeIcon: {
      zIndex: 10,
      padding: 4,
    }
  })
);

export { LivestreamScreenshareVolumeStyles };