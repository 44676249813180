import React, {
  ReactElement,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import {
  Button,
  ClickAwayListener,
  FormControlLabel,
  Menu,
  MenuItem,
  Paper,
  Slider,
  Switch,
  withStyles,
} from "@material-ui/core";
import ActiveIcon from "@material-ui/icons/FiberManualRecord";

import {
  PresenterContext,
  PresenterContextType,
} from "../../../context/PresenterContext";
import { ServerHelper } from "../../../Utilities/ServerHelper";
import { PresenterHelper } from "../../../Utilities/PresenterHelper";
import PresenterMicStatusText from "./PresenterMicStatusText/PresenterMicStatusText";
import { UIHelper } from "../../../Utilities/UIHelper";
import { isMobile } from "react-device-detect";
import { StringVariableHelper } from "../../../Utilities/StringVariableHelper";

import styles from "./PresenterMenu.module.css";
import { PresenterMenuStyles } from "./PresenterMenuStyles";
import ScrollContainer from "react-indiana-drag-scroll";
import { AppContext, AppContextType } from "../../../context/AppContext";

const CustomSlider = withStyles((theme) => ({
  disabled: {
    "& .MuiSlider-track": {
      color: "#747474 !important",
    },
    "& .MuiSlider-thumb": {
      color: "#747474 !important",
    },
    "& .MuiSlider-rail": {
      opacity: ".2 !important",
    },
  },
}))(Slider);

export default function PresenterMenu(): ReactElement {
  const [disableToStage, toggleDisableToStage] = useState(false);
  const [cameraEnabled, toggleCameraEnabled] = useState(
    SHOWBOAT.StreamingUserMedia.isCameraRunning()
  );

  const [audioAnchorElement, setAudioAnchorElement] = useState(null);
  const [teleportAnchorElement, setTeleportAnchorElement] = useState(null);
  const [displayAnchorElement, setDisplayAnchorElement] = useState(null);

  const [disablePresenterToggle, setDisablePresenterToggle] = useState(false);
  const [disableLaserToggle, setDisableLaserToggle] = useState(false);

  const [bubbleSize, setBubbleSize] = useState(
    SHOWBOAT.RemotePlayersZoneConfig.Audio_Max_Distance
  );

  const [tableToggleDisable, setTableToggleDisable] = useState(false);

  const disableButtonRef = useRef(null);
  const resizeTimerRef = useRef(null);
  const autoBubbleInterval = useRef(null);
  const bubbleSizeRef = useRef(
    SHOWBOAT.RemotePlayersZoneConfig.Audio_Max_Distance
  );
  const dragTimeoutRef = useRef(null);

  const disableStageTeleportRef = useRef(null);

  const disablePresenterToggleClickTimeout = useRef(null);
  const disableLaserToggleClickTimeout = useRef(null);

  const laserPointerRef = useRef(false);

  const {
    togglePlayLocalVideo,
    toggleShareLivestream,
    isBackstage,
    setCurrentMode,
    toggleIsPresentingWebcam,
    setCurrentPresenterTool,
    toggleIsPresentingSlides,
    toggleIsSharingScreen,
    currentMode,
    infiniteAudio,
    toggleInfiniteAudio,
    isAudienceMuted,
    toggleIsAudienceMuted,
    laserPointer,
    toggleLaserPointer,
    toggleIsOnStage,
    toggleIsBackstage,
    isOnStage,
    defaultScreen,
    toggleDefaultScreen,
    isPresentingWebcam,
    isPresentingSlides,
    isSharingScreen,
    playLocalVideo,
    shareLivestream,
    autoBubble,
    toggleAutoBubble,
  }: PresenterContextType = useContext(PresenterContext);

  const { showMoveMenu, setShowMoveMenu }: AppContextType =
    useContext(AppContext);

  useEffect(() => {
    //Add event listener to presenter menu buttons
    let displayButton = document.getElementById("displayDropdownActivator");
    if (displayButton)
      displayButton.addEventListener("mouseleave", handleDisplayClose);

    let audioButton = document.getElementById("avatarAudioDropdownActivator");
    if (audioButton)
      audioButton.addEventListener("mouseleave", handleAudioClose);

    let teleportButton = document.getElementById("teleportDropdownActivator");
    if (teleportButton)
      teleportButton.addEventListener("mouseleave", handleTeleportClose);

    //Listen for presentation state change
    PresenterHelper.OnPresentationModeChange.Add(handlePresentationModeChange);

    //Event listeners to disable toggles while at a Table
    SHOWBOAT.TableController.OnLocalUserJoinedTable.Add(
      handleLocalUserJoinTable
    );
    SHOWBOAT.TableController.OnLocalUserLeftTable.Add(
      handleLocalUserLeaveTable
    );

    //Listen for failures in partition changes
    SHOWBOAT.UIEventManager.OnPartionChangeFailure.Add(
      handlePartitionChangeFailure
    );

    //Event listeners for camera start/stop, for present webcam feature
    SHOWBOAT.StreamingUserMedia.OnCameraStarted.Add(handleCameraStarted);
    SHOWBOAT.StreamingUserMedia.OnCameraStopped.Add(handleCameraStopped);

    //Remove transition for presenter widgets on screen resize
    const handleScreenResize = () => {
      document
        .getElementById("presenterMenuHolder")
        .classList.add("transition-stopper");
      clearTimeout(resizeTimerRef.current);

      let cameraPreviewDiv: HTMLElement = document.getElementById(
        "videoHolderPresenter"
      );

      if (cameraPreviewDiv) {
        cameraPreviewDiv.classList.add("transition-stopper");
      }

      let slidePresentationDiv: HTMLElement = document.getElementById(
        "slidePresentationHolder"
      );

      if (slidePresentationDiv) {
        slidePresentationDiv.classList.add("transition-stopper");
      }

      resizeTimerRef.current = setTimeout(function () {
        document
          .getElementById("presenterMenuHolder")
          .classList.remove("transition-stopper");

        if (cameraPreviewDiv) {
          cameraPreviewDiv.classList.add("transition-stopper");
        }

        if (slidePresentationDiv) {
          slidePresentationDiv.classList.remove("transition-stopper");
        }
      }, 400);
    };

    //Disable position transition animation when window is resized
    window.addEventListener("resize", handleScreenResize);

    return function cleanup() {
      PresenterHelper.OnPresentationModeChange.Remove(
        handlePresentationModeChange
      );

      SHOWBOAT.UIEventManager.OnPartionChangeFailure.Remove(
        handlePartitionChangeFailure
      );

      SHOWBOAT.StreamingUserMedia.OnCameraStarted.Remove(handleCameraStarted);
      SHOWBOAT.StreamingUserMedia.OnCameraStopped.Remove(handleCameraStopped);

      SHOWBOAT.TableController.OnLocalUserJoinedTable.Remove(
        handleLocalUserJoinTable
      );
      SHOWBOAT.TableController.OnLocalUserLeftTable.Remove(
        handleLocalUserLeaveTable
      );

      window.removeEventListener("resize", handleScreenResize);

      if (disableStageTeleportRef.current) {
        clearTimeout(disableStageTeleportRef.current);
      }

      if (resizeTimerRef.current) {
        clearTimeout(resizeTimerRef.current);
      }

      if (disableButtonRef.current) {
        clearTimeout(disableButtonRef.current);
      }

      if (dragTimeoutRef.current) {
        clearTimeout(dragTimeoutRef.current);
      }

      if (disablePresenterToggleClickTimeout.current) {
        clearTimeout(disablePresenterToggleClickTimeout.current);
      }

      if (disableLaserToggleClickTimeout.current) {
        clearTimeout(disableLaserToggleClickTimeout.current);
      }

      let displayButton = document.getElementById("displayDropdownActivator");
      if (displayButton)
        displayButton.removeEventListener("mouseleave", handleDisplayClose);

      let audioButton = document.getElementById("avatarAudioDropdownActivator");
      if (audioButton)
        audioButton.removeEventListener("mouseleave", handleAudioClose);

      let teleportButton = document.getElementById("teleportDropdownActivator");
      if (teleportButton)
        teleportButton.removeEventListener("mouseleave", handleTeleportClose);
    };
  }, []);

  useEffect(() => {
    laserPointerRef.current = laserPointer;
  }, [laserPointer]);

  useEffect(() => {
    SHOWBOAT.SocketIOController.OnEventVariableUpdate.Add(
      StringVariableHelper.AudioDistanceEventName,
      handleRemotePresenterAudioDistanceChange
    );

    //If AutoBubble is ON, set up interval to listen for default calculation changes
    //Also remove listener for remote presenter changes
    if (autoBubble) {
      SHOWBOAT.UIEventManager.OnAudioDistanceChange.Raise(
        SHOWBOAT.RemotePlayersZoneConfig.Audio_Max_Distance
      );

      setBubbleSize(SHOWBOAT.RemotePlayersZoneConfig.Audio_Max_Distance);
      bubbleSizeRef.current =
        SHOWBOAT.RemotePlayersZoneConfig.Audio_Max_Distance;

      autoBubbleInterval.current = setInterval(function () {
        //Set slider with auto calculation
        setBubbleSize(SHOWBOAT.RemotePlayersZoneConfig.Audio_Max_Distance);
        bubbleSizeRef.current =
          SHOWBOAT.RemotePlayersZoneConfig.Audio_Max_Distance;

        //Send auto calculation to 3D
        SHOWBOAT.UIEventManager.OnAudioDistanceChange.Raise(
          SHOWBOAT.RemotePlayersZoneConfig.Audio_Max_Distance
        );
      }, 1000);
    } else {
      //AutoBubble is OFF, so clear interval
      if (autoBubbleInterval.current) clearInterval(autoBubbleInterval.current);
    }

    return function cleanup() {
      if (autoBubbleInterval.current) clearInterval(autoBubbleInterval.current);

      SHOWBOAT.SocketIOController.OnEventVariableUpdate.Remove(
        StringVariableHelper.AudioDistanceEventName,
        handleRemotePresenterAudioDistanceChange
      );
    };
  }, [autoBubble]);

  const handlePartitionChangeFailure = () => {
    if (
      SHOWBOAT.LocalAvatarDataManager.partition ===
      StringVariableHelper.ShowboatPartitions.presenter
    ) {
      setCurrentMode(StringVariableHelper.ShowboatPartitions.presenter);

      //Reconcile UI buttons
      toggleIsBackstage(false);
    } else if (
      SHOWBOAT.LocalAvatarDataManager.partition ===
      StringVariableHelper.ShowboatPartitions.backstage
    ) {
      setCurrentMode(StringVariableHelper.ShowboatPartitions.backstage);

      //Reconcile UI buttons
      toggleIsBackstage(true);
    } else if (
      SHOWBOAT.LocalAvatarDataManager.partition ===
      StringVariableHelper.ShowboatPartitions.attendees
    ) {
      setCurrentMode(StringVariableHelper.ShowboatPartitions.attendees);

      //Reconcile UI buttons
      toggleIsBackstage(false);
    }
  };

  const handleLocalUserJoinTable = () => {
    //Disable Presenter Mode and Laser Pointer
    setTableToggleDisable(true);

    //If laser pointer is on, turn it off and raise event
    if (laserPointerRef.current) {
      toggleLaserPointer(false);

      SHOWBOAT.LocalAvatarDataManager.laserEnabled = false;

      ServerHelper.UpdatePlayerData(SHOWBOAT.ChangeReason.LaserEnabled);

      SHOWBOAT.UIEventManager.OnLaserPointerToggle.Raise(false);
    }
  };

  const handleLocalUserLeaveTable = () => {
    //Re-enable Presenter Mode and Laser Pointer
    setTableToggleDisable(false);
  };

  const handleRemotePresenterAudioDistanceChange = (eventObj: any) => {
    //Do nothing if userID is our own
    if (eventObj.userID === SHOWBOAT.LocalAvatarDataManager.userID) return;

    //If auto is toggled on , toggle auto
    if (eventObj.auto) {
      toggleAutoBubble(true);
    } else {
      toggleAutoBubble(false);
    }

    //Set slider value
    setBubbleSize(eventObj.distance);
    bubbleSizeRef.current = eventObj.distance;
  };

  //Function to react to presentation mode changes
  const handlePresentationModeChange = () => {
    SHOWBOAT.Logger.Log(
      "My current presentation mode:",
      PresenterHelper.myCurrentPresentationMode
    );
    SHOWBOAT.Logger.Log(
      "My previous presentation mode:",
      PresenterHelper.myPreviousPresentationMode
    );

    //Check if currentPresenterID is our own
    if (
      PresenterHelper.currentPresenterID ===
      SHOWBOAT.LocalAvatarDataManager.userID
    ) {
      //currentPresenterID is our own, so handle accordingly
      switch (PresenterHelper.myPreviousPresentationMode) {
        //We had slides activated
        case SHOWBOAT.JumbotronMode.slideShare:
          //Close slide menu
          handleForceSlidesOff();
          break;

        //We had screenshare activated
        case SHOWBOAT.JumbotronMode.screenShare:
          //Deactivate screenshare button
          handleScreenshareForceOff();
          break;

        //We had webcam share activated
        case SHOWBOAT.JumbotronMode.webcamShare:
          handlePresentWebcamForceOff();
          break;

        //We had local video share activated
        case SHOWBOAT.JumbotronMode.localVideoShare:
          togglePlayLocalVideo(false);
          UIHelper.OnForceVideoshareOff.Raise();
          break;

        //We had livestream share activated
        case SHOWBOAT.JumbotronMode.livestream:
          toggleShareLivestream(false);
          UIHelper.OnForceLivestreamOff.Raise();
          break;
      }
    } else {
      //currentPresenterID is not our own, so handle accordingly
      switch (PresenterHelper.myPreviousPresentationMode) {
        //We have slides activated
        case SHOWBOAT.JumbotronMode.slideShare:
          handleForceSlidesOff();
          break;

        //We have screenshare activated
        case SHOWBOAT.JumbotronMode.screenShare:
          handleScreenshareForceOff();
          break;

        //We have webcam share activated
        case SHOWBOAT.JumbotronMode.webcamShare:
          handlePresentWebcamForceOff();
          break;

        //We have local video share activated
        case SHOWBOAT.JumbotronMode.localVideoShare:
          //Just raise force event (menu can stay open)
          togglePlayLocalVideo(false);
          UIHelper.OnForceVideoshareOff.Raise();
          break;

        //We have livestream share activated
        case SHOWBOAT.JumbotronMode.livestream:
          //Just raise force event (menu can stay open)
          toggleShareLivestream(false);
          UIHelper.OnForceLivestreamOff.Raise();
          break;
      }
    }
  };

  //Mode change
  const handlePresentationModeToggle = (e) => {
    if (disablePresenterToggle) return;

    //If the toggle is disabled, raise UI error and return
    if (tableToggleDisable) {
      //Disable for 1 sec
      setDisablePresenterToggle(true);
      disablePresenterToggleClickTimeout.current = setTimeout(function () {
        setDisablePresenterToggle(false);
      }, 1000);

      SHOWBOAT.UIEventManager.OnUIError.Raise(
        "Not available while at a table."
      );
      return;
    }

    let presentationMode = e.target.checked;

    if (isBackstage) {
      setCurrentMode(
        e.target.checked
          ? StringVariableHelper.ShowboatModeType.presenter
          : StringVariableHelper.ShowboatModeType.attendees
      );
    } else {
      if (!presentationMode) {
        //Disable present webcam toggle, toggle to false in 3D world
        toggleIsPresentingWebcam(false);
        PresenterHelper.OnPresentWebcamToggle(false);
        SHOWBOAT.UIEventManager.OnModeChange.Raise(
          SHOWBOAT.ShowboatChannelType.Attendees
        );
      } else {
        SHOWBOAT.UIEventManager.OnModeChange.Raise(
          SHOWBOAT.ShowboatChannelType.Presenter
        );
      }
      setCurrentMode(
        e.target.checked
          ? StringVariableHelper.ShowboatModeType.presenter
          : StringVariableHelper.ShowboatModeType.attendees
      );
    }
  };

  //Slides
  const handleToggleSlidePresentation = () => {
    //Show slide tool
    setCurrentPresenterTool(StringVariableHelper.PresenterToolNames.slides);
  };

  const handleForceSlidesOff = () => {
    //Change state variable to close menu
    toggleIsPresentingSlides(false);
  };

  //Screenshare
  const handleShareScreenToggle = async (e) => {
    //Show screenshare menu
    setCurrentPresenterTool(
      StringVariableHelper.PresenterToolNames.screenshare
    );
  };

  const handleScreenshareForceOff = () => {
    //Change state variable to close menu
    toggleIsSharingScreen(false);
  };

  //Play Local Video
  const handlePlayLocalVideoToggle = () => {
    //If slides are open, close slide menu
    /* if (isPresentingSlides) {
      toggleIsPresentingSlides(false);
    }
    
    //Show menu (just local state update)
    togglePlayLocalVideo(!playLocalVideo); */

    //Show local video menu
    setCurrentPresenterTool(StringVariableHelper.PresenterToolNames.localVideo);
  };

  //Share livestream
  const handleShareLivestreamToggle = () => {
    //Show livestream menu
    setCurrentPresenterTool(StringVariableHelper.PresenterToolNames.livestream);
  };

  //Present webcam
  const handlePresentWebcamToggle = async () => {
    if (
      currentMode !== StringVariableHelper.ShowboatModeType.presenter ||
      !cameraEnabled ||
      isMobile ||
      isBackstage
    ) {
      return;
    }

    //If operation is in progress for camera, do not open popup
    if (SHOWBOAT.StreamingUserMedia.isOperationInProgress()) return;

    //Show webcam menu
    setCurrentPresenterTool(StringVariableHelper.PresenterToolNames.webcam);
  };

  const handleCameraStarted = () => {
    //Change local cameraEnabled state
    toggleCameraEnabled(true);
  };

  const handleCameraStopped = () => {
    //Force feature off if camera is stopped/muted

    //notify 3D
    PresenterHelper.OnPresentWebcamToggle(false);

    //Change local cameraEnabled state
    toggleCameraEnabled(false);

    //Force-off in context
    toggleIsPresentingWebcam(false);
  };

  const handlePresentWebcamForceOff = () => {
    //Change state variable to close menu
    toggleIsPresentingWebcam(false);
  };

  //Infinite audio
  const handleInfiniteAudioToggle = () => {
    let toggleState: boolean = !infiniteAudio;

    //Send event with inverse of infiniteAudio, as when InfiniteAudio is true, SpacialAudio is false
    PresenterHelper.OnSpatialAudioChange(!toggleState);

    toggleInfiniteAudio(toggleState);
  };

  //Silence audience
  const handleMuteAudienceChange = (e: any) => {
    PresenterHelper.OnSilenceAudience(!isAudienceMuted);

    toggleIsAudienceMuted(!isAudienceMuted);
  };

  //Laser pointer
  const handleLaserPointerToggle = () => {
    if (disableLaserToggle) return;

    if (tableToggleDisable) {
      //Disable for 1 sec
      setDisableLaserToggle(true);
      disableLaserToggleClickTimeout.current = setTimeout(function () {
        setDisableLaserToggle(false);
      }, 1000);

      SHOWBOAT.UIEventManager.OnUIError.Raise(
        "Not available while at a table."
      );
      return;
    }

    let laserEnabled: boolean = !laserPointer;

    //update local data
    SHOWBOAT.LocalAvatarDataManager.laserEnabled = laserEnabled;

    //notify 3d world
    SHOWBOAT.UIEventManager.OnLaserPointerToggle.Raise(laserEnabled);

    //let the socket know of change
    ServerHelper.UpdatePlayerData(SHOWBOAT.ChangeReason.LaserEnabled);

    toggleLaserPointer(laserEnabled);
  };

  //Teleport backstage
  const handleTeleportBackstageClick = () => {
    if (disableToStage) return;

    //Set disable button timeout
    toggleDisableToStage(true);

    disableButtonRef.current = setTimeout(function () {
      toggleDisableToStage(false);
    }, 1000);

    //Teleporting TO backstage
    if (!isBackstage) {
      //If not backstage and are on-stage in the normal room, change on/off stage button to "Teleport to Stage"
      toggleIsOnStage(false);

      //Change current mode to presenter-only mode via UIEventManager
      SHOWBOAT.UIEventManager.OnModeChange.Raise(
        SHOWBOAT.ShowboatChannelType.Backstage
      );
    }

    //Teleporting FROM backstage
    if (isBackstage) {
      //If teleporting back to room from backstage, actually change to the partition that was selected backstage
      if (currentMode === StringVariableHelper.ShowboatModeType.attendees) {
        SHOWBOAT.UIEventManager.OnModeChange.Raise(
          SHOWBOAT.ShowboatChannelType.Attendees
        );
      } else if (
        currentMode === StringVariableHelper.ShowboatModeType.presenter
      ) {
        SHOWBOAT.UIEventManager.OnModeChange.Raise(
          SHOWBOAT.ShowboatChannelType.Presenter
        );
      }
    }

    SHOWBOAT.UIEventManager.OnTeleportToBackstageToggle.Raise(!isBackstage);
    toggleIsBackstage(!isBackstage);
  };

  //Teleport to stage
  const handleTeleportToStageClick = () => {
    if (disableToStage) return;

    //Disable the button for 1 second
    toggleDisableToStage(true);
    disableStageTeleportRef.current = setTimeout(function () {
      toggleDisableToStage(false);
    }, 1000);

    //If backstage, change partition to whichever is selected and remove from backstage
    if (isBackstage) {
      //Change selected partition
      if (currentMode === StringVariableHelper.ShowboatModeType.attendees) {
        SHOWBOAT.UIEventManager.OnModeChange.Raise(
          SHOWBOAT.ShowboatChannelType.Attendees
        );
      } else if (
        currentMode === StringVariableHelper.ShowboatModeType.presenter
      ) {
        SHOWBOAT.UIEventManager.OnModeChange.Raise(
          SHOWBOAT.ShowboatChannelType.Presenter
        );
      }

      //Remove from backstage, change button text to "Teleport to Backstage"
      SHOWBOAT.UIEventManager.OnTeleportToBackstageToggle.Raise(false);
      toggleIsBackstage(false);
    }

    SHOWBOAT.UIEventManager.OnTeleportToStageToggle.Raise(!isOnStage);

    toggleIsOnStage(!isOnStage);
  };

  //Default screen handler
  const handleDefaultScreenToggle = () => {
    //If defaultScreen is already true, do nothing
    if (defaultScreen === true) return;

    PresenterHelper.OnDefaultScreenActivate();

    toggleDefaultScreen(true);
  };

  //Bubble size
  const handleBubbleSizeChange = (e, newValue: number) => {
    //Set ZoneConfig on drag
    SHOWBOAT.RemotePlayersZoneConfig.setAudioBubbleDistance(newValue);
    SHOWBOAT.UIEventManager.OnAudioDistanceChange.Raise(newValue);

    //Send server variable on drag end

    setBubbleSize(newValue);
    bubbleSizeRef.current = newValue;

    clearTimeout(dragTimeoutRef.current);

    dragTimeoutRef.current = setTimeout(handleDragTimeout, 500);

    if (e) {
      e.stopPropagation();
    }
  };

  const handleDragTimeout = () => {
    //Send value to server
    SHOWBOAT.SocketIOController.SetServerEventVariable(
      StringVariableHelper.AudioDistanceEventName,
      {
        auto: false,
        distance: bubbleSizeRef.current,
        userID: SHOWBOAT.LocalAvatarDataManager.userID,
      }
    );
  };

  //Auto bubble
  const handleAutoBubbleChange = () => {
    let toggleState = !autoBubble;

    toggleAutoBubble(toggleState);

    //Raise server event
    SHOWBOAT.SocketIOController.SetServerEventVariable(
      StringVariableHelper.AudioDistanceEventName,
      {
        auto: toggleState,
        distance: bubbleSizeRef.current,
        userID: SHOWBOAT.LocalAvatarDataManager.userID,
      }
    );

    SHOWBOAT.RemotePlayersZoneConfig.useAutoBubble = toggleState;
  };

  //Show audio dropdown
  const handleShowAudioDropdown = (e) => {
    //Hide other menus
    setTeleportAnchorElement(null);
    setDisplayAnchorElement(null);
    /* setCustomAnchorElement(null); */

    //Set values from RemoteZoneConfig
    setBubbleSize(SHOWBOAT.RemotePlayersZoneConfig.Audio_Max_Distance);
    bubbleSizeRef.current = SHOWBOAT.RemotePlayersZoneConfig.Audio_Max_Distance;

    toggleAutoBubble(SHOWBOAT.RemotePlayersZoneConfig.useAutoBubble);

    //Show AudioBubble in 3D and raise distance event
    SHOWBOAT.UIEventManager.OnAudioDistanceShow.Raise();
    SHOWBOAT.UIEventManager.OnAudioDistanceChange.Raise(
      SHOWBOAT.RemotePlayersZoneConfig.Audio_Max_Distance
    );

    if (audioAnchorElement !== e.currentTarget) {
      setAudioAnchorElement(e.currentTarget);
    }
  };

  //Show display dropdown
  const handleShowDisplayDropdown = (e) => {
    //Hide other menus
    setAudioAnchorElement(null);
    setTeleportAnchorElement(null);
    /* setCustomAnchorElement(null); */

    if (displayAnchorElement !== e.currentTarget) {
      setDisplayAnchorElement(e.currentTarget);
    }
  };

  //Show teleport dropdown
  const handleShowTeleportDropdown = (e) => {
    //Hide other menus
    setAudioAnchorElement(null);
    setDisplayAnchorElement(null);
    /* setCustomAnchorElement(null); */

    if (teleportAnchorElement !== e.currentTarget) {
      setTeleportAnchorElement(e.currentTarget);
    }
  };

  //Show custom dropdown
  const handleShowCustomDropdown = (e) => {
    //Hide other menus
    setAudioAnchorElement(null);
    setDisplayAnchorElement(null);
    setTeleportAnchorElement(null);

    if (teleportAnchorElement !== e.currentTarget) {
      setTeleportAnchorElement(e.currentTarget);
    }
  };

  //Chose handlers
  const handleAudioClose = (e) => {
    let toElement = e.toElement || e.relatedTarget;

    //Safety net for somehow hovering over window
    if (toElement == window) {
      return;
    }

    if (
      document.getElementById("avatarAudioDropdownActivator") === null ||
      document.getElementById("avatarAudioDropdown") === null
    ) {
      SHOWBOAT.UIEventManager.OnAudioDistanceHide.Raise();
      return;
    }

    //If we are moving to the anchor element, do not close the menu
    if (toElement) {
      if (
        toElement === audioAnchorElement ||
        document
          .getElementById("avatarAudioDropdownActivator")
          .contains(toElement) ||
        document.getElementById("avatarAudioDropdown").contains(toElement)
      )
        return;
    }

    //Ensure we hide AudioBubble rings in 3D
    SHOWBOAT.UIEventManager.OnAudioDistanceHide.Raise();

    setAudioAnchorElement(null);
  };

  const handleDisplayClose = (e) => {
    let toElement = e.toElement || e.relatedTarget;

    //Safety net for somehow hovering over window
    if (toElement == window) {
      return;
    }

    if (document.getElementById("displayDropdownActivator") === null) return;
    if (document.getElementById("displayDropdown") === null) return;

    //If we are moving to the anchor element, do not close the menu
    if (toElement) {
      if (
        toElement === displayAnchorElement ||
        document
          .getElementById("displayDropdownActivator")
          .contains(toElement) ||
        document.getElementById("displayDropdown").contains(toElement)
      )
        return;
    }

    setDisplayAnchorElement(null);
  };

  const handleTeleportClose = (e) => {
    let toElement = e.toElement || e.relatedTarget;

    //Safety net for somehow hovering over window
    if (toElement == window) {
      return;
    }

    if (document.getElementById("teleportDropdownActivator") === null) return;
    if (document.getElementById("teleportDropdown") == null) return;

    //If we are moving to the anchor element, do not close the menu
    if (toElement) {
      if (
        toElement === teleportAnchorElement ||
        document
          .getElementById("teleportDropdownActivator")
          .contains(toElement) ||
        document.getElementById("teleportDropdown").contains(toElement)
      )
        return;
    }

    setTeleportAnchorElement(null);
  };

  const handleToggleMoveMenu = () => {
    setShowMoveMenu(!showMoveMenu);
  };

  const handleCustomClose = (e) => {
    let toElement = e.toElement || e.relatedTarget;

    //Safety net for somehow hovering over window
    if (toElement == window) {
      return;
    }

    if (document.getElementById("customDropdownActivator") === null) return;
    if (document.getElementById("customDropdown") == null) return;

    //If we are moving to the anchor element, do not close the menu
    if (toElement) {
      if (
        toElement === teleportAnchorElement ||
        document
          .getElementById("customDropdownActivator")
          .contains(toElement) ||
        document.getElementById("customDropdown").contains(toElement)
      )
        return;
    }

    /*  setCustomAnchorElement(null); */
  };

  const classes = PresenterMenuStyles();

  return (
    <React.Fragment>
      {/*  <SlidePreview
        imgSrc={SHOWBOAT.ApplicationSkin.slides.presentationSlides[currentSlide]}
      /> */}

      <Paper
        square
        className={classes.presenterMenuHolder}
        id="presenterMenuHolder"
      >
        <ScrollContainer
          horizontal={true}
          vertical={false}
          className={styles.scrollContainer}
        >
          <PresenterMicStatusText />

          <div className={styles.presenterMenuOptionsHolder}>
            {/* Mode toggle */}
            <FormControlLabel
              control={
                <Switch
                  checked={
                    currentMode ===
                    StringVariableHelper.ShowboatModeType.presenter
                  }
                  onChange={handlePresentationModeToggle}
                  name="Mode Toggle"
                  classes={{
                    track: classes.switchTrack,
                  }}
                  color="primary"
                />
              }
              label="PRESENT"
              labelPlacement="start"
              classes={{
                label: classes.switchLabel,
                root: tableToggleDisable
                  ? `${classes.presenterMenuToggle} ${classes.presenterMenuToggleDisabled}`
                  : classes.presenterMenuToggle,
              }}
            />

            {/* Laser Pointer toggle */}
            <FormControlLabel
              control={
                <Switch
                  checked={laserPointer}
                  onChange={handleLaserPointerToggle}
                  name="Mode Toggle"
                  classes={{
                    track: classes.switchTrack,
                  }}
                  color="primary"
                />
              }
              label="LASER POINTER"
              labelPlacement="start"
              classes={{
                label: classes.switchLabel,
                root: tableToggleDisable
                  ? `${classes.presenterMenuToggle} ${classes.presenterMenuToggleDisabled}`
                  : classes.presenterMenuToggle,
              }}
            />

            {/* Display dropdown activator  */}
            <Button
              id="displayDropdownActivator"
              className={`${classes.hoverButton} ${classes.hoverButtonDisplay}`}
              variant="text"
              onMouseEnter={handleShowDisplayDropdown}
              onClick={handleShowDisplayDropdown}
              disableRipple
            >
              DISPLAY
              <ActiveIcon
                className={
                  isPresentingWebcam ||
                  isPresentingSlides ||
                  isSharingScreen ||
                  playLocalVideo ||
                  shareLivestream
                    ? classes.buttonActiveIcon
                    : `${classes.buttonActiveIcon} ${classes.buttonIconInactive}`
                }
              />
            </Button>

            {/* Display Dropdown */}
            <ClickAwayListener onClickAway={handleDisplayClose}>
              <Menu
                open={Boolean(displayAnchorElement)}
                classes={{
                  paper: classes.presenterMenuDropdown,
                }}
                PopoverClasses={{
                  root: classes.popover,
                }}
                anchorEl={displayAnchorElement}
                onClose={handleDisplayClose}
                MenuListProps={{
                  onMouseLeave: handleDisplayClose,
                }}
                disableAutoFocusItem
                id="displayDropdown"
              >
                <MenuItem
                  className={
                    isPresentingWebcam
                      ? `${classes.presenterDropdownMenuItem} ${classes.presenterDropdownMenuItemActive}`
                      : currentMode !==
                          StringVariableHelper.ShowboatModeType.presenter ||
                        !cameraEnabled ||
                        isMobile ||
                        isBackstage
                      ? `${classes.presenterDropdownMenuItem} ${classes.presenterDropdownMenuItemDisabled}`
                      : classes.presenterDropdownMenuItem
                  }
                  onClick={handlePresentWebcamToggle}
                >
                  WEBCAM
                  {isPresentingWebcam && (
                    <ActiveIcon className={classes.activeIconMenuItem} />
                  )}
                </MenuItem>
                <MenuItem
                  className={
                    isSharingScreen
                      ? `${classes.presenterDropdownMenuItem} ${classes.presenterDropdownMenuItemActive}`
                      : classes.presenterDropdownMenuItem
                  }
                  onClick={handleShareScreenToggle}
                >
                  SCREENSHARE
                  {isSharingScreen && (
                    <ActiveIcon className={classes.activeIconMenuItem} />
                  )}
                </MenuItem>
                <MenuItem
                  className={
                    isPresentingSlides
                      ? `${classes.presenterDropdownMenuItem} ${classes.presenterDropdownMenuItemActive}`
                      : classes.presenterDropdownMenuItem
                  }
                  onClick={handleToggleSlidePresentation}
                >
                  SLIDES
                  {isPresentingSlides && (
                    <ActiveIcon className={classes.activeIconMenuItem} />
                  )}
                </MenuItem>
                <MenuItem
                  className={
                    playLocalVideo
                      ? `${classes.presenterDropdownMenuItem} ${classes.presenterDropdownMenuItemActive}`
                      : classes.presenterDropdownMenuItem
                  }
                  onClick={handlePlayLocalVideoToggle}
                >
                  LOCAL VIDEO
                  {playLocalVideo && (
                    <ActiveIcon className={classes.activeIconMenuItem} />
                  )}
                </MenuItem>
                <MenuItem
                  className={
                    shareLivestream
                      ? `${classes.presenterDropdownMenuItem} ${classes.presenterDropdownMenuItemActive}`
                      : classes.presenterDropdownMenuItem
                  }
                  onClick={handleShareLivestreamToggle}
                >
                  LIVESTREAM
                  {shareLivestream && (
                    <ActiveIcon className={classes.activeIconMenuItem} />
                  )}
                </MenuItem>
                <MenuItem
                  className={
                    defaultScreen
                      ? `${classes.presenterDropdownMenuItem} ${classes.presenterDropdownMenuItemActive}`
                      : classes.presenterDropdownMenuItem
                  }
                  onClick={handleDefaultScreenToggle}
                >
                  DEFAULT SCREEN
                  {defaultScreen && (
                    <ActiveIcon className={classes.activeIconMenuItem} />
                  )}
                </MenuItem>
              </Menu>
            </ClickAwayListener>

            {/* Avatar Audio dropdown activator  */}
            <Button
              id="avatarAudioDropdownActivator"
              className={`${classes.hoverButton} ${classes.hoverButtonAvatarAudio}`}
              variant="text"
              onMouseEnter={handleShowAudioDropdown}
              onClick={handleShowAudioDropdown}
              disableRipple
            >
              AVATAR AUDIO
              <ActiveIcon
                className={
                  isAudienceMuted || infiniteAudio
                    ? `${classes.buttonActiveIcon} ${classes.buttonActiveIconAvatar}`
                    : `${classes.buttonActiveIcon} ${classes.buttonActiveIconAvatar} ${classes.buttonIconInactive}`
                }
              />
            </Button>

            {/* Audio Dropdown */}
            <ClickAwayListener onClickAway={handleAudioClose}>
              <Menu
                open={Boolean(audioAnchorElement)}
                classes={{
                  paper: `${classes.presenterMenuDropdown} ${classes.presenterMenuDropdownAudio}`,
                }}
                PopoverClasses={{
                  root: classes.popover,
                }}
                anchorEl={audioAnchorElement}
                onClose={handleAudioClose}
                MenuListProps={{
                  onMouseLeave: handleAudioClose,
                }}
                disableAutoFocusItem
                id="avatarAudioDropdown"
              >
                <MenuItem
                  className={
                    infiniteAudio
                      ? `${classes.presenterDropdownMenuItem} ${classes.presenterDropdownMenuItemActive}`
                      : classes.presenterDropdownMenuItem
                  }
                  onClick={handleInfiniteAudioToggle}
                >
                  INFINITE AUDIO
                  {infiniteAudio && (
                    <ActiveIcon className={classes.activeIconMenuItem} />
                  )}
                </MenuItem>
                <MenuItem
                  className={
                    isAudienceMuted
                      ? `${classes.presenterDropdownMenuItem} ${classes.presenterDropdownMenuItemActive}`
                      : classes.presenterDropdownMenuItem
                  }
                  onClick={handleMuteAudienceChange}
                >
                  SILENCE AUDIENCE
                  {isAudienceMuted && (
                    <ActiveIcon className={classes.activeIconMenuItem} />
                  )}
                </MenuItem>

                <MenuItem
                  classes={{
                    root: `${classes.presenterDropdownMenuItem} ${classes.bubbleMenuItem}`,
                    selected: classes.bubbleMenuItemFocused,
                  }}
                  disableRipple
                  id="bubbleSizeMenuItem"
                >
                  AUTO BUBBLE
                  <Switch
                    checked={autoBubble}
                    onChange={handleAutoBubbleChange}
                    color="primary"
                    className={classes.autoBubbleToggle}
                  />
                  <CustomSlider
                    orientation="horizontal"
                    className={classes.bubbleSlider}
                    onChange={handleBubbleSizeChange}
                    value={bubbleSize}
                    disabled={autoBubble}
                    min={5}
                    max={30}
                    step={0.1}
                  ></CustomSlider>
                </MenuItem>
              </Menu>
            </ClickAwayListener>

            {/* Teleport To dropdown activator  */}
            <Button
              id="teleportDropdownActivator"
              className={classes.hoverButton}
              variant="text"
              onMouseEnter={handleShowTeleportDropdown}
              onClick={handleShowTeleportDropdown}
              disableRipple
            >
              TELEPORT TO
            </Button>

            {/* Teleport To dropdown */}
            <ClickAwayListener onClickAway={handleTeleportClose}>
              <Menu
                open={Boolean(teleportAnchorElement)}
                classes={{
                  paper: `${classes.presenterMenuDropdown} ${classes.presenterMenuDropdownTeleport}`,
                }}
                PopoverClasses={{
                  root: classes.popover,
                }}
                onClose={handleTeleportClose}
                MenuListProps={{
                  onMouseLeave: handleTeleportClose,
                }}
                anchorEl={teleportAnchorElement}
                disableAutoFocusItem
                id="teleportDropdown"
              >
                <MenuItem
                  className={classes.presenterDropdownMenuItem}
                  onClick={handleTeleportToStageClick}
                >
                  {isOnStage ? "OFFSTAGE" : "STAGE"}
                </MenuItem>
                <MenuItem
                  className={classes.presenterDropdownMenuItem}
                  onClick={handleTeleportBackstageClick}
                >
                  {isBackstage ? "EVENT" : "BACKSTAGE"}
                </MenuItem>
              </Menu>
            </ClickAwayListener>

            <Button
              className={classes.hoverButton}
              variant="text"
              onClick={handleToggleMoveMenu}
            >
              Breakout
            </Button>

            {/* Custom Room Controls button*/}
            {/* <Button
              className={classes.hoverButton}
              variant="text"
            >
              CUSTOM
            </Button> */}
          </div>
        </ScrollContainer>
      </Paper>

      {/* Presenter tool components */}
      {/* <SlidePresentationControlsV2 /> */}
    </React.Fragment>
  );
}
