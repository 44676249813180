import { makeStyles, createStyles } from '@material-ui/core/styles';

const SettingsSidebarStyles = makeStyles((theme: any) => createStyles({
  settingsHeaderHolder: {
    width: "100%",
    backgroundColor: theme.palette.sidebarHeader.main,
    height: 90,
    borderRadius: 4,
    borderTopRadius: 0,
    position: "relative",
  },
  settingsHeaderTop: {
    backgroundColor: theme.palette.primary.main,
    position: "absolute",
    width: "100%",
    height: 40,
    borderTopRadius: 4,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    top: 0,
    left: 0,
  },
  settingsIcon: {
    position: 'absolute',
    top: 7,
    left: 10,
    color: "white"
  },
  settingsHeader: {
    fontSize: 16,
    fontFamily: "Roboto, sans-serif",
    position: "absolute",
    fontWeight: 400,
    top: 10,
    left: 43,
    color: "white"
  },
  closeSidebarButton: {
    padding: 4,
    position: 'absolute',
    top: 3,
    right: 7,
    color: "white"
  },
  tabs: {
    position: "absolute",
    bottom: 0,
  },
  settingsTab: {

  }
})
)

export { SettingsSidebarStyles };