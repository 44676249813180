export class TestHelper {

    public static Init = () : void => {
        (window as any).TestHelper = TestHelper;
    }
  
    public static addBots = (numberOfBots : number) : void => {
        for (let i = 0; i < numberOfBots; i++) {

            //Assemble random AvatarData object
            let randomAvatarData : SHOWBOAT.AvatarData = {
                userID: TestHelper.randomString(16),
                eventID: "TEST",
                roomID: "TEST",
                role: "attendees",
                partition: "attendees",
                ipAddress: "TEST",
                firstName: "Bot",
                lastName: TestHelper.randomString(8),
                company: "Test",
                color: 0,
                face: 0,
                cameraEnabled: false,
                micEnabled: false,
                laserEnabled: false,
                currentMicName: "",
                currentCameraName: "",
                currentSpeakerName: "",
                micDeviceList: "",
                cameraDeviceList: "",
                speakerDeviceList: "",
                publicKey: "",
                isForceMuted: false,
                loadComplete: true,
            }

            //Start timeout to call connection handler
            setTimeout(function() {
                SHOWBOAT.SocketIOController.OnRemotePlayerConnected.Raise(randomAvatarData);

                //Start disconnect timeout
                setTimeout(function() {
                    SHOWBOAT.SocketIOController.OnRemotePlayerDisconnected.Raise(randomAvatarData.userID);
                }, TestHelper.randomIntFromInterval(10000, 20000))

            }, TestHelper.randomIntFromInterval(3000, 6000))
        }
    }

    private static randomString = (length: number) : string => {
        return Math.round((Math.pow(36, length + 1) - Math.random() * Math.pow(36, length))).toString(36).slice(1);
    }

    private static randomIntFromInterval = (min : number, max: number) : number => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }
}

(window as any).TestHelper = TestHelper;