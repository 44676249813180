import React, {useState, useEffect} from 'react';

export interface MessageContextType {
  chatMessages : Array<SHOWBOAT.SocketMessage>,
  setMessages : (messages : Array<SHOWBOAT.SocketMessage>) => void,
  alerts: Array<SHOWBOAT.SocketMessage>,
  setAlerts: (alerts: Array<SHOWBOAT.SocketMessage>) => void,
  newMessage : boolean,
  toggleNewMessage : (newMessage : boolean) => void
}

export const MessageContext = React.createContext(null);

export const MessageContextProvider = (props) => {

  const [ chatMessages, setMessages ] = useState([]);
  const [ alerts, setAlerts ] = useState([]);
  const [ newMessage, toggleNewMessage ] = useState(false);

  let msgID : number = 0;

  useEffect(() => {

    //append to chat array when message added
    SHOWBOAT.SocketIOController.OnEventMessage.Add("chat", handleOnGlobalChatMessage);

    //append to global broadcast array when broadcast occurs
    SHOWBOAT.SocketIOController.OnEventMessage.Add("alert", handleOnGlobalAlertMessage);

    //append to personal chat array when a message is received
    
    //cleanup listeners
    return function cleanup() {
      SHOWBOAT.SocketIOController.OnEventMessage.Remove("chat", handleOnGlobalChatMessage);

      SHOWBOAT.SocketIOController.OnEventMessage.Remove("alert", handleOnGlobalAlertMessage);

    }
  }, []);

  useEffect(() => {
    if (chatMessages.length >= 50) {

      chatMessages.shift();

      setMessages(chatMessages)
    }
  }, [chatMessages])


  /*Message handlers */

  const handleOnGlobalChatMessage = (data: SHOWBOAT.SocketMessage) => {

    toggleNewMessage(true);

    let newMessage = {
      userID: data.userID,
      message: data.message,
      id: msgID
    }

    setMessages(chatMessages => [...chatMessages, newMessage]);

    msgID = msgID + 1;
    
  };

  const handleOnGlobalAlertMessage = (data: SHOWBOAT.SocketMessage) => {
    setAlerts(alerts => [...alerts, data])
  }
  /**/

  const messagesProviderValue : MessageContextType = {
    chatMessages,
    setMessages,
    alerts,
    setAlerts,
    newMessage,
    toggleNewMessage
  }

  return (
    <MessageContext.Provider
      value={messagesProviderValue}
    >
      {props.children}
    </MessageContext.Provider>
  )
}