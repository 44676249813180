import { IconButton, Paper, Slider, Typography } from '@material-ui/core';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import PlayIcon from '@material-ui/icons/PlayArrow';
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeMuteIcon from '@material-ui/icons/VolumeOff';
import PauseIcon from '@material-ui/icons/Pause';

import { PresenterContext, PresenterContextType } from '../../../../../context/PresenterContext';

import { LocalVideoControlsStyles } from './LocalVideoControlsStyles';
import styles from './LocalVideoControls.module.css';

interface Props {
  handlePlayPauseClick: (e) => void,
  play: boolean,
  videoMuted: boolean,
  handleVideoMutedToggle: () => void,
  localVideoVolume: number,
  handleLocalVideoVolumeChange: (volume: number) => void,
  streamVideo : boolean
}

export default function LocalVideoControls(props: Props): ReactElement {

  const {
    localVideoVolume,
    setLocalVideoVolume,
  } : PresenterContextType = useContext(PresenterContext);

  const [ seekbarValue, setSeekbarValue ] = useState(0);
  const [ videoDuration, setVideoDuration ] = useState("");
  const [ videoCurrentTime, setVideoCurrentTime ] = useState("");
  const [ showVolume, toggleShowVolume ] = useState(false);

  useEffect(() => {
    //Add event listener to update seek bar while video plays
    let video = document.getElementById("localVideo") as HTMLMediaElement;

    if (video) {
      video.addEventListener("timeupdate", handleUpdateSeekBar);
      let durationString = (video.duration / 60).toString();
      let minutes = parseInt(durationString, 10);
      let seconds = Math.round(video.duration % 60).toString();

      //Add 0 before seconds if length is only 1
      if (seconds.toString().length === 1) {
        seconds = "0" + seconds;
      }


      //Set duration for time display
      setVideoDuration(`${minutes}:${seconds}`);

      //Set value for seek bar based on video's current time
      handleUpdateSeekBar();

    }
    return function cleanup() {
      video.removeEventListener("timeupdate", handleUpdateSeekBar);
    }
  }, [])
  
  const handlePlayPauseClick = (e) => {
    props.handlePlayPauseClick(e);
  }

  const handleScrub = (e, newValue : number) => {
    let video = document.getElementById("localVideo") as HTMLVideoElement;
    if (video) {
      let time = video.duration * (newValue / 100);

      video.currentTime = time;

      let currentTimeString = time.toString();

      let minutes = Math.floor(parseInt(currentTimeString, 10) / 60);
      let seconds = Math.round(time % 60).toString();

      if (seconds.toString().length === 1) {
        seconds = "0" + seconds;
      }
      
      setVideoCurrentTime(`${minutes}:${seconds}`);

    }


    setSeekbarValue(newValue);

    e.stopPropagation();
  }

  const handleUpdateSeekBar = () => {
    let video = document.getElementById("localVideo") as HTMLVideoElement;
    let value = (100 / video.duration) * video.currentTime;

    //Update state to display the current time
    let currentTimeString = (video.currentTime / 60).toString();
    let minutes = parseInt(currentTimeString, 10);

    let seconds = Math.round(video.currentTime % 60).toString();

    //Add 0 before seconds if length is only 1
    if (seconds.toString().length === 1) {
      seconds = "0" + seconds; 
    }

    //Set duration for time display
    setVideoCurrentTime(`${minutes}:${seconds}`)

    setSeekbarValue(value);
  }

  const handleVideoMuteToggle = (e) => {

    let video = document.getElementById("localVideo") as HTMLVideoElement;

    if (props.videoMuted) {
      video.muted = false;

      //Set slider value and video volume to be value in context (if exists)
      let volumeValue = localVideoVolume ? localVideoVolume : video.volume;

      //If we are streaming to room, set volume value on server
      if (props.streamVideo) SHOWBOAT.UIEventManager.OnLocalVideoShareVolumeChange.Raise(volumeValue);

      props.handleLocalVideoVolumeChange(volumeValue * 100);
    } else {
      //Store the volume value in context to be able to reset it on unmute
      setLocalVideoVolume(video.volume);

      video.muted = true;
    }

    props.handleVideoMutedToggle();

    /* if (e) {
      e.stopPropogation();
    } */
  }

  const handleLocalVideoVolumeChange = (e, newValue: number) => {

    props.handleLocalVideoVolumeChange(newValue);

    e.stopPropagation();
  }

  const handleMuteButtonMouseLeave = (e) => {
    let toElement = (e.toElement || e.relatedTarget);

    //If we are moving to volume slider holder, do not close the slider holder

    if (document.getElementById("volumeSliderHolder")) {
      if (
        document.getElementById("volumeSliderHolder").contains(toElement)
        || toElement.id === "volumeHolder"
        || toElement.id === "volumeSliderHolder") return;
    }

    toggleShowVolume(false);

  }

  const handleVolumeSliderMouseLeave = (e) => {
    let toElement = (e.toElement || e.relatedTarget);

    //If we are moving to the mute button, do not close slider

    if (
      document.getElementById("volumeButton").contains(toElement)
      || toElement.id === "volumeButton"
      || toElement.id === "volumeHolder") return;

    toggleShowVolume(false);
  }
  
  const classes = LocalVideoControlsStyles();

  return (
    <Paper 
      className={classes.localVideoControlsHolder}
      onClick={(e) => {e.stopPropagation()}}
    >

      <IconButton 
        className={`${classes.playButton} cancelDrag`}
        onClick={(e) => handlePlayPauseClick(e)}
        id="playPauseButton"
      >
        {props.play 
          ? <PauseIcon />
          : <PlayIcon />
        }
      </IconButton>

      <div className={styles.sliderHolder}>
        <Slider
          color="primary"
          className={classes.controlsSlider}
          value={seekbarValue}
          onChange={handleScrub}
        >

        </Slider>

        <div className={styles.timeHolder}>
          <Typography variant="body1" className={classes.videoTime}>
            {videoCurrentTime === "" ? "0:00" : videoCurrentTime} / {videoDuration}
          </Typography>
        </div>
      </div>

      <div 
        className={styles.volumeHolder} 
        id="volumeHolder" 
        onMouseEnter={() => toggleShowVolume(true)}
        onMouseLeave={handleMuteButtonMouseLeave}
      >
        <IconButton
          className={`${classes.volumeButton} cancelDrag`}
          onClick={(e) => handleVideoMuteToggle(e)}
          id="volumeButton"
        >
          {props.videoMuted
            ? <VolumeMuteIcon />
            : <VolumeUpIcon />
          }
        </IconButton>

        {showVolume &&
          <Paper 
            className={classes.volumeSliderHolder}
            onMouseLeave={handleVolumeSliderMouseLeave}
            id="volumeSliderHolder"
          >
            <Slider
              value={props.localVideoVolume}
              orientation="vertical"
              onChange={handleLocalVideoVolumeChange}
              className={classes.volumeSlider}
              classes={{
                thumb: classes.volumeThumb
              }}
            >

            </Slider>

          </Paper>
        }
      </div>

      

    </Paper>
  )
}
