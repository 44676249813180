import { IconButton } from '@material-ui/core';
import ArrowUpIcon from '@material-ui/icons/ArrowDropUp';
import React, { ReactElement } from 'react';
import { CloseIconButtonStyles } from './CloseIconButtonStyles';

interface Props {
  handleCloseButtonClick: () => void,
}

export default function CloseIconButton(props: Props): ReactElement {

  const handleCloseButtonClick = (e) => {
    props.handleCloseButtonClick();

    e.stopPropagation();
  }
  
  const classes = CloseIconButtonStyles();
  
  return (
    <IconButton
      className={classes.closeIconButton}
      onClick={handleCloseButtonClick}
    >
      <ArrowUpIcon className={classes.arrowUpIcon} />
    </IconButton>
  )
}
