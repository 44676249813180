import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import MicIcon from "@material-ui/icons/Mic";
import CamIcon from "@material-ui/icons/Videocam";
import CamMutedIcon from "@material-ui/icons/VideocamOff";
import SettingsIcon from "@material-ui/icons/Settings";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import PeopleIcon from "@material-ui/icons/PeopleAlt";
import ChatIcon from "@material-ui/icons/Chat";
import LocationIcon from "@material-ui/icons/LocationOn";
import SupportIcon from "@material-ui/icons/Help";
import CalendarIcon from "@material-ui/icons/Today";
import CameraIcon from "@material-ui/icons/CameraAlt";
import { Badge } from "@material-ui/core";
import { CallEnd, MicOff } from "@material-ui/icons";

import {
  DeviceContext,
  DeviceContextType,
} from "../../../../context/DeviceContext";
import { AppContext, AppContextType } from "../../../../context/AppContext";
import { ServerHelper } from "../../../../Utilities/ServerHelper";
import {
  RoomAvailabilityContext,
  RoomAvailabilityContextType,
} from "../../../../context/RoomAvailabilityContext";
import {
  PresenterContext,
  PresenterContextType,
} from "../../../../context/PresenterContext";
import {
  MessageContext,
  MessageContextType,
} from "../../../../context/MessageContext";
import { StringVariableHelper } from "../../../../Utilities/StringVariableHelper";
import ControlCenterButton from "../ControlCenterButton/ControlCenterButton";

import styles from "./ControlCenterButtons.module.css";
import { ControlCenterButtonsStyles } from "./ControlCenterButtonsStyles";
import useGetDevicePreferences from "../../../../hooks/useGetDevicePreferences";

interface Props {}

export default function ControlCenterButtons(props: Props): ReactElement {
  //Context
  const { isMicEnabled, isCameraEnabled }: DeviceContextType =
    useContext(DeviceContext);

  const { moreThanOneRoom }: RoomAvailabilityContextType = useContext(
    RoomAvailabilityContext
  );

  const {
    setCurrentSidebarMode,
    currentSidebarMode,
    jumbotronFullscreenView,
    toggleJumbotronFullscreenView,
    photoPreview,
    setPhotoPreview,
  }: AppContextType = useContext(AppContext);

  const { isAudienceMuted, currentMode, isBackstage }: PresenterContextType =
    useContext(PresenterContext);

  const { newMessage, toggleNewMessage }: MessageContextType =
    useContext(MessageContext);

  const { storeCachedPreferredCamState, storeCachedPreferredMicState } =
    useGetDevicePreferences();

  const [isIOS, toggleIsIOS] = useState(false);
  const [isFullscreen, toggleIsFullscreen] = useState(false);

  const [settingsTooltip, toggleSettingsTooltip] = useState(false);
  const [attendeesTooltip, toggleAttendeesTooltip] = useState(false);
  const [roomTooltip, toggleRoomTooltip] = useState(false);
  const [chatTooltip, toggleChatTooltip] = useState(false);
  const [supportTooltip, toggleSupportTooltip] = useState(false);
  const [exitTooltip, toggleExitTooltip] = useState(false);
  const [calendarTooltip, toggleCalendarTooltip] = useState(false);

  const rippleInterval = useRef(null);
  const rippleTimeout = useRef(null);

  useEffect(() => {
    function iOS() {
      return (
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document)
      );
    }

    //Check if user is using iOS. If so, disable full-screen toggle
    if (iOS()) {
      toggleIsIOS(true);
    }

    //Add event listeners for full screen changes
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);

    return function cleanup() {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullScreenChange
      );

      if (rippleInterval.current) {
        clearInterval(rippleInterval.current);
      }

      if (rippleTimeout.current) {
        clearTimeout(rippleTimeout.current);
      }
    };
  }, []);

  const handleFullScreenChange = () => {
    if (
      !(document as any).fullscreenElement &&
      !(document as any).webkitIsFullScreen &&
      !(document as any).mozFullScreen &&
      !(document as any).msFullscreenElement
    ) {
      toggleIsFullscreen(false);
    }
  };

  //Mic mute toggle
  const handleMuteMicToggle = () => {
    if (SHOWBOAT.StreamingUserMedia.isOperationInProgress()) return;

    //Store preference in Local Storage
    storeCachedPreferredMicState(!isMicEnabled);

    //Toggle mic device state
    SHOWBOAT.StreamingUserMedia.SetDeviceStates(isCameraEnabled, !isMicEnabled);
  };

  //Cam mute toggle
  const handleMuteCameraToggle = () => {
    if (SHOWBOAT.StreamingUserMedia.isOperationInProgress()) return;

    //Store preference in Local Storage
    storeCachedPreferredCamState(!isCameraEnabled)

    //Toggle camera device state
    SHOWBOAT.StreamingUserMedia.SetDeviceStates(!isCameraEnabled, isMicEnabled);
  };

  //Chat toggle
  const handleChatToggle = () => {
    //Hide chat tooltip
    toggleChatTooltip(false);

    //Hide new message notification and clear interval/timeout
    toggleNewMessage(false);

    setCurrentSidebarMode(currentSidebarMode === "chat" ? "" : "chat");
  };

  //Attendees toggle
  const handleAttendeesToggle = () => {
    //Hide attendees tooltip
    toggleAttendeesTooltip(false);

    setCurrentSidebarMode(
      currentSidebarMode === "attendees" ? "" : "attendees"
    );
  };

  //Room toggle
  const handleRoomToggle = () => {
    //Hide room tooltip
    toggleRoomTooltip(false);

    setCurrentSidebarMode(currentSidebarMode === "room" ? "" : "room");
  };

  //Settings sidebar
  const handleSettingsSidebarToggle = () => {
    //Hide settings tooltip
    toggleSettingsTooltip(false);

    setCurrentSidebarMode(currentSidebarMode === "settings" ? "" : "settings");
  };

  //Exit toggle
  const handleExitToggle = () => {
    //Hide exit tooltip
    toggleExitTooltip(false);

    setCurrentSidebarMode(currentSidebarMode === "exit" ? "" : "exit");
  };

  //Support toggle
  const handleSupportToggle = () => {
    //Hide support tooltip
    toggleSupportTooltip(false);

    setCurrentSidebarMode(currentSidebarMode === "support" ? "" : "support");
  };

  //Screenshot button
  const handleScreenshotClick = () => {
    //Raise event to capture screen
    SHOWBOAT.UIEventManager.OnImageCapture.Raise();

    //Show photo preview popup
    setPhotoPreview(true);
  };

  //Fullscreen
  const handleFullscreenClick = () => {
    var doc = window.document as any;
    var docEl = doc.documentElement as any;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }

    toggleIsFullscreen(!isFullscreen);
  };

  //2D Jumbotron View
  const handle2DViewClick = () => {
    //Send corresponding event
    SHOWBOAT.UIEventManager.OnFullscreenPresentationToggle.Raise(
      !jumbotronFullscreenView,
      false,
      currentSidebarMode !== ""
    );

    toggleJumbotronFullscreenView(!jumbotronFullscreenView);
  };

  //Calendar
  const handleCalendarToggle = () => {
    //Hide calendar tooltip
    toggleCalendarTooltip(false);

    if (currentSidebarMode === "calendar") {
      setCurrentSidebarMode("");
    } else {
      setCurrentSidebarMode("calendar");
    }
  };

  const classes = ControlCenterButtonsStyles();

  return (
    <div className={styles.controlCenterButtonsHolder}>
      <ControlCenterButton
        title={isMicEnabled ? " MUTE MIC" : "UNMUTE MIC"}
        enterDelay={200}
        enterNextDelay={200}
        buttonClasses={
          isAudienceMuted &&
          !(
            currentMode === StringVariableHelper.ShowboatModeType.presenter ||
            isBackstage
          )
            ? `${classes.controlCenterButton} ${classes.controlCenterButtonAudienceMuted}`
            : isMicEnabled
            ? classes.controlCenterButton
            : `${classes.controlCenterButton} ${classes.controlCenterButtonMuted}`
        }
        onClick={handleMuteMicToggle}
      >
        {isMicEnabled ? (
          <MicIcon className={classes.controlCenterButtonIcon} />
        ) : (
          <MicOff className={classes.controlCenterButtonIcon} />
        )}
      </ControlCenterButton>

      <ControlCenterButton
        title={isCameraEnabled ? "MUTE CAMERA" : "UNMUTE CAMERA"}
        enterDelay={150}
        enterNextDelay={150}
        buttonClasses={
          isAudienceMuted &&
          !(
            currentMode === StringVariableHelper.ShowboatModeType.presenter ||
            isBackstage
          )
            ? `${classes.controlCenterButton} ${classes.controlCenterButtonAudienceMuted}`
            : isCameraEnabled
            ? classes.controlCenterButton
            : `${classes.controlCenterButton} ${classes.controlCenterButtonMuted}`
        }
        onClick={handleMuteCameraToggle}
      >
        {isCameraEnabled ? (
          <CamIcon className={classes.controlCenterButtonIcon} />
        ) : (
          <CamMutedIcon className={classes.controlCenterButtonIcon} />
        )}
      </ControlCenterButton>

      <ControlCenterButton
        title="SETTINGS"
        enterDelay={150}
        enterNextDelay={150}
        buttonClasses={
          currentSidebarMode === "settings"
            ? `${classes.controlCenterButton} ${classes.controlCenterButtonActivated}`
            : classes.controlCenterButton
        }
        onClick={handleSettingsSidebarToggle}
        onTooltipOpen={() => toggleSettingsTooltip(true)}
        buttonOnMouseLeave={() => toggleSettingsTooltip(false)}
        tooltipOpen={settingsTooltip}
      >
        <SettingsIcon className={classes.controlCenterButtonIcon} />
      </ControlCenterButton>

      <ControlCenterButton
        title="FULL SCREEN"
        enterDelay={150}
        enterNextDelay={150}
        onClick={handleFullscreenClick}
        buttonClasses={
          isFullscreen
            ? `${classes.controlCenterButton} ${classes.controlCenterButtonActivated}`
            : classes.controlCenterButton
        }
        buttonDisable={isIOS}
      >
        <FullscreenIcon className={classes.controlCenterButtonIcon} />
      </ControlCenterButton>

      <ControlCenterButton
        title="GUESTS"
        enterDelay={150}
        enterNextDelay={150}
        onClick={handleAttendeesToggle}
        buttonClasses={
          currentSidebarMode === "attendees"
            ? `${classes.controlCenterButton} ${classes.controlCenterButtonActivated}`
            : classes.controlCenterButton
        }
        onTooltipOpen={() => toggleAttendeesTooltip(true)}
        buttonOnMouseLeave={() => toggleAttendeesTooltip(false)}
        tooltipOpen={attendeesTooltip}
      >
        <PeopleIcon className={classes.controlCenterButtonIcon} />
      </ControlCenterButton>

      <ControlCenterButton
        title="CHAT"
        enterDelay={150}
        enterNextDelay={150}
        onClick={handleChatToggle}
        buttonClasses={
          currentSidebarMode === "chat"
            ? `${classes.controlCenterButton} ${classes.controlCenterButtonActivated} ${classes.controlCenterButtonChat}`
            : `${classes.controlCenterButton} ${classes.controlCenterButtonChat}`
        }
        onTooltipOpen={() => toggleChatTooltip(true)}
        buttonOnMouseLeave={() => toggleChatTooltip(false)}
        tooltipOpen={chatTooltip}
        buttonCustomStyles={{
          border: newMessage ? "none" : "2px solid transparent",
        }}
        buttonLabelClasses={classes.buttonLabel}
      >
        <ChatIcon
          className={`${classes.controlCenterButtonIcon} ${classes.chatIcon}`}
          id="chatIconControlCenter"
        />
        {newMessage && <Badge className={classes.badge}></Badge>}
      </ControlCenterButton>

      {/* Show room change button if presenter, or more than one room open */}
      {/*    {(SHOWBOAT.LocalAvatarDataManager.role ===
                StringVariableHelper.ShowboatRoles.presenter ||
                moreThanOneRoom) && ( */}
      <ControlCenterButton
        title="ROOM"
        enterDelay={150}
        enterNextDelay={150}
        onClick={handleRoomToggle}
        buttonClasses={
          currentSidebarMode === "room"
            ? `${classes.controlCenterButton} ${classes.controlCenterButtonActivated}`
            : classes.controlCenterButton
        }
        style={{
          opacity:
            SHOWBOAT.LocalAvatarDataManager.role ===
            StringVariableHelper.ShowboatRoles.presenter
              ? 1
              : moreThanOneRoom
              ? 1
              : 0.5,
        }}
        onTooltipOpen={() => toggleRoomTooltip(true)}
        buttonOnMouseLeave={() => toggleRoomTooltip(false)}
        tooltipOpen={roomTooltip}
      >
        <LocationIcon className={classes.controlCenterButtonIcon} />
      </ControlCenterButton>
      {/*   )} */}

      {/* Show calendar button if enabled in ServerHelper */}
      {ServerHelper.enableCalendar && (
        <ControlCenterButton
          title="SCHEDULE"
          enterDelay={150}
          enterNextDelay={150}
          onClick={handleCalendarToggle}
          buttonClasses={
            currentSidebarMode === "calendar"
              ? `${classes.controlCenterButton} ${classes.controlCenterButtonActivated}`
              : classes.controlCenterButton
          }
          onTooltipOpen={() => toggleCalendarTooltip(true)}
          buttonOnMouseLeave={() => toggleCalendarTooltip(false)}
          tooltipOpen={calendarTooltip}
        >
          <CalendarIcon className={classes.controlCenterButtonIcon} />
        </ControlCenterButton>
      )}

      <ControlCenterButton
        title="JUMBOTRON"
        enterDelay={150}
        enterNextDelay={150}
        onClick={handle2DViewClick}
        buttonClasses={
          jumbotronFullscreenView
            ? `${classes.controlCenterButton} ${classes.controlCenterButtonActivated}`
            : classes.controlCenterButton
        }
      >
        <img
          className={styles.jumbotronIcon}
          src={
            SHOWBOAT.ApplicationSkin.theme === "dark"
              ? "assets/images/2D-icon-white.svg"
              : "assets/images/2D-icon.svg"
          }
          alt="2d icon"
        />
      </ControlCenterButton>

      <ControlCenterButton
        title="TAKE PHOTO"
        enterDelay={150}
        enterNextDelay={150}
        onClick={handleScreenshotClick}
        buttonClasses={classes.controlCenterButton}
        buttonDisable={photoPreview}
      >
        <CameraIcon className={classes.controlCenterButtonIcon} />
      </ControlCenterButton>

      <ControlCenterButton
        title="HELP"
        enterDelay={150}
        enterNextDelay={150}
        onClick={handleSupportToggle}
        buttonClasses={
          currentSidebarMode === "support"
            ? `${classes.controlCenterButton} ${classes.controlCenterButtonActivated}`
            : classes.controlCenterButton
        }
        onTooltipOpen={() => toggleSupportTooltip(true)}
        buttonOnMouseLeave={() => toggleSupportTooltip(false)}
        tooltipOpen={supportTooltip}
      >
        <SupportIcon className={classes.controlCenterButtonIcon} />
      </ControlCenterButton>

      <ControlCenterButton
        title="EXIT"
        enterDelay={150}
        enterNextDelay={150}
        onClick={handleExitToggle}
        buttonClasses={
          currentSidebarMode === "exit"
            ? `${classes.controlCenterButton} ${classes.controlCenterButtonActivated}`
            : classes.controlCenterButton
        }
        onTooltipOpen={() => toggleExitTooltip(true)}
        buttonOnMouseLeave={() => toggleExitTooltip(false)}
        tooltipOpen={exitTooltip}
      >
        <CallEnd className={classes.controlCenterButtonIcon} />
      </ControlCenterButton>
    </div>
  );
}
