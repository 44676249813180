import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const RightBarStyles = makeStyles((theme: Theme) => createStyles({
  paper: {
    width: 340,
    display: "inline-block",
    overflow: "visible",
    right: 10,
    bottom: 0,
    height: "auto",
    pointerEvents: "none",
    border: "none",
    backgroundColor: "transparent",
    transition: "width 200ms cubic-bezier(0, 0, 0.2, 1)",
    [theme.breakpoints.down('md')]: {
      width: 300
    },
    [theme.breakpoints.down('sm')]: {
      width: 250
    }
  },
 
  icon: {
    position: "absolute",
    top: 16,
    left: 17,
    zIndex: 9998,
    fontSize: 30,
    color: theme.palette.text.primary,
    "&:hover": {
      cursor: "pointer"
    }
  },

})
)

export { RightBarStyles }