import React, {useState, useEffect } from 'react';

export const DeviceContext = React.createContext(null);

export interface DeviceContextType {
  isCameraEnabled : boolean,
  toggleIsCameraEnabled : (isCameraEnabled : boolean) => void,
  isMicEnabled : boolean,
  toggleIsMicEnabled : (isMicEnabled : boolean) => void,
  micDevices : Array<string>,
  changeMicDevices : (micDevices : Array<string>) => void,
  cameraDevices : Array<string>,
  changeCameraDevices: (cameraDevices: Array<string>) => void,
  speakerDevices : Array<string>,
  changeSpeakerDevices : (speakerDevices : Array<string>) => void
}

export const DeviceContextProvider = (props) => {

  const [ isCameraEnabled, toggleIsCameraEnabled ] = useState(SHOWBOAT.LocalAvatarDataManager.avatarData.cameraEnabled);
  const [ isMicEnabled, toggleIsMicEnabled ] = useState(SHOWBOAT.LocalAvatarDataManager.avatarData.micEnabled);
  const [ micDevices, changeMicDevices ] = useState([])
  const [ cameraDevices, changeCameraDevices ] = useState([])
  const [ speakerDevices, changeSpeakerDevices ] = useState([]);

  //listeners to UI changes
  useEffect(() => {
    SHOWBOAT.StreamingUserMedia.OnCameraStarted.Add(OnCameraStarted);
    SHOWBOAT.StreamingUserMedia.OnCameraStopped.Add(OnCameraStopped);
    SHOWBOAT.StreamingUserMedia.OnMicrophoneStarted.Add(OnMicrophoneStarted);
    SHOWBOAT.StreamingUserMedia.OnMicrophoneStopped.Add(OnMicrophoneStopped);
    
    return function cleanup() {
      SHOWBOAT.StreamingUserMedia.OnCameraStarted.Remove(OnCameraStarted);
      SHOWBOAT.StreamingUserMedia.OnCameraStopped.Remove(OnCameraStopped);
      SHOWBOAT.StreamingUserMedia.OnMicrophoneStarted.Remove(OnMicrophoneStarted);
      SHOWBOAT.StreamingUserMedia.OnMicrophoneStopped.Remove(OnMicrophoneStopped);
    }
  }, []);

  
  const OnCameraStarted = () => {
    toggleIsCameraEnabled(true);
  }

  const OnCameraStopped = () => {
    toggleIsCameraEnabled(false);
  };

  const OnMicrophoneStarted = () => {
    toggleIsMicEnabled(true);
  };

  const OnMicrophoneStopped = () => {
    toggleIsMicEnabled(false);
  };


  const deviceProviderValue : DeviceContextType = {
    isCameraEnabled,
    toggleIsCameraEnabled,
    isMicEnabled,
    toggleIsMicEnabled,
    micDevices,
    changeMicDevices,
    cameraDevices,
    changeCameraDevices,
    speakerDevices,
    changeSpeakerDevices
  }

  return (
    <DeviceContext.Provider
      value={deviceProviderValue}
    >
      {props.children}
    </DeviceContext.Provider>
  )

}