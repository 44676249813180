import { makeStyles, createStyles } from '@material-ui/core/styles';

const MobileControlsStyles = makeStyles((theme: any) => createStyles({
  arrowKeyHolder: {
    border: "2px solid",
    borderColor: "black",
    borderRadius: 5,
    backgroundColor: "rgba(255, 255, 255, 0.25)",
    width: 70,
    height: 70,
    display: "inline-block",
    position: "relative",
    zIndex: 10,
    touchAction: "manipulation",
    "&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.25)",
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.25)",
    }
  },
  icon: {
    color: "black",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: "auto",
    fontSize: 40,
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    }
  },
  iconUp: {
    transform: "rotate(-90deg)"
  },
  iconLeft: {
    transform: "rotate(180deg)"
  },
  iconDown: {
    transform: "rotate(90deg)"

  },
  iconRight: {

  }
})
)

export { MobileControlsStyles }; 