import { makeStyles, createStyles } from '@material-ui/core/styles';

const ColorSelectStyles = makeStyles((theme: any) => createStyles({
  colorSelectWrapper: {
    width: 560,
    height: 460,
    backgroundColor: theme.palette.intakePaper.main,
    position: "absolute",
    right: 0,
    bottom: -8,
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      width: 400,
      height: 400,
      bottom: 50,
    }
  },
  colorSelectHeader: {
    color: theme.palette.text.main,
    fontSize: 24,
    position: "absolute",
    top: 34,
    fontFamily: "Open Sans, sans-serif",
    left: 50,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      left: 30,
    }
  },
  backButton: {
    height: 46,
    width: 115,
  },
  nextButton: {
    backgroundColor: theme.palette.intakeBlue.main,
    height: 46,
    width: 115,
    color: "white",
  },
  colorInputPaper: {
    width: 104,
    height: 104,
    position: "relative",
    display: "inline-block",
    marginBottom: 15,
    marginRight: 15,
    outline: "none !important",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down('sm')]: {
      width: 75,
      height: 75,
      marginBottom: 5,
    }
  },
  colorInputPaperSelected: {
    width: 104,
    height: 104,
    marginRight: 15,
    marginBottom: 15,
    outline: "none !important",
    textAlign: "center",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down('sm')]: {
      width: 75,
      height: 75,
      marginBottom: 5,
    }
  },
  checkIcon: {
    color: theme.palette.intakePaper.main,
    position: "relative",
    top: 3,
    fontSize: 16,
    strokeWidth: 2,
  },
  selectedIcon: {
    position: "relative",
    bottom: 120,
    backgroundColor: theme.palette.text.primary,
    borderRadius: "50%",
    display: "inline-block",
    height: 25,
    width: 25,
    textAlign: "center",
    [theme.breakpoints.down('sm')]: {
      bottom: 92
    }
  }
})
)

export { ColorSelectStyles }