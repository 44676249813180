import React, { ReactElement, useState, useEffect } from "react";
import {
  MenuItem,
  Typography,
  FormControl,
  Select,
  Button,
} from "@material-ui/core";
import VolumeIcon from "@material-ui/icons/VolumeUp";

import { StringVariableHelper } from "../../Utilities/StringVariableHelper";

import styles from "./SpeakerSettings.module.css";
import { SpeakerSettingsStyles } from "./SpeakerSettingsStyles";

interface Props {
  intake: boolean;
  onSpeakerSettingsComplete?: () => void;
  onSpeakerSettingsBack?: () => void;
}

export default function SpeakerSettings(props: Props): ReactElement {
  /*State store */
  const [isTestingSpeakers, toggleIsTestingSpeakers] = useState(false);
  const [speakerList, setSpeakerList] = useState([]);
  const [currentSpeaker, setCurrentSpeaker] = useState(undefined);
  const [disableNextAndBackButton, toggleDisableNextAndBackButton] =
    useState(true);
  /**/

  /* initial speaker load */
  useEffect(() => {
    SHOWBOAT.SystemInformation.Load()
      .then(() => {
        if (SHOWBOAT.SystemInformation.AudioOutputDevices) {
          setSpeakerList(SHOWBOAT.SystemInformation.AudioOutputDevices);
          //Check if we have a current speaker
          updateCurrentSpeaker();
        }

        toggleDisableNextAndBackButton(false);
      })
      .catch(() => {});
  }, []);

  const updateCurrentSpeaker = () => {
    if (
      SHOWBOAT.SystemInformation.HasSpeakers &&
      SHOWBOAT.SystemInformation.AudioOutputDevices &&
      SHOWBOAT.SystemInformation.AudioOutputDevices.length > 0
    ) {
      let mediaDeviceID: string =
        SHOWBOAT.StreamingUserMedia.getCurrentSpeakerDevice();

      if (mediaDeviceID) {
        //locate the current speaker
        for (
          let i = 0;
          i < SHOWBOAT.SystemInformation.AudioOutputDevices.length;
          ++i
        ) {
          if (
            SHOWBOAT.SystemInformation.AudioOutputDevices[i].deviceId ===
            mediaDeviceID
          ) {
            //Set value in localStorage for preferredSpeaker
            localStorage.setItem(
              StringVariableHelper.LocalStorageProperties.PreferredSpeakerID,
              SHOWBOAT.SystemInformation.AudioOutputDevices[i].deviceId
            );

            setCurrentSpeaker(
              SHOWBOAT.SystemInformation.AudioOutputDevices[i]
            );
            break;
          }
        }
      } else {
        //try and assign the system default as the current speaker
        for (
          let i = 0;
          i < SHOWBOAT.SystemInformation.AudioOutputDevices.length;
          ++i
        ) {
          if (
            SHOWBOAT.SystemInformation.AudioOutputDevices[i].deviceId &&
            SHOWBOAT.SystemInformation.AudioOutputDevices[
              i
            ].deviceId.toLowerCase() === "default"
          ) {
            SHOWBOAT.StreamingUserMedia.SetSpeakerDevice(
              SHOWBOAT.SystemInformation.AudioOutputDevices[i].deviceId
            );

            //Set value in localStorage
            localStorage.setItem(
              StringVariableHelper.LocalStorageProperties.PreferredSpeakerID,
              SHOWBOAT.SystemInformation.AudioOutputDevices[i].deviceId
            );

            setCurrentSpeaker(
              SHOWBOAT.SystemInformation.AudioOutputDevices[i]
            );
            break;
          }
        }
      }
    }
  };

  const onSpeakerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) return;

    if (speakerList && speakerList.length > 0) {
      for (let i = 0; i < speakerList.length; ++i) {
        if (speakerList[i].deviceId === event.target.value) {
          SHOWBOAT.StreamingUserMedia.SetSpeakerDevice(event.target.value);

          //Set preferred speaker device in localStorage
          localStorage.setItem(
            StringVariableHelper.LocalStorageProperties.PreferredSpeakerID,
            speakerList[i].id
          );

          setCurrentSpeaker(speakerList[i]);
          break;
        }
      }
    }
  };

  useEffect(() => {
    let testAudio: any;

    if (!currentSpeaker) return;

    testAudio = document.getElementById("speakerTestAudio");

    if (typeof testAudio.sinkId !== "undefined") {
      testAudio
        .setSinkId(currentSpeaker.deviceId)
        .then(() => {})
        .catch((ex) => {
          SHOWBOAT.Logger.Log("Error setting sink ID on test audio");
          SHOWBOAT.Logger.Log(ex);
        });
    } else {
      SHOWBOAT.Logger.Log("Browser does not support changing speakers");
    }
  }, [currentSpeaker]);

  /**/

  const handleTestAudio = () => {
    let testAudio: any;

    testAudio = document.getElementById("speakerTestAudio");

    toggleIsTestingSpeakers(true);

    testAudio.play();

    testAudio.onended = () => {
      toggleIsTestingSpeakers(false);

      testAudio.pause();
      testAudio.currentTime = 0;
    };
  };

  const handleStopTestAudio = () => {
    let testAudio: any;

    testAudio = document.getElementById("speakerTestAudio");

    toggleIsTestingSpeakers(false);

    testAudio.pause();
    testAudio.currentTime = 0;
  };
  /**/

  let currentSpeakerDeviceID: string = currentSpeaker
    ? currentSpeaker.deviceId
    : "";
  let speakerMenuItems: JSX.Element[] = [];

  //populate speaker select dropdown list
  if (speakerList && speakerList.length > 0 && speakerList[0].deviceId) {
    speakerMenuItems = speakerList.map((mediaDevice: any) => {
      return (
        <MenuItem
          key={`${mediaDevice.deviceId} ${mediaDevice.label}`}
          value={mediaDevice.deviceId}
        >
          {mediaDevice.label}
        </MenuItem>
      );
    });
  } else {
    currentSpeakerDeviceID = "Unknown";
    speakerMenuItems.push(
      <MenuItem key={"Unknown"} value={"Unknown"}>
        {"Browser default speaker"}
      </MenuItem>
    );
  }

  //Material UI classes
  const classes = SpeakerSettingsStyles();

  //classes based on if intake or not

  return (
    <div
      className={styles.speakerSettingsHolder}
      style={{
        top: props.intake ? 0 : -16,
        position: props.intake ? "initial" : "relative",
      }}
    >
      <audio id="speakerTestAudio" src="assets/audio/speaker-test.wav"></audio>

      {props.intake && (
        <Typography variant="h1" classes={{ root: classes.avHeader }}>
          AV Settings
        </Typography>
      )}

      <Typography variant="h2" classes={{ root: classes.speakerSelectHeader }}>
        Speakers
      </Typography>

      {/* Only show speaker select if on Chrome */}
      {SHOWBOAT.SystemInformation.IsSetSinkIdSupported && (
        <FormControl classes={{ root: classes.speakerSelectHolder }}>
          <Select value={currentSpeakerDeviceID} onChange={onSpeakerChange}>
            {speakerMenuItems}
          </Select>
        </FormControl>
      )}

      {props.intake && (
        <div className={styles.buttonHolder}>
          <Button
            disabled={disableNextAndBackButton}
            classes={{ root: classes.backButton }}
            onClick={props.onSpeakerSettingsBack}
            variant="text"
          >
            BACK
          </Button>
          <Button
            disabled={disableNextAndBackButton}
            classes={{ root: classes.nextButton }}
            onClick={props.onSpeakerSettingsComplete}
            variant="contained"
            id="speakersNext"
          >
            NEXT
          </Button>
        </div>
      )}

      {isTestingSpeakers ? (
        <Button
          id="testSpeakersButton"
          variant="contained"
          color="primary"
          onClick={handleStopTestAudio}
          classes={{
            root: !SHOWBOAT.SystemInformation.IsSetSinkIdSupported
              ? `${classes.speakerTestButton} ${classes.speakerTestButtonNonChrome}`
              : classes.speakerTestButton,
            label: classes.speakerTestButtonLabel,
          }}
        >
          <VolumeIcon classes={{ root: classes.volumeIcon }} />
          Playing...
        </Button>
      ) : (
        <Button
          id="testSpeakersButton"
          variant="contained"
          color="primary"
          onClick={handleTestAudio}
          classes={{
            root: !SHOWBOAT.SystemInformation.IsSetSinkIdSupported
              ? `${classes.speakerTestButton} ${classes.speakerTestButtonNonChrome}`
              : classes.speakerTestButton,
            label: classes.speakerTestButtonLabel,
          }}
        >
          <VolumeIcon classes={{ root: classes.volumeIcon }} />
          Test Speakers
        </Button>
      )}
    </div>
  );
}
