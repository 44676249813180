import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const TeleportMessagePopupStyles = makeStyles((theme: Theme) => createStyles({
  teleportMessage: {
    position: "absolute",
    textAlign: "left",
    marginBottom: 10,
    top: 10,
    left: 0,
    width: 311,
    height: 122,
    display: "none",
    background: theme.palette.background.paper,
    pointerEvents: "all",
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      width: 250,
    }
  },
  teleportAvatar: {
    position: "absolute",
    top: 15,
    left: 15
  },
  teleportMessageText: {
    position: "relative",
    top: 18,
    fontSize: 14,
    left: 42,
    fontWeight: 600,
  },
  teleportMessageName: {
    fontSize: 20,
    position: "absolute",
    left: 22,
    width: 270,
    top: 38,
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      width: 220,
    }
  },
  locationIcon: {
    color: theme.palette.primary.main,
    position: "absolute",
    left: 18,
    top: 18,
  },
  teleportButtonHolder: {
    width: "100%",
    position: "relative",
    top: 16
  },
  testButton: {
    position: "relative",
    top: 125,
    zIndex: 10,
  },
  acceptButton: {
    position: "absolute",
    bottom: 10,
    right: 130,
  },
  declineButton: {
    position: "absolute",
    bottom: 10,
    right: 21,
  },
  acceptIcon: {

  },
  declineIcon: {

  }
})
)

export {TeleportMessagePopupStyles}