import React, {
  ReactElement,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import {
  CircularProgress,
  Paper,
  Typography,
  Button,
  Badge,
} from "@material-ui/core";
import axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import FailIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/HelpOutline";
import CameraIcon from "@material-ui/icons/Videocam";
import MicIcon from "@material-ui/icons/Mic";
import { isChrome, isSafari } from "react-device-detect";

import { isMobileOnly, isTablet } from "react-device-detect";
import SendToSupport from "../SendToSupport/SendToSupport";
import { StringVariableHelper } from "../../Utilities/StringVariableHelper";

import styles from "./SystemCheckPage.module.css";
import { SystemCheckPageStyles } from "./SystemCheckPageStyles";
import { ServerHelper } from "../../Utilities/ServerHelper";
import SystemCheckHeader from "../SystemCheckHeader/SystemCheckHeader";

interface Props {}

export default function SystemCheckPage(props: Props): ReactElement {
  const [, setPort] = useState(null);
  const [, setEngine] = useState("");

  const [currentState, setCurrentState] = useState("running");

  const [canReachAWS, toggleCanReachAWS] = useState(false);
  const [canReachGameServer, toggleCanReachGameServer] = useState(false);
  const [canReachLiveswitch, toggleCanReachLiveswitch] = useState(false);
  const [canUseAudioContext, toggleCanUseAudioContext] = useState(false);

  const [hasCamera, toggleHasCamera] = useState(false);
  const [hasMic, toggleHasMic] = useState(false);
  const [hasCameraPermissions, toggleHasCameraPermissions] = useState(false);
  const [
    hasMadeCameraPermissionsSelection,
    setHasMadeCameraPermissionsSelection,
  ] = useState(false);
  const [cameraPermissionsDismissed, setCameraPermissionsDismissed] =
    useState(false);
  const [hasMicPermissions, toggleHasMicPermissions] = useState(false);
  const [hasMadeMicPermissionsSelection, setHasMadeMicPermissionsSelection] =
    useState(false);
  const [hasCameraNames, toggleHasCameraNames] = useState(false);
  const [hasMicNames, toggleHasMicNames] = useState(false);
  const [micPermissionsDismissed, setMicPermissionsDismissed] = useState(false);

  const [hasSpeakerNames, toggleHasSpeakerNames] = useState(false);

  const [canScreenshare, toggleCanScreenshare] = useState(false);
  const [isWebRTCSupported, toggleIsWebRTCSupported] = useState(false);

  const [areWebWorkersSupported, toggleAreWebWorkersSupported] = useState(true);

  const [browserName, setBrowserName] = useState("");
  const [browserVersion, setBrowserVersion] = useState(null);

/*   const [browserError, toggleBrowserError] = useState(false); */

  const [operatingSystem, setOperatingSystem] = useState("");
  const [operatingSystemVersion, setOperatingSystemVersion] = useState("");

  const [gameServerLoader, toggleGameServerLoader] = useState(true);
  const [awsLoader, toggleAWSLoader] = useState(true);
  const [liveswitchLoader, toggleLiveswitchLoader] = useState(true);
  const [genericSystemInfoLoader, toggleGenericSystemInfoLoader] =
    useState(true);
  const [audioContextLoader, toggleAudioContextLoader] = useState(true);
  const [cameraLoader, setCameraLoader] = useState(true);
  const [micLoader, setMicLoader] = useState(true);
  const [speakerDeviceLoader, toggleSpeakerDeviceLoader] = useState(true);

  const [cameraPermissionsFailReason, setCameraPermissionsFailReason] =
    useState("Unable to access camera");
  const [micPermissionsFailReason, setMicPermissionsFailReason] = useState(
    "Unable to access microphone"
  );
  const [speakerDeviceFailReason, setSpeakerDeviceFailReason] = useState(
    "No speakers detected"
  );

  const [systemInformationLoader, toggleSystemInformationLoader] =
    useState(true);

  const [sendToSupport, toggleSendToSupport] = useState(false);

  const [bookingID, setBookingID] = useState("");

  const gameServerTimeoutRef = useRef(null);
  const serverConnectionPromiseResolverRef = useRef(null);
  const currentStateRef = useRef("running");

  const systemCheckIDRef = useRef("");

  /*Shadow refs */
  const canReachAWSRef = useRef(false);
  const canReachGameServerRef = useRef(false);
  const canReachLiveswitchRef = useRef(false);
  const hasCameraRef = useRef(false);
  const hasMicRef = useRef(false);
  const hasCameraPermissionsRef = useRef(false);
  const hasMicPermissionsRef = useRef(false);
  const canScreenshareRef = useRef(false);
  const isWebRTCSupportedRef = useRef(false);
  const areWebWorkersSupportedRef = useRef(false);
  const browserNameRef = useRef("");
  const browserVersionRef = useRef(null);
  const operatingSystemRef = useRef("");
  const operatingSystemVersionRef = useRef("");
  const canUseAudioContextRef = useRef(false);
  const speakerDeviceRef = useRef(null);
  /**/

  //Assemble function to send data to backend
  const sendSystemCheckData = useCallback(async () => {
    let speakerLabelArray = [];

    if (speakerDeviceRef.current && speakerDeviceRef.current.length) {
      for (let i = 0; i < speakerDeviceRef.current.length; i++) {
        speakerLabelArray.push(speakerDeviceRef.current[i]);
      }
    }

    //Assemble data obj
    let objToSend: Object = {
      firstName: "",
      lastName: "",
      bookingID:
        bookingID !== "" ? bookingID : "00000000-0000-0000-0000-000000000000",
      comment: "",
      data: {
        canReachAWS: canReachAWSRef.current,
        canReachGameServer: canReachGameServerRef.current,
        canReachLiveswitch: canReachLiveswitchRef.current,
        hasCamera: hasCameraRef.current,
        hasMic: hasMicRef.current,
        speakerDevices: speakerLabelArray,
        hasCameraPermissions: hasCameraPermissionsRef.current,
        hasMicPermissions: hasMicPermissionsRef.current,
        canScreenshare: canScreenshareRef.current,
        isWebRTCSupported: isWebRTCSupportedRef.current,
        browserName: browserNameRef.current,
        browserVersion: browserVersionRef.current,
        operatingSystem: operatingSystemRef.current,
        operatingSystemVersion: operatingSystemVersionRef.current,
        areWebWorkersSupported: areWebWorkersSupportedRef.current,
        canUseAudioContext: canUseAudioContextRef.current,
      },
      systemCheckID: systemCheckIDRef.current,
    };

    //Send data to backend
    if (process.env.REACT_APP_API_STAGE !== "dev") {
      await axios.post("/system-check", JSON.stringify(objToSend));
    }
  }, [bookingID]);

  useEffect(() => {
    let systemCheckIDString =
      StringVariableHelper.LocalStorageProperties.SystemCheckID;

    //Check if user has systemCheckID stored. If not, create one
    if (localStorage.getItem(systemCheckIDString) !== null) {
      systemCheckIDRef.current = localStorage.getItem(systemCheckIDString);
    } else {
      let newUUID: string = SHOWBOAT.LiveSwitchClientController.generateUUID();
      localStorage.setItem(systemCheckIDString, newUUID);
      systemCheckIDRef.current = newUUID;
    }

    let enginePort: number;
    let engineURL: string;

    //Check AWS Connection
    const awsConnectionCheck = async () => {
      try {
        let params = {
          timestamp: new Date().getTime(),
        };

        //This page is only shown if login code has any allowed "systemcheck" variant in first 11 characters
        //So, just check if login code has any additional characters at the end

        if (ServerHelper.loginCode.length > 11)
          (params as any).loginCode = ServerHelper.loginCode.substring(11);

        let response = await axios.get("/system-check", {
          params: params,
        });

        SHOWBOAT.Logger.Log("Reach AWS success");

        //Grab port to test in response
        setPort(response.data.port);
        enginePort = response.data.port;

        //Grab engine to test in response
        setEngine(response.data.engine);
        engineURL = response.data.engine;

        toggleAWSLoader(false);
        toggleCanReachAWS(true);
        canReachAWSRef.current = true;

        //Initiate liveswitch test
        await liveswitchCheck(
          response.data.url,
          response.data.token,
          response.data.appID,
          response.data.userID,
          response.data.deviceID,
          response.data.clientID
        );

        //Initiate socket server test
        await connectToSocketServer();
      } catch (error) {
        SHOWBOAT.Logger.Error("Error running system check", error);

        //Set fallback port and engine on a fail
        setPort(443);
        enginePort = 443;
        setEngine("engine.showboat.live");
        engineURL = "engine.showboat.live";

        //Initiate socket server test
        await connectToSocketServer();

        toggleAWSLoader(false);
        SHOWBOAT.Logger.Log("Reach AWS fail");
        toggleCanReachAWS(false);
        setCurrentState("failed");
        currentStateRef.current = "failed";
        canReachAWSRef.current = false;
      }
    };

    //Check all things SystemInformation
    const systemInformationCheck = async () => {
      await SHOWBOAT.SystemInformation.Load();

      //Todo... use stuff like OS, browser, etc...

      //Hide loader for all things system information
      toggleSystemInformationLoader(false);

      //Get camera/mic device lists
      getCameraAndMicDeviceLists();

      //Get speaker device list
      getSpeakerDeviceList();

      //Set OS name and version
      setOperatingSystem(SHOWBOAT.SystemInformation.OSName);
      operatingSystemRef.current = SHOWBOAT.SystemInformation.OSName;

      setOperatingSystemVersion(SHOWBOAT.SystemInformation.OSVersion);
      operatingSystemVersionRef.current = SHOWBOAT.SystemInformation.OSVersion;

      //Set Browser name and version
      setBrowserName(SHOWBOAT.SystemInformation.BrowserName);
      browserNameRef.current = SHOWBOAT.SystemInformation.BrowserName;

      //Check if we should show error due to browser
     /*  checkIfBrowserSupported(); */

      setBrowserVersion(SHOWBOAT.SystemInformation.BrowserVersion);
      browserVersionRef.current = SHOWBOAT.SystemInformation.BrowserVersion;

      //Camera and Mic
      toggleHasCamera(SHOWBOAT.SystemInformation.HasWebCamera);
      hasCameraRef.current = SHOWBOAT.SystemInformation.HasWebCamera;

      toggleHasMic(SHOWBOAT.SystemInformation.HasMicrophone);
      hasMicRef.current = SHOWBOAT.SystemInformation.HasMicrophone;

      toggleHasMicPermissions(
        SHOWBOAT.SystemInformation.HasMicrophonePermissions
      );
      hasMicPermissionsRef.current =
        SHOWBOAT.SystemInformation.HasMicrophonePermissions;

      toggleHasCameraPermissions(
        SHOWBOAT.SystemInformation.HasWebCameraPermissions
      );
      hasCameraPermissionsRef.current =
        SHOWBOAT.SystemInformation.HasWebCameraPermissions;

      toggleCanScreenshare(
        SHOWBOAT.SystemInformation.IsScreenCapturingSupported
      );
      canScreenshareRef.current =
        SHOWBOAT.SystemInformation.IsScreenCapturingSupported;

      toggleIsWebRTCSupported(SHOWBOAT.SystemInformation.IsWebRTCSupported);
      isWebRTCSupportedRef.current =
        SHOWBOAT.SystemInformation.IsWebRTCSupported;

      setMicLoader(false);
      setCameraLoader(false);
      toggleGenericSystemInfoLoader(false);
    };

    /* Game server check set-up */
    const OnLocalPlayerConnected = () => {
      //Clear error timeout
      if (gameServerTimeoutRef.current) {
        clearTimeout(gameServerTimeoutRef.current);
      }

      //Remove listeners
      SHOWBOAT.SocketIOController.OnLocalPlayerConnected.Remove(
        OnLocalPlayerConnected
      );
      SHOWBOAT.SocketIOController.OnConnectionTimeout.Remove(
        handleGameServerFail
      );

      //Resolve promise
      serverConnectionPromiseResolverRef.current(true);

      toggleGameServerLoader(false);
      toggleCanReachGameServer(true);
      canReachGameServerRef.current = true;
      SHOWBOAT.SocketIOController.Disconnect();
    };

    //Game server fail
    const handleGameServerFail = () => {
      //Clear error timeout
      if (gameServerTimeoutRef.current) {
        clearTimeout(gameServerTimeoutRef.current);
      }

      //Remove listeners
      SHOWBOAT.SocketIOController.OnLocalPlayerConnected.Remove(
        OnLocalPlayerConnected
      );
      SHOWBOAT.SocketIOController.OnConnectionTimeout.Remove(
        handleGameServerFail
      );

      setCurrentState("failed");
      currentStateRef.current = "failed";
      toggleGameServerLoader(false);
      toggleCanReachGameServer(false);
      canReachGameServerRef.current = false;
    };

    const connectToSocketServer = (): Promise<boolean> => {
      let promise = new Promise((resolve, reject) => {
        serverConnectionPromiseResolverRef.current = resolve;

        SHOWBOAT.SocketIOController.OnLocalPlayerConnected.Add(
          OnLocalPlayerConnected
        );

        SHOWBOAT.SocketIOController.Connect(engineURL, enginePort, true);

        gameServerTimeoutRef.current = setTimeout(function () {
          handleGameServerFail();
        }, 20000);

        SHOWBOAT.SocketIOController.OnConnectionTimeout.Add(
          handleGameServerFail
        );
      });

      return promise as Promise<boolean>;
    };

    /**/

    //Check web worker support
    if (typeof Worker !== "undefined") {
      //Web workers supported
      toggleAreWebWorkersSupported(true);
      areWebWorkersSupportedRef.current = true;
    } else {
      setCurrentState("failed");
      currentStateRef.current = "failed";
      toggleAreWebWorkersSupported(false);
      areWebWorkersSupportedRef.current = false;
    }

    const liveswitchCheck = async (
      url: string,
      token: string,
      appID: string,
      userID: string,
      deviceID: string,
      clientID: string
    ) => {
      let audioStream;
      try {
        //Create sample audio to stream
        let ctx = new AudioContext();
        let oscillator = ctx.createOscillator();
        let audioStreamDestination = ctx.createMediaStreamDestination();
        oscillator.connect(audioStreamDestination);
        audioStream = audioStreamDestination.stream;
        oscillator.start();

        //AudioContext success
        toggleAudioContextLoader(false);
        toggleCanUseAudioContext(true);
        canUseAudioContextRef.current = true;
      } catch {
        setCurrentState("failed");
        currentStateRef.current = "failed";

        //AudioContext fail
        toggleAudioContextLoader(false);
        toggleCanUseAudioContext(false);
        canUseAudioContextRef.current = false;

        //Also mark Liveswitch properties as fail
        toggleLiveswitchLoader(false);
        toggleCanReachLiveswitch(false);
        canReachLiveswitchRef.current = false;
        return;
      }

      try {
        let localMedia = new fm.liveswitch.LocalMedia(audioStream, false);

        //Create a client
        let client = new fm.liveswitch.Client(
          url,
          appID,
          userID,
          deviceID,
          clientID,
          []
        );

        //Register
        let channel: fm.liveswitch.Channel;

        let channels: fm.liveswitch.Channel[] = await client.register(token);

        if (!channels || channels.length === 0) {
          console.error("Missing liveswitch channels!");
          setCurrentState("failed");
          currentStateRef.current = "failed";
          toggleLiveswitchLoader(false);
          toggleCanReachLiveswitch(false);
          canReachLiveswitchRef.current = false;
          return;
        }

        channel = channels[0];

        await localMedia.start();

        //Connect
        if (!channel) {
          setCurrentState("failed");
          currentStateRef.current = "failed";
          toggleCanReachLiveswitch(false);
          canReachLiveswitchRef.current = false;
          toggleLiveswitchLoader(false);
          console.error("Missing liveswitch channel!");
          return;
        }

        let lsAudioStream = new fm.liveswitch.AudioStream(localMedia, null);
        let connection = channel.createSfuUpstreamConnection(lsAudioStream);
        await connection.open();

        setTimeout(async () => {
          await connection.close();
          await client.unregister();
        }, 500);

        toggleLiveswitchLoader(false);
        canReachLiveswitchRef.current = true;
        toggleCanReachLiveswitch(true);
      } catch (error) {
        setCurrentState("failed");
        currentStateRef.current = "failed";
        canReachLiveswitchRef.current = false;
        toggleCanReachLiveswitch(false);
        toggleLiveswitchLoader(false);
        console.error("Error Creating SFU Upstream Connection");
        console.error(error);
        return;
      }
    };

    //Execute all promises, then send data to backend
    let awsPromise = awsConnectionCheck();
    let systemInfoPromise = systemInformationCheck();

    Promise.all([awsPromise, systemInfoPromise])
      .then(() => {
        //Set as completed if we have cam/mic permissions
        if (
          SHOWBOAT.SystemInformation.HasWebCameraPermissions &&
          SHOWBOAT.SystemInformation.HasMicrophonePermissions &&
          currentStateRef.current !== "failed"
        ) {
          setCurrentState("completed");
          currentStateRef.current = "completed";
        }

        //Send the data to axios
        sendSystemCheckData();

        //FOR TESTING
        /* toggleCanScreenshare(false);
        toggleCanReachAWS(false);
        toggleCanReachGameServer(false);
        toggleCanReachLiveswitch(false);
        toggleAreWebWorkersSupported(false);
        toggleHasCamera(false);
        toggleHasMic(false);
        toggleIsWebRTCSupported(false); */
      })
      .catch(() => {
        //Ensure state is set to "failed"
        setCurrentState("failed");
        currentStateRef.current = "failed";

        //Send the data to axios
        sendSystemCheckData();
      });

    return function cleanup() {
      SHOWBOAT.SocketIOController.OnLocalPlayerConnected.Remove(
        OnLocalPlayerConnected
      );
      SHOWBOAT.SocketIOController.OnConnectionTimeout.Remove(
        handleGameServerFail
      );

      if (gameServerTimeoutRef.current) {
        clearTimeout(gameServerTimeoutRef.current);
      }
    };
  }, [sendSystemCheckData]);

  const tryCameraPermissions = async () => {
    //Show spinner
    setCameraLoader(true);

    let response =
      await SHOWBOAT.SystemInformation.RequestCameraAndMicPermissions(
        true,
        false
      );
    
    setHasMadeCameraPermissionsSelection(true);

    //If we fail, set fail reason
    if (!response.success) {
      //Check if popup was dismissed
      if (response.error.message === "Permission dismissed") {
        setCameraPermissionsFailReason(
          "Camera permissions need to be accepted in order to complete system check"
        );
        setCameraPermissionsDismissed(true);
      } else {
        setCameraPermissionsDismissed(false);

        setCurrentState("failed");
        currentStateRef.current = "failed";

        //If we are on safari, hide the speaker loader
        if (isSafari && SHOWBOAT.SystemInformation.HasMicrophonePermissions) {
          toggleSpeakerDeviceLoader(false);
        }

        //Set correct version of fail reason
        if (
          response.error.message === "Permission denied" ||
          response.error.message ===
            "The request is not allowed by the user agent or the platform in the current context." ||
          response.error.message ===
            "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."
        ) {
          setCameraPermissionsFailReason(
            "Your browser has blocked permissions to your camera"
          );
        }
      }
    }

    //If permissions are given, re-load SystemInformation and get device lists
    if (SHOWBOAT.SystemInformation.HasWebCameraPermissions) {
      setCameraPermissionsDismissed(false);

      await SHOWBOAT.SystemInformation.Load();

      getCameraAndMicDeviceLists();
      getSpeakerDeviceList();

      //Ensure test has not failed from something else before marking as completed
      if (
        (SHOWBOAT.SystemInformation.HasMicrophonePermissions &&
          currentStateRef.current !== "failed") ||
        (SHOWBOAT.SystemInformation.HasWebCameraPermissions &&
          (hasMadeMicPermissionsSelection ||
            hasMadeCameraPermissionsSelection) &&
          isSafari)
          && !awsLoader
          && !gameServerLoader
          && !liveswitchLoader
      ) {
        setCurrentState("completed");
        currentStateRef.current = "completed";
      }
    }

    //Show result
    setCameraLoader(false);
    toggleHasCameraPermissions(
      SHOWBOAT.SystemInformation.HasWebCameraPermissions
    );
    hasCameraPermissionsRef.current =
      SHOWBOAT.SystemInformation.HasWebCameraPermissions;

    toggleHasMicPermissions(
      SHOWBOAT.SystemInformation.HasMicrophonePermissions
    );
    hasMicPermissionsRef.current =
      SHOWBOAT.SystemInformation.HasMicrophonePermissions;

    //Resend data to backend
    sendSystemCheckData();
  };

  const tryMicrophonePermissions = async () => {
    //Show spinner
    setMicLoader(true);

    let response =
      await SHOWBOAT.SystemInformation.RequestCameraAndMicPermissions(
        false,
        true
      );

    setHasMadeMicPermissionsSelection(true);

    //If we fail, set fail reason
    if (!response.success) {
      //Check if popup was dismissed
      if (response.error.message === "Permission dismissed") {
        setMicPermissionsFailReason(
          "Microphone permissions need to be accepted in order to complete system check"
        );
        setMicPermissionsDismissed(true);
      } else {
        setMicPermissionsDismissed(false);

        setCurrentState("failed");
        currentStateRef.current = "failed";

        //Hide speaker loader
        toggleSpeakerDeviceLoader(false);

        //Set correct version of fail reason
        if (
          response.error.message === "Permission denied" ||
          response.error.message ===
            "The request is not allowed by the user agent or the platform in the current context." ||
          response.error.message ===
            "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission."
        ) {
          setMicPermissionsFailReason(
            "Your browser has blocked permissions to your microphone"
          );
        } else {
          setMicPermissionsFailReason(
            "Permission is needed to access your microphone"
          );
        }
      }
    }

    //If permissions are given, load SystemInformation and get device lists
    if (SHOWBOAT.SystemInformation.HasMicrophonePermissions) {
      setMicPermissionsDismissed(false);

      await SHOWBOAT.SystemInformation.Load();

      getCameraAndMicDeviceLists();
      getSpeakerDeviceList();

      //Ensure test has not failed already from something else
      if (
        (SHOWBOAT.SystemInformation.HasWebCameraPermissions &&
          currentStateRef.current !== "failed") ||
        (SHOWBOAT.SystemInformation.HasMicrophonePermissions &&
          (hasMadeMicPermissionsSelection ||
            hasMadeCameraPermissionsSelection) &&
          isSafari)
          && !awsLoader
          && !gameServerLoader
          && !liveswitchLoader
      ) {
        setCurrentState("completed");
        currentStateRef.current = "completed";
      }
    } else {
        setSpeakerDeviceFailReason(
          "Microphone permissions are required to view speaker list"
        );
    }

    //Show result
    setMicLoader(false);
    toggleHasMicPermissions(
      SHOWBOAT.SystemInformation.HasMicrophonePermissions
    );
    hasMicPermissionsRef.current =
      SHOWBOAT.SystemInformation.HasMicrophonePermissions;

    toggleHasCameraPermissions(
      SHOWBOAT.SystemInformation.HasWebCameraPermissions
    );
    hasCameraPermissionsRef.current =
      SHOWBOAT.SystemInformation.HasWebCameraPermissions;

    //Resend data to backend
    sendSystemCheckData();
  };

  //Get camera and mic device lists
  const getCameraAndMicDeviceLists = () => {
    //Toggle both loaders on
    setCameraLoader(false);
    setMicLoader(false);

    //Camera
    if (
      SHOWBOAT.SystemInformation.HasWebCamera &&
      SHOWBOAT.SystemInformation.HaveCameraNames
    ) {
      setCameraLoader(false);
      toggleHasCameraNames(true);
    } else {
      setCameraLoader(false);
      toggleHasCameraNames(false);
    }

    //Mic
    if (
      SHOWBOAT.SystemInformation.HasMicrophone &&
      SHOWBOAT.SystemInformation.HaveMicrophoneNames
    ) {
      setMicLoader(false);
      toggleHasMicNames(true);
    } else {
      setMicLoader(false);
      toggleHasMicNames(false);
    }
  };

  //Get speaker device lists
  const getSpeakerDeviceList = async () => {
    //Toggle loader on
    toggleSpeakerDeviceLoader(true);

    if (
      SHOWBOAT.SystemInformation.HasSpeakers &&
      SHOWBOAT.SystemInformation.HaveSpeakerNames
    ) {
      //Assign ref
      speakerDeviceRef.current = SHOWBOAT.SystemInformation.AudioOutputDevices;

      toggleSpeakerDeviceLoader(false);
      toggleHasSpeakerNames(true);
    } else {
      //Check if mic permissions have been granted
      //If not, tell user they are needed
      if (!SHOWBOAT.SystemInformation.HasMicrophonePermissions) {
        if (hasMadeMicPermissionsSelection) {
          toggleSpeakerDeviceLoader(false);

          setSpeakerDeviceFailReason(
            "Microphone permissions are required to view speaker list"
          );
        }
      } else {
        //Mic permissions have been granted. If we are not on chrome, just show the generic list

        if (!isChrome) {
          toggleHasSpeakerNames(true);
          toggleSpeakerDeviceLoader(false);
        }

        //System should be able to access speakers and none detected
        //Fail check, but only if mic permissions have been tried
        if (hasMadeMicPermissionsSelection) {
          currentStateRef.current = "failed";
          setCurrentState("failed");
        }
      }

      if (!isChrome && SHOWBOAT.SystemInformation.HasMicrophonePermissions) {
        toggleHasSpeakerNames(true);
      } else {
        toggleHasSpeakerNames(false);
      }
    }
  };

  //Check if browser is supported
/*   const checkIfBrowserSupported = () => {
    let browserName: string = SHOWBOAT.SystemInformation.BrowserName;
    let browserVersion: string =
      SHOWBOAT.SystemInformation.BrowserVersion.toString();

    //Show error on IE (any version) and Safari <v11
    if (browserName === "Safari") {
      //Check if version is less than 11
      let versionTrimmed: string = browserVersion.substring(0, 2);

      if (parseInt(versionTrimmed) < 11) {
        toggleBrowserError(true);
        return;
      }
    } else if (browserName === "Internet Explorer") {
      toggleBrowserError(true);
      return;
    }
  }; */

  //Send to support handler
  const handleSendToSupportClick = () => {
    toggleSendToSupport(true);

    //Assemble object to send as prop
  };

  //Back handler from Send to Support page
  const handleSendToSupportBackClick = () => {
    toggleSendToSupport(false);
  };

  //Material UI classes
  const classes = SystemCheckPageStyles();

  //Set margin bottom of header based on current state
  let marginBottom;

  switch (currentState) {
    case "completed":
      marginBottom = "10px";
      break;
    case "failed":
      marginBottom = "10px";
      break;
    case "running":
      marginBottom = "-35px";
      if (
        !SHOWBOAT.SystemInformation.HasWebCameraPermissions &&
        !SHOWBOAT.SystemInformation.HasMicrophonePermissions
      ) {
        if (!cameraLoader && !micLoader) {
          marginBottom = "-50px";
        } else {
          marginBottom = "-35px";
        }
      } else {
        marginBottom = "-35px";
      }
      break;
  }

  return (
    <Paper square className={classes.systemCheckPageWrapper}>
      <div className={styles.systemCheckWrapper}>
        <div className={styles.logoHolder}>
          <img
            className={styles.showboatLogo}
            alt="Showboat Logo"
            src="assets/images/sb-logo.png"
          />

          <Typography variant="h2" className={classes.showboatHeader}>
            Showboat
          </Typography>
        </div>

        <Paper className={classes.systemCheckHolder}>
          {sendToSupport ? (
            <SendToSupport
              handleBackClick={handleSendToSupportBackClick}
              dataObj={{
                canReachAWS,
                canReachGameServer,
                canReachLiveswitch,
                hasCamera,
                hasMic,
                hasCameraPermissions,
                hasMicPermissions,
                canScreenshare,
                isWebRTCSupported,
                browserName,
                browserVersion,
                operatingSystem,
                operatingSystemVersion,
                areWebWorkersSupported,
                canUseAudioContext,
                speakerDeviceArray: speakerDeviceRef.current,
              }}
              bookingID={bookingID}
              systemCheckID={systemCheckIDRef.current}
            />
          ) : (
            <div className={styles.systemCheckContentHolder}>
              <div
                className={styles.systemCheckHeaderHolder}
                style={{ marginBottom: marginBottom }}
              >
                <SystemCheckHeader
                  sendToSupport={handleSendToSupportClick}
                  currentState={currentState}
                  hasCameraPermissions={hasCameraPermissions}
                  hasMicPermissions={hasMicPermissions}
                />
              </div>

              <div className={styles.systemCheckItemsHolder}>
                {/************** CAMERA COMPATIBILITY ****************************/}

                <div
                  className={
                    !hasCameraPermissions && !hasMadeCameraPermissionsSelection
                      ? `${styles.systemsCheckItem} ${styles.systemsCheckItemPermissions} ${styles.cameraItem}`
                      : `${styles.systemsCheckItem} ${styles.cameraItem}`
                  }
                  id="cameraPermissionsItem"
                >
                  {cameraLoader || cameraPermissionsDismissed ? (
                    <CircularProgress
                      className={
                        cameraPermissionsDismissed
                          ? `${classes.itemLoadingSpinner} ${classes.itemIconError}`
                          : classes.itemLoadingSpinner
                      }
                    />
                  ) : hasCameraPermissions ? (
                    <CheckIcon
                      style={{ color: "#93FF00" }}
                      className={`${classes.itemIcon} ${classes.itemIconError}`}
                    />
                  ) : hasMadeCameraPermissionsSelection ? (
                    <FailIcon
                      style={{ color: "#FF0092" }}
                      className={
                        !cameraLoader && !hasCameraPermissions
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  ) : (
                    <CameraIcon className={classes.itemIcon} />
                  )}

                  <Typography
                    variant="h3"
                    className={
                      !hasCameraPermissions && hasMadeCameraPermissionsSelection
                        ? `${classes.systemsCheckItemHeader} ${classes.systemsCheckItemHeaderError} ${classes.systemCheckItemHeaderCamOrMic}`
                        : `${classes.systemsCheckItemHeader} ${classes.systemCheckItemHeaderCamOrMic}`
                    }
                  >
                    Camera Compatibility
                  </Typography>

                  {!cameraLoader && hasCameraNames && (
                    <ul className={styles.cameraAndMicList}>
                      {SHOWBOAT.SystemInformation.VideoInputDevices.map(
                        (camDevice) => (
                          <li
                            key={camDevice.deviceId}
                            className={classes.camAndMicListItem}
                          >
                            {camDevice.label}
                          </li>
                        )
                      )}
                    </ul>
                  )}

                  {!cameraLoader &&
                    !hasCameraPermissions &&
                    hasMadeCameraPermissionsSelection && (
                      <Typography
                        variant="body1"
                        className={
                          cameraPermissionsDismissed
                            ? `${classes.itemErrorText} ${classes.itemErrorTextDismissed}`
                            : classes.itemErrorText
                        }
                      >
                        {cameraPermissionsFailReason}
                      </Typography>
                    )}

                  {((!hasCameraPermissions &&
                    !hasMadeCameraPermissionsSelection) ||
                    cameraPermissionsDismissed) && (
                    <Button
                      disabled={
                        cameraPermissionsFailReason ===
                        "Your browser has blocked permissions to your camera"
                      }
                      className={
                        cameraPermissionsDismissed
                          ? `${classes.permissionsButton} ${classes.permissionsButtonDismissed}`
                          : classes.permissionsButton
                      }
                      classes={{
                        label: classes.permissionsLabel,
                      }}
                      onClick={tryCameraPermissions}
                      variant="contained"
                    >
                      <Typography
                        style={{
                          zIndex: 1,
                          fontFamily: "Roboto, sans-serif",
                          fontSize: 14,
                          fontWeight: "bold",
                          textTransform: "none",
                        }}
                      >
                        Request Access
                      </Typography>
                      <Badge className={classes.badge}></Badge>
                    </Button>
                  )}
                </div>

                {/************** MIC COMPATIBILITY ****************************/}

                <div
                  className={
                    !hasMicPermissions && !hasMadeMicPermissionsSelection
                      ? `${styles.systemsCheckItem} ${styles.systemsCheckItemPermissions} ${classes.systemCheckItemHeaderCamOrMic}`
                      : `${styles.systemsCheckItem} ${classes.systemCheckItemHeaderCamOrMic}`
                  }
                  id="micPermissionsItem"
                >
                  {micLoader || micPermissionsDismissed ? (
                    <CircularProgress
                      className={
                        micPermissionsDismissed
                          ? `${classes.itemLoadingSpinner} ${classes.itemIconError}`
                          : classes.itemLoadingSpinner
                      }
                    />
                  ) : hasMicPermissions ? (
                    <CheckIcon
                      style={{ color: "#93FF00" }}
                      className={`${classes.itemIcon} ${classes.itemIconError}`}
                    />
                  ) : hasMadeMicPermissionsSelection ? (
                    <FailIcon
                      style={{ color: "#FF0092" }}
                      className={
                        !micLoader && !hasMicPermissions
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  ) : (
                    <MicIcon className={classes.itemIcon} />
                  )}

                  <Typography
                    variant="h3"
                    className={
                      !hasMicPermissions && hasMadeMicPermissionsSelection
                        ? `${classes.systemsCheckItemHeader} ${classes.systemsCheckItemHeaderError}`
                        : classes.systemsCheckItemHeader
                    }
                  >
                    Mic Compatibility
                  </Typography>

                  {!micLoader && hasMicNames && (
                    <ul className={styles.cameraAndMicList}>
                      {SHOWBOAT.SystemInformation.AudioInputDevices.map(
                        (audioDevice) => (
                          <li
                            key={audioDevice.deviceId}
                            className={classes.camAndMicListItem}
                          >
                            {audioDevice.label}
                          </li>
                        )
                      )}
                    </ul>
                  )}

                  {!hasMicPermissions && hasMadeMicPermissionsSelection && (
                    <Typography
                      variant="body1"
                      className={
                        micPermissionsDismissed
                          ? `${classes.itemErrorText} ${classes.itemErrorTextDismissed}`
                          : classes.itemErrorText
                      }
                    >
                      {micPermissionsFailReason}
                    </Typography>
                  )}

                  {((!hasMicPermissions && !hasMadeMicPermissionsSelection) ||
                    micPermissionsDismissed) && (
                    <Button
                      disabled={
                        micPermissionsFailReason ===
                        "Your browser has blocked permissions to your microphone"
                      }
                      className={
                        micPermissionsDismissed
                          ? `${classes.permissionsButton} ${classes.permissionsButtonDismissed}`
                          : classes.permissionsButton
                      }
                      classes={{
                        label: classes.permissionsLabel,
                      }}
                      onClick={tryMicrophonePermissions}
                      variant="contained"
                    >
                      <Typography
                        style={{
                          zIndex: 1,
                          fontFamily: "Roboto, sans-serif",
                          fontSize: 14,
                          fontWeight: "bold",
                          textTransform: "none",
                        }}
                      >
                        Request Access
                      </Typography>
                      <Badge className={classes.badge}></Badge>
                    </Button>
                  )}
                </div>

                {/************** Speaker Device List ****************************/}
                <div className={styles.systemsCheckItem}>
                  {speakerDeviceLoader ? (
                    <CircularProgress
                      className={`${classes.itemLoadingSpinner} ${classes.itemLoadingSpinnerSpeakers}`}
                    />
                  ) : !hasSpeakerNames ? (
                    <FailIcon
                      style={{ color: "#FF0092" }}
                      className={
                        !micLoader && !hasMicPermissions
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  ) : (
                    <CheckIcon
                      style={{ color: "#93FF00" }}
                      className={classes.itemIconError}
                    />
                  )}

                  <Typography
                    variant="h3"
                    className={
                      !speakerDeviceLoader
                        ? `${classes.systemsCheckItemHeader} ${classes.systemsCheckItemHeaderError}`
                        : classes.systemsCheckItemHeader
                    }
                  >
                    Speaker Devices
                  </Typography>

                  {!speakerDeviceLoader && hasSpeakerNames && (
                    <ul className={styles.cameraAndMicList}>
                      {SHOWBOAT.SystemInformation.AudioOutputDevices.length ? (
                        SHOWBOAT.SystemInformation.AudioOutputDevices.map(
                          (speakerDevice) => (
                            <li
                              key={speakerDevice.deviceId}
                              className={classes.camAndMicListItem}
                            >
                              {speakerDevice.label}
                            </li>
                          )
                        )
                      ) : (
                        <li
                          key={"system-default"}
                          className={classes.camAndMicListItem}
                        >
                          System Default
                        </li>
                      )}
                    </ul>
                  )}

                  {((!hasMicPermissions &&
                    !hasMadeMicPermissionsSelection &&
                    !micPermissionsDismissed &&
                    speakerDeviceLoader) ||
                    (micPermissionsDismissed && !hasMicPermissions)) && (
                    <ul className={styles.cameraAndMicList}>
                      <li className={classes.camAndMicListItem}>
                        Microphone permissions are required to view speaker list
                      </li>
                    </ul>
                  )}

                  {!speakerDeviceLoader && !hasSpeakerNames && (
                    <Typography
                      variant="body1"
                      className={classes.itemErrorText}
                    >
                      {speakerDeviceFailReason}
                    </Typography>
                  )}
                </div>
                {/************** OPERATING SYSTEM ****************************/}

                {/* <div className={styles.systemsCheckItem}>
                  {systemInformationLoader ? (
                    <CircularProgress className={classes.itemLoadingSpinner} />
                  ) : (
                    <CheckIcon
                      style={{ color: "#93FF00" }}
                      className={classes.itemIcon}
                    />
                  )}

                  <Typography
                    variant="h3"
                    className={classes.systemsCheckItemHeader}
                  >
                    Operating System: {operatingSystem} {operatingSystemVersion}
                  </Typography>
                </div> */}

                {/************** BROWSER ****************************/}

                {/* <div className={styles.systemsCheckItem}>
                  {systemInformationLoader ? (
                    <CircularProgress className={classes.itemLoadingSpinner} />
                  ) : browserError ? (
                    <FailIcon
                      style={{ color: "#FF0092" }}
                      className={
                        !systemInformationLoader && browserError
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  ) : (
                    <CheckIcon
                      style={{ color: "#93FF00" }}
                      className={
                        !systemInformationLoader && browserError
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  )}

                  <Typography
                    variant="h3"
                    className={
                      !systemInformationLoader && browserError
                        ? `${classes.systemsCheckItemHeader} ${classes.systemsCheckItemHeaderError}`
                        : classes.systemsCheckItemHeader
                    }
                  >
                    Browser: {browserName} {browserVersion}
                  </Typography>

                  {!systemInformationLoader && browserError && (
                    <Typography
                      variant="body1"
                      className={classes.itemErrorText}
                    >
                      Showboat currently only supports Chrome, Opera, Edge,
                      Firefox, and Safari above and including v11
                    </Typography>
                  )}
                </div> */}

                {/************** WEB WORKER ****************************/}
                <div className={styles.systemsCheckItem}>
                  {areWebWorkersSupported ? (
                    <CheckIcon
                      style={{ color: "#93FF00" }}
                      className={
                        !areWebWorkersSupported
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  ) : (
                    <InfoIcon
                      style={{ color: "#FF0092" }}
                      className={
                        !areWebWorkersSupported
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  )}

                  <Typography
                    variant="h3"
                    className={
                      !areWebWorkersSupported
                        ? `${classes.systemsCheckItemHeader} ${classes.systemsCheckItemHeaderError}`
                        : classes.systemsCheckItemHeader
                    }
                  >
                    Browser supports web workers
                  </Typography>

                  {!areWebWorkersSupported && (
                    <Typography
                      variant="body1"
                      className={classes.itemErrorText}
                    >
                      Showboat requires a browser that supports web workers
                    </Typography>
                  )}
                </div>

                {/************** Showboat Websocket Server ****************************/}

                <div className={styles.systemsCheckItem}>
                  {gameServerLoader ? (
                    <CircularProgress className={classes.itemLoadingSpinner} />
                  ) : canReachGameServer ? (
                    <CheckIcon
                      style={{ color: "#93FF00" }}
                      className={
                        !gameServerLoader && !canReachGameServer
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  ) : (
                    <FailIcon
                      style={{ color: "#FF0092" }}
                      className={
                        !gameServerLoader && !canReachGameServer
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  )}

                  <Typography
                    variant="h3"
                    className={
                      !gameServerLoader && !canReachGameServer
                        ? `${classes.systemsCheckItemHeader} ${classes.systemsCheckItemHeaderError}`
                        : classes.systemsCheckItemHeader
                    }
                  >
                    Showboat application server accessible
                  </Typography>

                  {!gameServerLoader && !canReachGameServer && (
                    <Typography
                      variant="body1"
                      className={classes.itemErrorText}
                    >
                      Unable to reach Showboat application server
                    </Typography>
                  )}
                </div>

                {/************** Showboat AWS Server ****************************/}

                <div className={styles.systemsCheckItem}>
                  {awsLoader ? (
                    <CircularProgress className={classes.itemLoadingSpinner} />
                  ) : canReachAWS ? (
                    <CheckIcon
                      style={{ color: "#93FF00" }}
                      className={
                        !awsLoader && !canReachAWS
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  ) : (
                    <FailIcon
                      style={{ color: "#FF0092" }}
                      className={
                        !awsLoader && !canReachAWS
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  )}

                  <Typography
                    variant="h3"
                    className={
                      !awsLoader && !canReachAWS
                        ? `${classes.systemsCheckItemHeader} ${classes.systemsCheckItemHeaderError}`
                        : classes.systemsCheckItemHeader
                    }
                  >
                    Showboat registration server accessible
                  </Typography>

                  {!awsLoader && !canReachAWS && (
                    <Typography
                      variant="body1"
                      className={classes.itemErrorText}
                    >
                      Unable to connect to the Showboat Registration server.
                    </Typography>
                  )}
                </div>

                {/************** LIVESWITCH ****************************/}

                <div className={styles.systemsCheckItem}>
                  {liveswitchLoader ? (
                    <CircularProgress className={classes.itemLoadingSpinner} />
                  ) : canReachLiveswitch ? (
                    <CheckIcon
                      style={{ color: "#93FF00" }}
                      className={
                        !liveswitchLoader && !canReachLiveswitch
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  ) : (
                    <FailIcon
                      style={{ color: "#FF0092" }}
                      className={
                        !liveswitchLoader && !canReachLiveswitch
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  )}

                  <Typography
                    variant="h3"
                    className={
                      !liveswitchLoader && !canReachLiveswitch
                        ? `${classes.systemsCheckItemHeader} ${classes.systemsCheckItemHeaderError}`
                        : classes.systemsCheckItemHeader
                    }
                  >
                    Showboat video server accessible
                  </Typography>

                  {!liveswitchLoader && !canReachLiveswitch && (
                    <Typography
                      variant="body1"
                      className={classes.itemErrorText}
                    >
                      Unable to access the Showboat video server
                    </Typography>
                  )}
                </div>

                {/************** AUDIO CONTEXT ****************************/}
                <div className={styles.systemsCheckItem}>
                  {audioContextLoader ? (
                    <CircularProgress className={classes.itemLoadingSpinner} />
                  ) : canUseAudioContext ? (
                    <CheckIcon
                      style={{ color: "#93FF00" }}
                      className={
                        !audioContextLoader && !canUseAudioContext
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  ) : (
                    <FailIcon
                      style={{ color: "#FF0092" }}
                      className={
                        !audioContextLoader && !canUseAudioContext
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  )}

                  <Typography
                    variant="h3"
                    className={
                      !audioContextLoader && !canUseAudioContext
                        ? `${classes.systemsCheckItemHeader} ${classes.systemsCheckItemHeaderError}`
                        : classes.systemsCheckItemHeader
                    }
                  >
                    Able to use Audio Context
                  </Typography>

                  {!audioContextLoader && !canUseAudioContext && (
                    <Typography
                      variant="body1"
                      className={classes.itemErrorText}
                    >
                      Unable to utilize Audio Context
                    </Typography>
                  )}
                </div>

                {/************** Mobile Device ****************************/}

                <div className={styles.systemsCheckItem}>
                  {isMobileOnly ? (
                    <FailIcon
                      style={{ color: "#FF0092" }}
                      className={`${classes.itemIcon} ${classes.itemIconError}`}
                    />
                  ) : (
                    <CheckIcon
                      style={{ color: "#93FF00" }}
                      className={classes.itemIcon}
                    />
                  )}

                  <Typography
                    variant="h3"
                    className={
                      isMobileOnly
                        ? `${classes.systemsCheckItemHeader} ${classes.systemsCheckItemHeaderError}`
                        : classes.systemsCheckItemHeader
                    }
                  >
                    Mobile device: {isMobileOnly ? "Yes" : "No"}
                  </Typography>

                  {isMobileOnly && (
                    <Typography
                      variant="body1"
                      className={classes.itemErrorText}
                    >
                      Showboat is currently not supported on mobile devices
                    </Typography>
                  )}
                </div>

                {/************** Tablet Device ****************************/}
                <div className={styles.systemsCheckItem}>
                  {isTablet ? (
                    <FailIcon
                      style={{ color: "#FF0092" }}
                      className={`${classes.itemIcon} ${classes.itemIconError}`}
                    />
                  ) : (
                    <CheckIcon
                      style={{ color: "#93FF00" }}
                      className={classes.itemIcon}
                    />
                  )}

                  <Typography
                    variant="h3"
                    className={
                      isTablet
                        ? `${classes.systemsCheckItemHeader} ${classes.systemsCheckItemHeaderError}`
                        : classes.systemsCheckItemHeader
                    }
                  >
                    Tablet: {isTablet ? "Yes" : "No"}
                  </Typography>

                  {isTablet && (
                    <Typography
                      variant="body1"
                      className={classes.itemErrorText}
                    >
                      Showboat is currently not supported on tablet devices
                    </Typography>
                  )}
                </div>

                {/************** WEBRTC ****************************/}

                <div className={styles.systemsCheckItem}>
                  {genericSystemInfoLoader ? (
                    <CircularProgress className={classes.itemLoadingSpinner} />
                  ) : isWebRTCSupported ? (
                    <CheckIcon
                      style={{ color: "#93FF00" }}
                      className={
                        !genericSystemInfoLoader && !isWebRTCSupported
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  ) : (
                    <InfoIcon
                      style={{ color: "#FF0092" }}
                      className={
                        !genericSystemInfoLoader && !isWebRTCSupported
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  )}

                  <Typography
                    variant="h3"
                    className={
                      !genericSystemInfoLoader && !isWebRTCSupported
                        ? `${classes.systemsCheckItemHeader} ${classes.systemsCheckItemHeaderError}`
                        : classes.systemsCheckItemHeader
                    }
                  >
                    System supports WebRTC
                  </Typography>

                  {!genericSystemInfoLoader && !isWebRTCSupported && (
                    <Typography
                      variant="body1"
                      className={classes.itemErrorText}
                    >
                      System must support WebRTC
                    </Typography>
                  )}
                </div>

                {/************** SCREENSHARE ****************************/}

                <div className={styles.systemsCheckItem}>
                  {genericSystemInfoLoader ? (
                    <CircularProgress className={classes.itemLoadingSpinner} />
                  ) : canScreenshare ? (
                    <CheckIcon
                      style={{ color: "#93FF00" }}
                      className={
                        !genericSystemInfoLoader && !canScreenshare
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  ) : (
                    <InfoIcon
                      style={{ color: "#FF0092" }}
                      className={
                        !genericSystemInfoLoader && !canScreenshare
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                      }
                    />
                  )}

                  <Typography
                    variant="h3"
                    className={
                      !genericSystemInfoLoader && !canScreenshare
                        ? `${classes.systemsCheckItemHeader} ${classes.systemsCheckItemHeaderError}`
                        : classes.systemsCheckItemHeader
                    }
                  >
                    Browser can screen share
                  </Typography>

                  {!genericSystemInfoLoader && !canScreenshare && (
                    <Typography
                      variant="body1"
                      className={classes.itemErrorText}
                    >
                      Browser will not support sharing your screen if you are a
                      presenter
                    </Typography>
                  )}
                </div>

                {/************** CANVAS STREAM ****************************/}

                {/*  <div className={
                 (!genericSystemInfoLoader && !canCanvasStream)
                 ? `${styles.systemsCheckItem} ${styles.systemsCheckItemError}`
                 : styles.systemsCheckItem
              }>

                {genericSystemInfoLoader
                  ? <CircularProgress className={classes.itemLoadingSpinner} />
                  : canCanvasStream
                    ? <CheckIcon 
                        style={{ color: "#93FF00" }}
                        className={(!genericSystemInfoLoader && !canCanvasStream)
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                        } 
                      />
                    : <FailIcon 
                        style={{ color: "#FF0092" }} 
                        className={(!genericSystemInfoLoader && !canCanvasStream)
                          ? `${classes.itemIcon} ${classes.itemIconError}`
                          : classes.itemIcon
                        } 
                      />
                }

                <Typography 
                  variant="h3" 
                  className={
                    (!genericSystemInfoLoader && !canCanvasStream)
                    ? `${classes.systemsCheckItemHeader} ${classes.systemsCheckItemHeaderError}`
                    : classes.systemsCheckItemHeader
                  }
                >
                  Browser can canvas stream
                </Typography>

                {(!genericSystemInfoLoader && !canCanvasStream) &&
                  <Typography variant="body1" className={classes.itemErrorText}>
                    Browser will not support sharing your screen if you are a presenter
                  </Typography>
                }
                
              </div> */}

                {/************** THE END ****************************/}
              </div>
            </div>
          )}
        </Paper>
      </div>
    </Paper>
  );
}
