import React from "react";
import ReactDOM from "react-dom";
import dotenv from "dotenv";
import {
  isMobile,
  isMobileOnly,
  isTablet,
  isSafari,
} from "react-device-detect";

import "./index.css";
import App from "./App";
import { ServerHelper } from "./Utilities/ServerHelper";
import TimeoutErrorPage from "./Intake/Pages/TimeoutErrorPage/TimeoutErrorPage";
import FailedLoginErrorPage from "./Intake/Pages/FailedLoginErrorPage/FailedLoginErrorPage";
import MobileAndTabletErrorPage from "./Intake/Pages/MobileAndTabletErrorPage/MobileAndTabletErrorPage";
import { StringVariableHelper } from "./Utilities/StringVariableHelper";

//Create canvas for Babylon and assign ID
(window as any).babylonCanvas = document.createElement("canvas");
(window as any).babylonCanvas.id = "showboatCanvas";

//Start up dotenv
dotenv.config();

//If we are in dev environment, increase logging level
if (process.env.REACT_APP_API_STAGE === "dev") {
  SHOWBOAT.Logger.ConsoleLogLevel = SHOWBOAT.LogLevel.Debug;
}

//If we are on mobile, set up the viewport hack and add resize listener
if (isMobile) {
  let vh = window.innerHeight * 0.01;

  document.documentElement.style.setProperty("--vh", `${vh}px`);

  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  //Set static property for 3D to read off of
  SHOWBOAT.UIEventManager.isMobile = isMobile;

  //Set RemotePlayersZoneConfig variable for iPads/mobile
  SHOWBOAT.RemotePlayersZoneConfig.Count_Visible = 10;
}

//Check if mobile or on tablet on a browser other than Safari. Render error page if so

/**/

//Check if on mobile or tablet. Render error page if so

/* if (isMobile) { */
if (isMobileOnly || (isTablet && !isSafari)) {
  //Add event listener to prevent overscrolling
  document.body.addEventListener("touchmove", function (e) {
    e.preventDefault();
  });

  ReactDOM.render(
    <MobileAndTabletErrorPage />,
    document.getElementById("root")
  );
}

const checkForBan = (): boolean => {
  let loginCode = ServerHelper.getLoginCode();

  if (
    localStorage.getItem(
      StringVariableHelper.LocalStorageProperties.BannedLoginCodes
    ) === null
  ) {
    return false;
  } else {
    let bannedLoginCodesArray = JSON.parse(
      localStorage.getItem(
        StringVariableHelper.LocalStorageProperties.BannedLoginCodes
      )
    );

    //If this code is on the banned login code list, return true
    if (bannedLoginCodesArray.includes(loginCode)) {
      ReactDOM.render(
        <TimeoutErrorPage failCopy="You have been banned from this event." />,
        document.getElementById("root")
      );

      return true;
    } else {
      return false;
    }
  }
};

const handleConnectionTimeout = () => {
  ServerHelper.OnServerConnectionTimeout.Remove(handleConnectionTimeout);

  ReactDOM.render(
    <TimeoutErrorPage failCopy="" />,
    document.getElementById("root")
  );

  return false;
};

const handleLoginResultSuccess = async (loginResult) => {
  document.title = loginResult.bookingName;

  ServerHelper.OnServerConnectionTimeout.Remove(handleConnectionTimeout);

  ReactDOM.render(
    <App
      primaryThemeColor={SHOWBOAT.ApplicationSkin.primaryThemeColor}
      theme={SHOWBOAT.ApplicationSkin.theme}
      systemCheck={false}
    />,
    document.getElementById("root")
  );
};

//System check login result
const handleSystemCheckLoginResult = () => {
  document.title = "Showboat System Check";

  ServerHelper.OnServerConnectionTimeout.Remove(handleConnectionTimeout);

  ReactDOM.render(
    <App
      primaryThemeColor={SHOWBOAT.ApplicationSkin.primaryThemeColor}
      theme={"dark"}
      systemCheck={true}
    />,
    document.getElementById("root")
  );
};

/* ADD THIS BACK TO ENABLE IPADS */

//Only add event listener if not mobile/tablet via Safari

/**/

//Only add timeout event listener if not mobile/tablet
/* if (!isMobile) { */
if (!(isMobileOnly || (isTablet && !isSafari))) {
  ServerHelper.OnServerConnectionTimeout.Add(handleConnectionTimeout);
}

const handleLoginResultFailure = () => {
  ServerHelper.OnServerConnectionTimeout.Remove(handleConnectionTimeout);
  ReactDOM.render(<FailedLoginErrorPage />, document.getElementById("root"));
};

let doLogin = true;

//Check if we are on a mobile phone or tablet
/* if (isMobile) doLogin = false; */

//Restrict phones from entering the app
if (isMobileOnly) doLogin = false;

//If we are on a tablet, only allow if using safari
if (isTablet) {
  if (!isSafari) {
    doLogin = false;
  }
}

/**/

//Check for ban before executing login logic
let isBanned = checkForBan();

if (isBanned) {
  doLogin = false;
}

if (doLogin) {
  ServerHelper.Login()
    .then((loginResult) => {
      SHOWBOAT.Logger.Log("LOGIN RESULT", loginResult);

      SHOWBOAT.SystemInformation.Init();

      if (loginResult && loginResult.success) {
        //Disable pinch-to-zoom behavior if on mobile/tablet

        if (isMobile) {
          document.addEventListener(
            "touchmove",
            function (e) {
              if (e.touches.length > 1) {
                e.preventDefault();
              }
            },
            { passive: false }
          );

          document.addEventListener(
            "touchstart",
            function (e) {
              if (e.touches.length > 1) {
                e.preventDefault();
              }
            },
            { passive: false }
          );

          document.addEventListener("gesturestart", function (e) {
            e.preventDefault();
          });

          document.addEventListener("gesturechange", function (e) {
            e.preventDefault();
          });

          document.addEventListener("gestureend", function (e) {
            e.preventDefault();
          });
        }

        handleLoginResultSuccess(loginResult);
      } else if (
        !loginResult.success &&
        (loginResult.failReason ===
          StringVariableHelper.LoginFailReasons.NotStarted ||
          loginResult.failReason ===
            StringVariableHelper.LoginFailReasons.Ended ||
          loginResult.failReason ===
            StringVariableHelper.LoginFailReasons.DeletedLoginCode ||
          loginResult.failReason ===
            StringVariableHelper.LoginFailReasons.CapacityFull)
      ) {
        handleLoginResultSuccess(loginResult);
      }
      //System check
      else if (
        !loginResult.success &&
        loginResult.failReason ===
          StringVariableHelper.LoginFailReasons.SystemCheck
      ) {
        handleSystemCheckLoginResult();
      } else {
        handleLoginResultFailure();
      }
    })
    .catch((err) => {
      SHOWBOAT.Logger.Error("Error logging in user", err);
    });
}
