import { Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';

import CloseIconButton from '../CloseIconButton/CloseIconButton';

import styles from './FindPeople.module.css';
import { FindPeopleStyles } from './FindPeopleStyles';

interface Props {
  handleSectionClose: () => void,
  currentSection: number
}

export default function FindPeople(props: Props): ReactElement {

  const handleCloseButtonClick = (e?) => {
    props.handleSectionClose();

    if (e) e.stopPropagation();
  }
  
  const classes = FindPeopleStyles();
  
  return (
    <React.Fragment>

      {props.currentSection === 1 &&
        <React.Fragment>
          <div
            className={styles.closeDiv}
            onClick={handleCloseButtonClick}
            role="button"
          ></div>
          <CloseIconButton
            handleCloseButtonClick={handleCloseButtonClick}
          />
        </React.Fragment>
      }
      

      <img 
        alt="find people button" 
        className={styles.findPeopleImage} 
        src={`assets/images/FindPeople${SHOWBOAT.ApplicationSkin.theme === "light" ? "Light" : "Dark"}.svg`}
      />

      <Typography 
        variant="body1" 
        className={classes.findPeopleText}
        style={{
          width: "250px"
        }}
      >
        Press the directory button in the main menu and type the name of the person
        you are trying to locate into the search bar.
      </Typography>
    </React.Fragment>
  )
}
