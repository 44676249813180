import { makeStyles, createStyles } from '@material-ui/core/styles';

const AvatarLoadingScreenStyles = makeStyles((theme: any) => createStyles({
  loadingHolder: {
    backgroundColor: theme.palette.intakePaper.main,
    width: "100%",
    height: "100%",
    top: 0,
    borderRadius: 10,
    display: "inline-block",
    position: "absolute",
    bottom: 50,
    left: 0,
    zIndex: 9999,
  },
  loadingProgress: {
    position: "relative",
    fontSize: 18,
    display: "inline-block",
    fontWeight: 500,
    top: 220,
    color: theme.palette.text.main,
  }
})
)

export { AvatarLoadingScreenStyles };