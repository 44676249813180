import React, { ReactElement, useEffect, useState } from "react";

import UnmuteIcon from "@material-ui/icons/VolumeUp";
import MuteIcon from "@material-ui/icons/VolumeOff";

import { AttendeeMuteIconStyles } from "./AttendeeMuteIconStyles";
import { useCallback } from "react";

interface Props {
  teleportSent?: boolean;
  userID: string;
  showVoiceActivity: boolean;
}

export default function AttendeeMuteIcon(props: Props): ReactElement {
  const [isAttendeeMuted, toggleIsAttendeeMuted] = useState(
    SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID) !== undefined
      ? SHOWBOAT.RemoteAvatarDataManager.getAvatarData(props.userID)
          .isForceMuted
      : false
  );

  const handleAttendeeMicMuteAndUnmute = useCallback(
    (avatarData: SHOWBOAT.AvatarData) => {
      if (avatarData.userID !== props.userID) return;

      toggleIsAttendeeMuted(avatarData.isForceMuted);
    },
    [props.userID]
  );

  useEffect(() => {
    //Listen in for mic mutes/unmutes for remote players
    SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Add(
      props.userID,
      SHOWBOAT.ChangeReason.ForceMute,
      handleAttendeeMicMuteAndUnmute
    );

    return function cleanup() {
      SHOWBOAT.RemoteAvatarDataManager.OnSpecificUserPlayerDataUpdate.Remove(
        props.userID,
        SHOWBOAT.ChangeReason.ForceMute,
        handleAttendeeMicMuteAndUnmute
      );
    };
  }, [handleAttendeeMicMuteAndUnmute, props.userID]);

  const handleAttendeeMuteToggle = (e: any) => {
    let toggleState: boolean = !isAttendeeMuted;

    //If we are muting, send a true and show muted icon
    if (toggleState) {
      SHOWBOAT.SocketIOController.SendOne("ForceAudioMute", props.userID, {
        forceAudioMute: true,
      });
      toggleIsAttendeeMuted(true);
    } else {
      SHOWBOAT.SocketIOController.SendOne("UnforceAudioMute", props.userID, {
        forceAudioMute: false,
      });
      toggleIsAttendeeMuted(false);
    }

    if (!e) {
      var eParam: any = window.event;
      eParam.cancelBubble = true;
      if (eParam.stopPropagation) {
        eParam.stopPropagation();
      }
    }
    e.cancelBubble = true;
    if (e.stopPropagation) {
      e.stopPropagation();
    }
  };

  const classes = AttendeeMuteIconStyles();

  if (isAttendeeMuted) {
    return (
      <MuteIcon
        className={classes.muteIcon}
        onClick={(e) => handleAttendeeMuteToggle(e)}
      />
    );
  } else {
    return (
      <UnmuteIcon
        className={
          props.showVoiceActivity
            ? `${classes.muteIcon} ${classes.muteIconVoiceActivity}`
            : classes.muteIcon
        }
        onClick={(e) => handleAttendeeMuteToggle(e)}
      />
    );
  }
}
