import { makeStyles, createStyles } from '@material-ui/core/styles';

const SpeakerSettingsStyles = makeStyles((theme: any) => createStyles({
  avHeader: {
    fontSize: 24,
    position: "relative",
    top: 34,
    left: 50,
    fontWeight: 400,
    fontFamily: "Open Sans, sans-serif",
    [theme.breakpoints.down('sm')]: {
      left: 30,
    }
  },
  speakerSelectHeader: {
    position: "relative",
    left: 52,
    top: 56,
    fontSize: 24,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      left: 30,
    }
  },
  speakerSelectHolder: {
    position: "relative",
    textAlign: "left",
    top: 75,
    left: 52,
    minWidth: "82%",
    maxWidth: "82%",
    [theme.breakpoints.down('sm')]: {
      left: 30,
      minWidth: "87%",
      maxWidth: "87%",
    }
  },
  backButton: {
    height: 46,
    width: 115,
  },
  nextButton: {
    backgroundColor: theme.palette.intakeBlue.main,
    height: 46,
    width: 115,
    color: "white",
  },
  speakerTestButton: {
    padding: "10px 20px",
    display: "inline-block",
    margin: "4px 2px",
    borderRadius: 30,
    position: "relative",
    top: 110,
    left: 50,
    [theme.breakpoints.down('sm')]: {
      left: 30,
    }
  },
  speakerTestButtonNonChrome: {
    top: 80,
  },
  speakerTestButtonLabel: {
    position: "relative",
    bottom: 3,
    fontSize: 14,

  },
  volumeIcon: {
    position: "relative",
    top: 6,
    right: 3,
  }
})
)

export { SpeakerSettingsStyles };

