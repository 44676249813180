import React, { useContext, useEffect, useState } from "react";

import {
  DeviceContext,
  DeviceContextType,
} from "../../../../context/DeviceContext";

import styles from "./AvatarPreview.module.css";

export default function AvatarPreview() {
  //Context
  const { isCameraEnabled }: DeviceContextType = useContext(DeviceContext);

  const [avatarFace, setAvatarFace] = useState(
    SHOWBOAT.LocalAvatarDataManager.avatarData.face
  );
  const [avatarColor, setAvatarColor] = useState(
    SHOWBOAT.LocalAvatarDataManager.avatarData.color
  );

  useEffect(() => {
    //Listen for face and color changes
    SHOWBOAT.UIEventManager.OnAvatarColorChanged.Add(handleAvatarColorChange);
    SHOWBOAT.UIEventManager.OnAvatarFaceChanged.Add(handleAvatarFaceChange);

    return function cleanup() {
      SHOWBOAT.UIEventManager.OnAvatarColorChanged.Remove(
        handleAvatarColorChange
      );
      SHOWBOAT.UIEventManager.OnAvatarFaceChanged.Remove(
        handleAvatarFaceChange
      );
    };
  }, []);

  useEffect(() => {
    let videoHolder = document.getElementById("videoHolder");

    //Make sure video holder is mounted
    if (videoHolder !== null) {
      if (isCameraEnabled) {
        let avatarPreviewCanvas =
          SHOWBOAT.StreamingUserMedia.getAvatarCanvas() as HTMLElement;
        avatarPreviewCanvas.id = "avatarPreviewCanvas";
        videoHolder.appendChild(avatarPreviewCanvas);
      }
    }
  }, [isCameraEnabled]);

  const handleAvatarColorChange = (color) => {
    setAvatarColor(color);
  };

  const handleAvatarFaceChange = (face) => {
    setAvatarFace(face);
  };

  //material UI classes

  return (
    <React.Fragment>
      <div className={styles.cameraPreviewHolder}>
        <>
          <div id="videoHolder" className={styles.videoWrapper}></div>

          {/* Cover video holder with avatar face if camera is disabled/muted */}
          {!isCameraEnabled && (
            <img
              alt="avatar face"
              src={`assets/images/faces/${SHOWBOAT.ApplicationSkin.faceThumbnailURLs[avatarFace]}`}
              style={{
                backgroundColor:
                  SHOWBOAT.ApplicationSkin.secondaryAvatarColors[avatarColor],
              }}
              className={styles.cameraFace}
            />
          )}
        </>
      </div>
    </React.Fragment>
  );
}
