import { Button, Typography } from "@material-ui/core";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import FullIcon from "@material-ui/icons/HighlightOff";

import EnterIcon from "./EnterIcon/EnterIcon";
import ExitIcon from "./ExitIcon/ExitIcon";

import { TableButtonStyles } from "./TableButtonStyles";

interface Props {}

export default function TableButton({}: Props): ReactElement {
  const [showTableButton, setShowTableButton] = useState(false);
  const [disableTableButton, setDisableTableButton] = useState(false);

  const [disableButtonCooldown, setDisableButtonCooldown] = useState(false);

  const [tableState, setTableState] = useState(SHOWBOAT.TableControlType.Join);

  const [twoDModeActive, setTwoDModeActive] = useState(false);

  const disableButtonCooldownRef = useRef(null);

  useEffect(() => {
    //Add listeners for show/hide events
    SHOWBOAT.UIEventManager.SetTableButtonState.Add(
      handleTableButtonStateChange
    );

    SHOWBOAT.UIEventManager.OnFullscreenPresentationToggle.Add(handle2DModeToggle);

    return function cleanup() {
      SHOWBOAT.UIEventManager.OnFullscreenPresentationToggle.Add(
        handle2DModeToggle
      );
      
      SHOWBOAT.UIEventManager.SetTableButtonState.Remove(
        handleTableButtonStateChange
      );

      if (disableButtonCooldownRef.current) {
        clearTimeout(disableButtonCooldownRef.current);
      }
    };
  }, []);

  const handleTableButtonStateChange = (
    tableControlType: SHOWBOAT.TableControlType,
    isVisible: boolean,
    isEnabled: boolean
  ) => {
    //Change visible state
    setShowTableButton(isVisible);

    //Change control type state
    setTableState(tableControlType);

    //Change disabled state
    setDisableTableButton(!isEnabled);
  };

  const handleTableButtonClick = () => {
    if (disableButtonCooldown) return;

    //Disable button for 1 sec after clicking
    setDisableButtonCooldown(true);
    disableButtonCooldownRef.current = setTimeout(function () {
      setDisableButtonCooldown(false);
    }, 1000);

    if (tableState === SHOWBOAT.TableControlType.Join) {
      SHOWBOAT.UIEventManager.OnTableJoinButton.Raise();
    } else if (tableState === SHOWBOAT.TableControlType.Leave) {
      SHOWBOAT.UIEventManager.OnTableLeaveButton.Raise();
    } else if (tableState === SHOWBOAT.TableControlType.Full) {
      //Do nothing. Table is full
    }
  };

  const handle2DModeToggle = (isActive: boolean) => {
    setTwoDModeActive(isActive)
  }

  const classes = TableButtonStyles();

  const renderIcon = (tableStateParam) => {
    switch (tableStateParam) {
      case SHOWBOAT.TableControlType.Join:
        return <EnterIcon disabled={disableTableButton} />;
      case SHOWBOAT.TableControlType.Leave:
        return <ExitIcon disabled={disableTableButton} />;
      case SHOWBOAT.TableControlType.Full:
        return <FullIcon />;
    }
  };

  const renderButtonText = (tableStateParam) => {
    switch (tableStateParam) {
      case SHOWBOAT.TableControlType.Join:
        return "JOIN TABLE";
      case SHOWBOAT.TableControlType.Leave:
        return "LEAVE TABLE";
      case SHOWBOAT.TableControlType.Full:
        return "TABLE FULL";
    }
  };

  const renderButtonClass = (tableStateParam) => {
    switch (tableStateParam) {
      case SHOWBOAT.TableControlType.Join:
        return classes.tableButton;
      case SHOWBOAT.TableControlType.Leave:
        return `${classes.tableButton} ${classes.tableButtonExit}`;
      case SHOWBOAT.TableControlType.Full:
        return `${classes.tableButton} ${classes.tableButtonFull}`;
    }
  };

  if (showTableButton) {
    return (
      <Button
        variant="contained"
        id="tableButton"
        onClick={handleTableButtonClick}
        classes={{ root: renderButtonClass(tableState), disabled: classes.tableButtonDisabled }}
        disabled={disableTableButton}
        style={{
          display: twoDModeActive ? "none" : "inline-flex"
        }}
      >
        {renderIcon(tableState)}
        <Typography
          className={
            tableState === SHOWBOAT.TableControlType.Leave
              ? `${classes.label} ${classes.labelExit}`
              : classes.label
          }
        >
          {renderButtonText(tableState)}
        </Typography>
      </Button>
    );
  } else {
    return null;
  }
}
