import React, { ReactElement } from "react";
import IntakeContentHolder from "../../components/IntakeContentHolder/IntakeContentHolder";

import SpeakerSettings from "../../SpeakerSettings/SpeakerSettings";

interface Props {
  onSpeakersPageComplete: () => void;
  onSpeakersPageBack: () => void;
}

export default function SpeakersPage(props: Props): ReactElement {
  //next button click
  const handleSpeakerSettingsComplete = () => {
    props.onSpeakersPageComplete();
  };

  //back button click
  const handleSpeakerSettingsBack = () => {
    props.onSpeakersPageBack();
  };

  return (
    <IntakeContentHolder marginBottom={"0px"} autoHeight={true}>
      <SpeakerSettings
        intake={true}
        onSpeakerSettingsComplete={handleSpeakerSettingsComplete}
        onSpeakerSettingsBack={handleSpeakerSettingsBack}
      />
    </IntakeContentHolder>
  );
}
