import React, {
  ReactElement,
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import $ from "jquery";

import { AppContext, AppContextType } from "../../../../context/AppContext";
import PrivateMessage from "./PrivateMessage/PrivateMessage";
import OneOnOneChatBar from "../OneOnOneChatBar/OneOnOneChatBar";

import styles from "./PrivateMessagesWrapper.module.css";
import {
  OneOnOneChatContext,
  OneOnOneChatContextType,
} from "../../../../context/OneOnOneChatContext";

interface Props {}

export default function PrivateMessagesWrapper(props: Props): ReactElement {
  const [privateMessages, setPrivateMessages] = useState([]);

  const [rightOffset, setRightOffset] = useState(10);

  const { isChatOff, isOneOnOneChatOpen }: OneOnOneChatContextType =
    useContext(OneOnOneChatContext);

  const { currentSidebarMode }: AppContextType = useContext(AppContext);

  const chatSettingRef = useRef();
  const msgID = useRef(0);

  $(document).ready(function () {
    $(".messageCloseButton").on("click", function (e) {
      var $thisParent = $(this).closest("div");
      $thisParent.prevAll().each(function (index) {
        $(this)
          .stop()
          .animate(
            {
              bottom: `-=${$thisParent.outerHeight(true) + 10}`,
            },
            300
          );
      });
    });
  });

  //RECEIVE PRIVATE MESSAGE
  const handleReceivePrivateMessage = useCallback(
    (data: SHOWBOAT.SocketMessage) => {
      if (chatSettingRef.current) {
        SHOWBOAT.SocketIOController.SendOne(
          "chat",
          data.userID,
          "Sorry, chat is disabled for this attendee and your message was not seen."
        );

        return;
      } else {
        let newMsg = {
          userID: data.userID,
          message: data.message,
          id: msgID.current,
        };

        setPrivateMessages((privateMessages) => [...privateMessages, newMsg]);

        msgID.current = msgID.current + 1;
      }
    },
    []
  );

  useEffect(() => {
    SHOWBOAT.SocketIOController.OnPrivateMessage.Add(
      "chat",
      handleReceivePrivateMessage
    );

    return function cleanup() {
      SHOWBOAT.SocketIOController.OnPrivateMessage.Remove(
        "chat",
        handleReceivePrivateMessage
      );

      $(".messageCloseButton").off("click");
    };
  }, [handleReceivePrivateMessage]);

  useEffect(() => {
    //Change right offset of messages depending on what is selected for the sidebar
    let rightOffset;

    switch (currentSidebarMode) {
      case "":
        rightOffset = 10;
        break;
      case "settings":
        rightOffset = 435;
        break;
      case "chat":
      case "room":
      case "support":
      case "attendees":
      case "calendar":
        rightOffset = 305;
        break;
      case "exit":
        rightOffset = 235;
        break;
    }

    setRightOffset(rightOffset);
  }, [currentSidebarMode]);

  useEffect(() => {
    (chatSettingRef.current as any) = isChatOff;
  }, [isChatOff]);

  //DISMISS PRIVATE MESSAGE
  const handleMessageDismiss = (msgID: number) => {
    setPrivateMessages(privateMessages.filter((msg) => msg.id !== msgID));
  };

  return (
    <div
      className={styles.chatMessagesHolder}
      style={{
        right: rightOffset,
      }}
    >
      <div id="privateChatMessageHolder" className={styles.chatMessageWrapper}>
        {privateMessages.map((msg, i) => {
          return (
            <PrivateMessage
              key={msg.id}
              message={msg.message}
              userID={msg.userID}
              id={msg.id}
              handleMessageDismiss={handleMessageDismiss}
            />
          );
        })}
      </div>

      {isOneOnOneChatOpen === true ? <OneOnOneChatBar /> : null}
    </div>
  );
}
