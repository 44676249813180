import { IconButton, Paper, Typography } from "@material-ui/core";
import React, { ReactElement, useContext } from "react";
import CalendarIcon from "@material-ui/icons/Today";
import RightArrowIcon from "@material-ui/icons/ArrowForward";
import styles from "./CalendarSidebar.module.css";
import { CalendarSidebarStyles } from "./CalendarSidebarStyles";
import { AppContext, AppContextType } from "../../../../context/AppContext";

export default function CalendarSidebar(): ReactElement {
  const { setCurrentSidebarMode, isPresenter }: AppContextType =
    useContext(AppContext);

  const classes = CalendarSidebarStyles();

  return (
    <div
      className={
        isPresenter
          ? styles.calendarContentHolder
          : `${styles.calendarContentHolder} ${styles.calendarContentHolderNonPresenter}`
      }
    >
      <Paper className={classes.calendarHeaderHolder}>
        <CalendarIcon className={classes.calendarIcon}></CalendarIcon>

        <Typography variant="h1" className={classes.calendarHeader}>
          Schedule
        </Typography>

        <IconButton
          className={classes.closeIconButton}
          onClick={() => setCurrentSidebarMode("")}
        >
          <RightArrowIcon />
        </IconButton>
      </Paper>
    </div>
  );
}
