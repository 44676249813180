import { makeStyles, createStyles } from '@material-ui/core/styles';

const FindPeopleStyles = makeStyles((theme: any) => createStyles({
  findPeopleText: {
    fontSize: 14,
    position: "relative",
    textAlign: "left",
    left: 11,
    marginBottom: 10,
  },
  closeIconButton: {
    position: "absolute",
    padding: 3,
    right: 5,
    top: 5,
  },
  arrowUpIcon: {
    fontSize: 30,
  }
})
)

export { FindPeopleStyles };