import React, { useState, useEffect } from 'react';

export interface RoomAvailabilityContextType {
  lessCrowdedAvailable : boolean,
  toggleLessCrowdedAvailable : (lessCrowdedAvailable : boolean) => void,
  moreCrowdedAvailable : boolean,
  toggleMoreCrowdedAvailable : (moreCrowdedAvailable : boolean) => void,
  moreThanOneRoom : boolean,
  toggleMoreThanOneRoom : (moreThanOneRoom : boolean) => void,
  hasBeenShownNewRoomPopup : boolean,
  toggleHasBeenShownNewRoomPopup : (hasBeenShown : boolean) => void,
}

export const RoomAvailabilityContext = React.createContext(null);

export const RoomAvailabilityContextProvider = (props) => {
  const [lessCrowdedAvailable, toggleLessCrowdedAvailable] = useState(false);
  const [moreCrowdedAvailable, toggleMoreCrowdedAvailable] = useState(false);
  const [moreThanOneRoom, toggleMoreThanOneRoom] = useState(false);
  const [hasBeenShownNewRoomPopup, toggleHasBeenShownNewRoomPopup] = useState(false);

  useEffect(() => {

    SHOWBOAT.SocketIOController.GetRoomAvailability()
      .then(response => {

        //set if more than one room to show change room button/menu
        if (response.RoomCount > 1) {
          toggleMoreThanOneRoom(true);
        } else {
          toggleMoreThanOneRoom(false);
        }

        //set more/less crowded availability
        toggleLessCrowdedAvailable(response.LessCrowdedAvailable);
        toggleMoreCrowdedAvailable(response.MoreCrowdedAvailable);

      })
      .catch(err => {
        SHOWBOAT.Logger.Error("Error retrieving room availability", err);
      })

    SHOWBOAT.SocketIOController.OnRoomOpened.Add(handleNewRoomOpen);


    return function cleanup() {
      /* SHOWBOAT.SocketIOController.OnNotification.Remove("RoomOpen", handleNewRoomOpen); */

      SHOWBOAT.SocketIOController.OnRoomOpened.Remove(handleNewRoomOpen);
    }
  }, [])

  const handleNewRoomOpen = () => {
    toggleMoreThanOneRoom(true);
  }

  /**/

  const roomAvailabilityProviderValue : RoomAvailabilityContextType = {
    lessCrowdedAvailable,
    toggleLessCrowdedAvailable,
    moreCrowdedAvailable,
    toggleMoreCrowdedAvailable,
    moreThanOneRoom,
    toggleMoreThanOneRoom,
    hasBeenShownNewRoomPopup,
    toggleHasBeenShownNewRoomPopup
  }

  return (
    <RoomAvailabilityContext.Provider
      value={roomAvailabilityProviderValue}
    >
      {props.children}
    </RoomAvailabilityContext.Provider>
  )
}
