import { Card, IconButton, Typography } from "@material-ui/core";
import React, { ReactElement, useEffect, useState } from "react";
import CheckIcon from "@material-ui/icons/Check";
import Linkify from "react-linkify";

import { ChatSidebarMessageStyles } from "./ChatSidebarMessageStyles";
import { UIHelper } from "../../../../../Utilities/UIHelper";

interface Props {
  msg: string;
  color: number;
  face: number;
  date: string;
  name: string;
  id: number;
}

export default function ChatSidebarMessage(props: Props): ReactElement {
  const [showCopyButton, setShowCopyButton] = useState(false);
  const [copied, setCopied] = useState(false);

  const [containerHeight, setContainerHeight] = useState(0);

  const [buttonTop, setButtonTop] = useState(0);
  const [buttonRight, setButtonRight] = useState(0);

  const handleMouseEnter = (e) => {
    //Refresh position information
    setPositionInformation();

    //Show custom right click menu

    setShowCopyButton(true);
  };

  const handleMouseLeave = (e) => {
    setCopied(false);
    setShowCopyButton(false);
  };

  const handleCopy = () => {
    //Safety check
    if (!showCopyButton) return;

    try {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(props.msg);
      } else {
        throw new Error("");
      }
    } catch {
      //Attempt old variant of copy to clipboard

      let dummy = document.createElement("textarea");

      dummy.style.right = "9999px";

      document.getElementById("chatContentHolder").appendChild(dummy);

      dummy.value = props.msg;

      dummy.select();
      dummy.setSelectionRange(0, 99999);

      document.execCommand("copy");

      document.getElementById("chatContentHolder").removeChild(dummy);
    }

    setCopied(true);
  };

  useEffect(() => {
    setPositionInformation();
  }, []);

  useEffect(() => {
    //Add event listener for scrolling
    if (showCopyButton) {
      document.addEventListener("scroll", setPositionInformation);
      document.addEventListener("wheel", setPositionInformation);
    } else {
      document.removeEventListener("wheel", setPositionInformation);
      document.removeEventListener("scroll", setPositionInformation);
    }

    return function cleanup() {
      document.removeEventListener("wheel", setPositionInformation);
      document.removeEventListener("scroll", setPositionInformation);
    };
  }, [showCopyButton]);

  const setPositionInformation = () => {
    setShowCopyButton(false);

    let messageHolder = document.getElementById(
      `${props.id.toString()}-eventChat`
    );

    if (messageHolder) {
      setButtonTop(messageHolder.getBoundingClientRect().top);
      setButtonRight(messageHolder.getBoundingClientRect().right);

      setContainerHeight(messageHolder.offsetHeight);
    }
  };

  const classes = ChatSidebarMessageStyles();

  let messageDate = new Date(props.date).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  let messageDateFormatted = messageDate.replace(/^0(?:0:0?)?/, "");

  let textColor: string;

  if (SHOWBOAT.ApplicationSkin.theme === "light") {
    textColor = SHOWBOAT.ApplicationSkin.primaryAvatarColors[props.color];
  } else {
    textColor = SHOWBOAT.ApplicationSkin.secondaryAvatarColors[props.color];
  }

  let linkColor;

  if (SHOWBOAT.ApplicationSkin.theme === "light") {
    linkColor = "black";
  } else {
    linkColor = "white";
  }
  return (
    <Card
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      elevation={4}
      classes={{ root: classes.chatMessageHolder }}
      id={`${props.id.toString()}-eventChat`}
    >
      <Card elevation={0} classes={{ root: classes.chatMessageHeader }}>
        <Typography
          classes={{ root: classes.chatName }}
          style={{
            color: textColor,
          }}
          variant="body1"
        >
          {props.name}
        </Typography>

        <Typography
          classes={{ root: classes.chatTime }}
          style={{ color: textColor }}
          variant="body1"
        >
          {messageDateFormatted}
        </Typography>
      </Card>

      <Typography
        variant="body1"
        classes={{ root: `${classes.chatBody} selectableText` }}
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              target={
                decoratedHref.includes("go.showboat.live") ? "_self" : "_blank"
              }
              href={UIHelper.checkAddBypass(decoratedHref)}
              key={key}
              style={{ color: linkColor, wordWrap: "break-word" }}
            >
              {decoratedText}
            </a>
          )}
        >
          {props.msg}
        </Linkify>
      </Typography>

      {showCopyButton && (
        <IconButton
          onClick={handleCopy}
          className={classes.copyButton}
          style={{
            visibility: showCopyButton ? "visible" : "hidden",
            top: buttonTop + containerHeight / 2 - 16,
            left: window.innerWidth - 40,
          }}
        >
          {copied ? (
            <CheckIcon className={classes.buttonIcon}></CheckIcon>
          ) : (
            <span
              className="material-icons"
              style={{ color: "black", fontSize: "20px" }}
            >
              content_copy
            </span>
          )}
        </IconButton>
      )}
    </Card>
  );
}
