import { makeStyles, createStyles } from '@material-ui/core/styles';

const EventCountdownStyles = makeStyles((theme: any) => createStyles({
  eventCountdownHolder: { 
    display: "inline-block",
    position: "absolute",
    backgroundColor: theme.palette.intakePaper.main,
    width: 370,
    height: 330,
    top: 260,
    left: 0,
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      width: 225,
      height: 270
    }
  },
  numberHolder: {
    width: 133,
    height: 57,
    position: "absolute",
    left: 0,
    textAlign: "center",
    borderRadius: 8,
    backgroundColor: theme.palette.intakeBackground.main,
    [theme.breakpoints.down('sm')]: {
      width: 100,
      height: 42,
      top: 7,
      left: 12
    }
  },
  timeLabel: {
    position: "absolute",
    fontSize: 30,
    top: 5,
    left: 155,
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
      left: 125,
      top: 11

    }
  },
  number: {
    fontSize: 30,
    position: "relative",
    display: "inline-block",
    top: 6,
    [theme.breakpoints.down('sm')]: {
      top: -1
    }
  }
})
)

export { EventCountdownStyles };