import {
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Switch,
  Typography,
} from "@material-ui/core";
import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Rnd } from "react-rnd";
import axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FolderIcon from "@material-ui/icons/FolderOpen";
import CloseIcon from "@material-ui/icons/Close";
import ArrowUp from "@material-ui/icons/ArrowDropUp";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import RightIcon from "@material-ui/icons/ArrowForwardIos";
import LeftIcon from "@material-ui/icons/ArrowBackIos";
import ActiveIcon from "@material-ui/icons/FiberManualRecord";
import scrollIntoView from "scroll-into-view-if-needed";

import {
  PresenterContext,
  PresenterContextType,
} from "../../../context/PresenterContext";
import { StringVariableHelper } from "../../../Utilities/StringVariableHelper";
import { PresenterHelper } from "../../../Utilities/PresenterHelper";
import { ServerHelper } from "../../../Utilities/ServerHelper";

import styles from "./SlidePresenterTool.module.css";
import { SlidePresenterToolStyles } from "./SlidePresenterToolStyles";
import { PopupContext, PopupContextType } from "../../../context/PopupContext";

export default function SlidePresenterTool(): ReactElement {
  const {
    viewAllSlides,
    currentPresenterTool,
    currentSlide,
    toggleViewAllSlides,
    setCurrentPresenterTool,
    isPresentingSlides,
    toggleIsPresentingSlides,
    setCurrentSlide,
  }: PresenterContextType = useContext(PresenterContext);

  const {
    toolXPosition,
    setToolXPosition,
    toolYPosition,
    setToolYPosition,
  }: PopupContextType = useContext(PopupContext);

  const [transparent, toggleTransparent] = useState(false);
  const [selectPresentations, toggleSelectPresentations] = useState(false);
  const [spacePresentations, setSpacePresentations] = useState([]);
  const [buttonDisable, toggleButtonDisable] = useState(false);
  const [disableFolderIcon, toggleDisableFolderIcon] = useState(true);
  const [anchorElement, setAnchorElement] = useState(null);
  const [selectedPresentation, setSelectedPresentation] = useState(null);
  const [offsetTop, setOffsetTop] = useState("");
  const [loadingSpinner, toggleLoadingSpinner] = useState(true);
  const [disableShareToggle, toggleDisableShareToggle] = useState(false);

  const currentSlideRef = useRef(0);
  const handlesArray = useRef(null);
  const firstRender = useRef(true);

  const disableShareToggleRef = useRef(null);

  const disableChangeSlidesTimeout = useRef(null);

  const setPopupPositionInContext = () => {
    //Ensure bounds are correct
    handleCalculateSlideMovementBounds();

    //Capture X/Y/Width/Height and store in context
    let popup = document.getElementById("slideToolHolder") as HTMLDivElement;
    if (popup) {
      let viewportOffset = popup.getBoundingClientRect();
      setToolXPosition(viewportOffset.left);
      setToolYPosition(viewportOffset.top + (viewAllSlides ? 300 : 0));
    }
  };

  const checkIfElementIsBelowViewport = () => {
    //Check if space between popup and bottom of viewport is negative
    //If so, move up by that much
    let popup = document.getElementById("slideToolHolder");
    let header = document.getElementById("slideToolHeaderHolder");
    let content = document.getElementById("slideContentHolder");
    let footer = document.getElementById("slidesFooter");

    if (popup && header && content && footer) {
      let popupHeight =
        header.clientHeight + content.clientHeight + footer.clientHeight;

      let viewportOffset = popup.getBoundingClientRect();
      let space = window.innerHeight - viewportOffset.top - popupHeight;

      if (space < 0) popup.style.marginTop = `${space}px`;
    }
  };

  useEffect(() => {
    firstRender.current = false;

    if (
      currentPresenterTool === StringVariableHelper.PresenterToolNames.slides
    ) {
      checkIfElementIsBelowViewport();

      //Ensure buttons are not disabled
      toggleButtonDisable(false);

      //When opening this component, get presentations for the space
      const getSpacePresentations = async () => {
        toggleLoadingSpinner(true);

        try {
          let response = await axios.get("/presentations/all", {
            params: {
              bookingID: ServerHelper.loginResult.eventID,
              loginCode: ServerHelper.loginCode,
              devCode: process.env.REACT_APP_API_STAGE === "dev" ? true : false,
            },
          });

          toggleDisableFolderIcon(false);

          setSpacePresentations(response.data);

          toggleLoadingSpinner(false);
        } catch (error) {
          toggleLoadingSpinner(false);

          if (error.response.status === 404) {
            SHOWBOAT.UIEventManager.OnUIError.Raise("No presentations found");
          } else {
            SHOWBOAT.UIEventManager.OnUIError.Raise(
              "Error getting presentations"
            );
          }
        }
      };
      getSpacePresentations();
    }

    return function cleanup() {
      if (disableChangeSlidesTimeout.current)
        clearTimeout(disableChangeSlidesTimeout.current);

      if (disableShareToggleRef.current) {
        clearTimeout(disableShareToggleRef.current);
      }
    };
  }, [currentPresenterTool]);

  useEffect(() => {
    if (selectedPresentation === null) return;

    //Ensure we update local state when selectedPresentation in context changes
    setSelectedPresentation(selectedPresentation);

    //Set presentation in PresenterHelper
    PresenterHelper.selectedPresentation = selectedPresentation;
  }, [selectedPresentation]);

  useEffect(() => {
    //If selected slide is below view, scroll it into view
    let newSlideDiv = document.getElementById(`SLIDE${currentSlide}`);

    if (newSlideDiv) scrollIntoView(newSlideDiv, { scrollMode: "if-needed" });
  }, [currentSlide]);

  useEffect(() => {
    //Call resize handler, since popup is resized in both these effects
    handleCalculateSlideMovementBounds();
  }, [selectedPresentation, viewAllSlides]);

  const handleCloseSlideTool = () => {
    toggleViewAllSlides(false);

    setPopupPositionInContext();
    setCurrentPresenterTool("");
  };

  const handleSelectPresentationClick = (e) => {
    //Set anchor element for the menu
    setAnchorElement(e.currentTarget);

    //Show presentation select menu
    toggleSelectPresentations(!selectPresentations);
  };

  const handleShowSlidesButton = () => {
    toggleViewAllSlides(!viewAllSlides);
  };

  const handleToggleShareSlides = () => {
    PresenterHelper.OnSlideToggle(!isPresentingSlides, currentSlide);

    //Disable toggle for 1 second
    toggleDisableShareToggle(true);
    disableShareToggleRef.current = setTimeout(function () {
      toggleDisableShareToggle(false);
    }, 1000);

    toggleIsPresentingSlides(!isPresentingSlides);
  };

  const handleMenuClose = () => {
    setAnchorElement(null);

    toggleSelectPresentations(false);
  };

  const handleNextSlideClick = () => {
    //Return if at last slide
    if (currentSlide + 1 >= selectedPresentation.slides.length) return;

    if (buttonDisable) return;

    handleSlideDisable();

    let nextSlide = currentSlide + 1;

    //If we are sharing slides, raise server-side event
    if (isPresentingSlides) {
      PresenterHelper.OnSlideChange(nextSlide);
    }

    setCurrentSlide(nextSlide);
  };

  const handlePreviousSlideClick = () => {
    //Don't allow previous click if at first slide
    if (currentSlide - 1 < 0) {
      return;
    }

    if (buttonDisable) return;

    handleSlideDisable();

    let previousSlide = currentSlide - 1;

    //If we are sharing slides, raise server event
    if (isPresentingSlides) {
      //Advance slide in context and server-side
      PresenterHelper.OnSlideChange(previousSlide);
    }

    setCurrentSlide(previousSlide);
  };

  const handleSlideDisable = () => {
    if (disableChangeSlidesTimeout.current)
      clearTimeout(disableChangeSlidesTimeout.current);

    toggleButtonDisable(true);

    disableChangeSlidesTimeout.current = setTimeout(function () {
      toggleButtonDisable(false);
    }, 500);
  };

  const handleSlideSelect = (i) => {
    //If slide change is in progress, don't do anything
    if (buttonDisable) return;

    //If slide is currently selected slide, just return with no animation
    if (i === currentSlide) return;

    handleSlideDisable();

    //Set current slide
    currentSlideRef.current = i;
    setCurrentSlide(i);

    //If we are sharing slides, send the event
    if (isPresentingSlides) PresenterHelper.OnSlideChange(i);
  };

  const handlePresentationChange = (presentation) => {
    //Do nothing if this is our current presentation
    if (selectedPresentation !== null) {
      if (presentation.presentationID === selectedPresentation.presentationID)
        return;
    }

    //Reset index back to 0
    setCurrentSlide(0);

    setSelectedPresentation(presentation);

    //Close presentation menu
    toggleSelectPresentations(false);
    setAnchorElement(null);
  };

  const handleCalculateSlideMovementBounds = () => {
    //Change bounds height when popup is resized

    //If rnd is greater than all of inner content, height is 100% of window - (rndWindow - height of popup)
    let bounds = document.getElementById("slideBoundsHolder");

    let rndPopup = document.getElementById("slideToolHolder");
    let header = document.getElementById("slideToolHeaderHolder");
    let content = document.getElementById("slideContentHolder");
    let footer = document.getElementById("slidesFooter");

    //Ensure all elements exist before executing any logic
    if (bounds && rndPopup && header && content && footer) {
      let rndHeight = rndPopup.clientHeight;
      let headerHeight = header.clientHeight;
      let contentHeight = content.clientHeight;
      let footerHeight = footer.clientHeight;

      let heightOfContent = headerHeight + contentHeight + footerHeight;

      let height;

      if (heightOfContent > rndHeight) {
        height = window.innerHeight - (heightOfContent - rndHeight) + 4;
      } else {
        height = window.innerHeight + (rndHeight - heightOfContent) + 4;
      }

      bounds.style.height = `${height}px`;

      return height;
    }
  };

  const classes = SlidePresenterToolStyles();

  //Calculate initial X/Y position of popup
  let mqSmall = window.matchMedia("(max-width: 959.95px)");

  let yPosition;
  let windowHeight = window.innerHeight;

  yPosition = windowHeight - 410;

  //Calculate initial preview size based on screen size
  let toolWidthLocal;
  let toolHeightLocal;

  if (mqSmall.matches) {
    toolWidthLocal = 256;
    toolHeightLocal = 144;
  } else {
    toolWidthLocal = 501;
    toolHeightLocal = 400;
  }

  let handles = document.getElementsByClassName("bottomResizeHandle");
  if (handles.length) {
    handlesArray.current = handles;
  }
  let container = document.getElementById("slidesFooter");
  if (container) {
    if (handlesArray.current) {
      for (let i = 0; i < handlesArray.current.length; i++) {
        container.appendChild(handlesArray.current[i]);
      }
    }
  }

  useEffect(() => {
    //If first render, return
    if (firstRender.current) return;

    //If we are viewing all slides, move popup up accordingly
    //Also scroll the selected slide into view

    if (viewAllSlides) {
      let slideHolder = document.getElementById("slideThumbnailHolder");

      if (slideHolder) setOffsetTop(`-${slideHolder.clientHeight + 150}px`);

      let currentSlideDiv = document.getElementById(`SLIDE${currentSlide}`);

      if (currentSlideDiv)
        scrollIntoView(currentSlideDiv, { scrollMode: "if-needed" });

      let appHolder = document.getElementById("fullscreenApp");

      if (appHolder) appHolder.scrollTop = 0;
    }
  }, [viewAllSlides, currentSlide]);

  //Only render slide tool if it is selected by the presenter
  if (currentPresenterTool === StringVariableHelper.PresenterToolNames.slides) {
    return (
      <React.Fragment>
        <div className={styles.boundsHolder} id="slideBoundsHolder"></div>
        <Rnd
          lockAspectRatio={501 / 400}
          default={{
            width: toolWidthLocal,
            height: toolHeightLocal,
            x: toolXPosition ? toolXPosition : 10,
            y: toolYPosition ? toolYPosition : yPosition,
          }}
          minWidth={301}
          minHeight={240}
          resizeHandleStyles={{
            left: {
              display: "none",
            },
            right: {
              display: "none",
            },
            top: {
              display: "none",
            },
            bottom: {
              display: "none",
            },
          }}
          bounds="#slideBoundsHolder"
          className={
            transparent
              ? `${styles.slideToolHolder} ${styles.slideToolHolderTransparent}`
              : styles.slideToolHolder
          }
          resizeHandleWrapperClass={"resizeHandleWrapper"}
          resizeHandleClasses={{
            bottomLeft: "bottomResizeHandle",
            bottomRight: "bottomResizeHandle",
          }}
          style={{
            top: viewAllSlides ? offsetTop : -150,
          }}
          id="slideToolHolder"
          onDragStop={setPopupPositionInContext}
          onResizeStop={handleCalculateSlideMovementBounds}
          cancel=".cancelDrag"
        >
          <div className={styles.slideSizeHolder}>
            <Paper
              className={classes.slideToolHeaderHolder}
              id="slideToolHeaderHolder"
            >
              <Typography variant="h2" className={classes.slideToolHeader}>
                SLIDES
              </Typography>

              <div className={styles.iconButtonHolder}>
                {loadingSpinner && (
                  <CircularProgress className={classes.loadingSpinner} />
                )}

                <IconButton
                  onClick={handleSelectPresentationClick}
                  className={`${classes.iconButton} cancelDrag`}
                  disabled={disableFolderIcon || isPresentingSlides}
                  id="selectPresentationButton"
                >
                  <FolderIcon />
                </IconButton>

                <IconButton
                  onClick={() => toggleTransparent(!transparent)}
                  className={`${classes.iconButton} cancelDrag`}
                >
                  <VisibilityIcon />
                </IconButton>

                <IconButton
                  onClick={handleCloseSlideTool}
                  className={`${classes.iconButton} cancelDrag`}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Paper>

            <div
              className={
                !viewAllSlides && selectedPresentation !== null
                  ? `${styles.slideContentHolder} ${styles.slideContentHolderPreview}`
                  : styles.slideContentHolder
              }
              id="slideContentHolder"
              style={{
                minHeight: 0 /* selectedPresentation === null ? 0 : "88%" */,
              }}
            >
              <div
                className={
                  viewAllSlides
                    ? `${styles.previewHolder} ${styles.previewHolderViewSlides}`
                    : styles.previewHolder
                }
                style={{
                  backgroundImage:
                    selectedPresentation === null
                      ? ""
                      : `url(${
                          selectedPresentation.slideRoot +
                          selectedPresentation.slides[currentSlide]
                        }?preview)`,
                  backgroundSize: "cover",
                  height: selectedPresentation === null ? 280 : "auto",
                  width: "100%",
                }}
              >
                {selectedPresentation === null ? (
                  <Typography variant="body1" className={classes.slideText}>
                    Please click the folder icon above to select a presentation.
                  </Typography>
                ) : (
                  <img
                    src={
                      selectedPresentation.slideRoot +
                      selectedPresentation.slides[currentSlide]
                    }
                    style={{ visibility: "hidden" }}
                    className={styles.slidePreview}
                    alt="Slide preview"
                    crossOrigin="anonymous"
                  />
                )}

                <Button
                  disabled={
                    buttonDisable ||
                    selectedPresentation === null ||
                    (selectedPresentation !== null &&
                      currentSlide + 2 > selectedPresentation.slides.length)
                  }
                  variant="text"
                  onClick={handleNextSlideClick}
                  className={`${classes.nextSlideButton} cancelDrag`}
                  onTouchStart={handleNextSlideClick}
                >
                  <RightIcon fontSize="large" />
                </Button>

                <Button
                  disabled={buttonDisable || currentSlide - 1 < 0}
                  variant="text"
                  onClick={handlePreviousSlideClick}
                  classes={{
                    root: `${classes.previousSlideButton} cancelDrag`,
                    label: classes.previousSlideLabel,
                  }}
                  onTouchStart={handlePreviousSlideClick}
                >
                  <LeftIcon fontSize="large" />
                </Button>
              </div>

              {viewAllSlides && (
                <Paper
                  className={`${classes.slideHolder} cancelDrag`}
                  id="slideThumbnailHolder"
                >
                  <div className={styles.slideThumbnailHolder}>
                    {selectedPresentation.slides.map((thumbnail, i) => {
                      return (
                        <div
                          className={`${styles.thumbnailHolder} cancelDrag`}
                          onClick={() => handleSlideSelect(i)}
                          key={i}
                          id={`SLIDE${i}`}
                        >
                          <img
                            src={selectedPresentation.thumbnailRoot + thumbnail}
                            alt="thumbnail"
                            draggable={false}
                            className={styles.thumbnail}
                            crossOrigin="anonymous"
                          />

                          {i === currentSlide && (
                            <div className={styles.selectedIndicator}></div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Paper>
              )}

              <Paper
                className={
                  selectedPresentation === null
                    ? `${classes.slideFooterHolder} ${classes.slideFooterHolderNoPresentation}`
                    : viewAllSlides
                    ? `${classes.slideFooterHolder} ${classes.slideFooterHolderViewSlides}`
                    : classes.slideFooterHolder
                }
                id="slidesFooter"
              >
                <Button
                  variant="text"
                  disabled={selectedPresentation === null}
                  onClick={handleShowSlidesButton}
                  className={`${classes.showSlidesButton} cancelDrag`}
                >
                  {viewAllSlides ? "HIDE ALL SLIDES" : "VIEW ALL SLIDES"}

                  {viewAllSlides ? (
                    <ArrowDown className={classes.buttonArrowIcon} />
                  ) : (
                    <ArrowUp className={classes.buttonArrowIcon} />
                  )}
                </Button>

                <FormControlLabel
                  control={
                    <Switch
                      checked={isPresentingSlides}
                      onChange={handleToggleShareSlides}
                      name="Mode Toggle"
                      color="primary"
                    />
                  }
                  label="SHARE"
                  labelPlacement="start"
                  classes={{
                    root: `${classes.shareToggle} cancelDrag`,
                    label: classes.toggleLabel,
                  }}
                  disabled={selectedPresentation === null || disableShareToggle}
                />
              </Paper>
            </div>

            <Menu
              className={`${classes.presentationsMenu} cancelDrag`}
              open={selectPresentations}
              onClose={handleMenuClose}
              anchorEl={anchorElement}
            >
              {spacePresentations.map((presentation, i) => {
                return (
                  <MenuItem
                    value={presentation}
                    key={i}
                    onClick={() => handlePresentationChange(presentation)}
                  >
                    {presentation.name}

                    {selectedPresentation &&
                      presentation.presentationID ===
                        selectedPresentation.presentationID && (
                        <ActiveIcon className={classes.activeIconMenuItem} />
                      )}
                  </MenuItem>
                );
              })}
            </Menu>
          </div>
        </Rnd>
      </React.Fragment>
    );
  } else {
    return null;
  }
}
