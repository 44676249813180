import { makeStyles, createStyles } from '@material-ui/core/styles';

const ControlCenterButtonsStyles = makeStyles((theme: any) => createStyles({
  controlCenterButton: {
    padding: 0,
    borderRadius: 4,
    position: "relative",
    height: 35,
    backgroundColor: theme.palette.controlCenterButton.main,
    color: theme.palette.text.primary,
    minWidth: 0,
    boxShadow: "none",
    width: "46%",
    flexGrow: 1,
    justifyContent: "space-between",
    display: "inline-block",
    marginRight: 4,
    marginBottom: 4,
    paddingTop: 5,
    overflow: "hidden",
    boxSizing: "border-box",
    border: "2px solid transparent",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: theme.palette.controlCenterButton.main,
      borderColor: `${theme.palette.primary.main} !important`
    },
    '@media(hover: none)': {
      "&:hover": {
        backgroundColor: theme.palette.controlCenterButton.main,
        borderColor: "transparent !important"
      }
    }
  },
  "@keyframes pulse": {
    "0%": {
      boxShadow: `0 0 0 0px ${theme.palette.primary.main}`,
    },
    "100%": {
      boxShadow: `0 0 0 100px ${theme.palette.primary.main}`,
    }
  },
  controlCenterButtonMuted: { 
    backgroundColor: theme.palette.controlCenterButtonMuted.main,
    color: "black",
    "&:hover": {
      backgroundColor: theme.palette.controlCenterButtonMuted.main,
    }
  },
  controlCenterButtonAudienceMuted: {
    backgroundColor: "#B7B7B7",
    color: "#777777",
    "&:hover": {
      backgroundColor: "#B7B7B7",
    }
  },
  controlCenterButtonBottom: {
    width: 109
  },
  controlCenterButtonActivated: {
    backgroundColor: theme.palette.primary.main,
    opacity: "1 !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.main
    }
  },
  controlCenterButtonIcon: {
    position: "relative",
    bottom: 1.5,
  },
  controlCenterButtonChat: {
    display: "flex",
    alignContent: "flex-start",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center"
  },
  ripple: {
    width: 70,
    left: -8,
    color: "transparent"
  },
  rippleIndicator: {
    color: theme.palette.primary.main,
  },
  ripplePulsate: {
  },
  buttonLabel: {
    color: theme.palette.text.primary
  },
  chatIcon: {
    zIndex: 1,
    position: "relative",
    bottom: 2,
  },
  badge: {
    width: 1,
    height: 1,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    margin: "auto",
    animation: `$pulse 1s infinite cubic-bezier(.36, .11, .39, .59)`,
    WebkitAnimation: "$pulse 1s infinite cubic-bezier(.36, .11, .89, .32)",
    MozAnimation: "$pulse 1s infinite cubic-bezier(.36, .11, .89, .32)",
  },
  tooltip: {
    fontSize: 14,
    borderRadius: 4,
    border: "2px solid",
    borderColor: "white",
    backgroundColor: "#CCCCCC",
    right: -17,
    top: 0,
    color: "#313131"
  },
  tooltipPopover: {
    opacity: 1,
    left: 20,
  },
  tooltipArrow: {
    "&::before": {
      border: "2px solid",
      borderColor: "white",
      boxSizing: "border-box",
      backgroundColor: "#CCCCCC",
      position: "relative",
      bottom: 2,
    }
  }
})
)

export { ControlCenterButtonsStyles };