import { useState, useContext, useEffect } from "react";
import { BioFormContext, BioFormContextType } from "../context/BioFormContext";
import { ServerHelper } from "../Utilities/ServerHelper";
import useBypassIntake from "./useBypassIntake";
import useCallAppIntakePost from "./useCallAppIntakePost";
import useLoadApp from "./useLoadApp";

const useExecutePostJoinLogic = () => {
  //Intake-post custom hook
  const { callAppIntakePost } = useCallAppIntakePost();

  /* const { bypassJoinCompleted } = useBypassIntake(); */

  const { isJoinCompleted } = useLoadApp();

  const {
    contextFirstName,
    contextLastName,
    contextCompany,
    contextEmail,
    contextPhone,
    contextAddress,
  }: BioFormContextType = useContext(BioFormContext);


  const [inactivePopup, toggleInactivePopup] = useState(false);

  useEffect(() => {
    return function cleanup() {
      SHOWBOAT.UIEventManager.OnAwayFromKeyboard.Remove(handleAwayFromKeyboard);

      //Remove right click event listeners from all scroll containers
      document.querySelectorAll(".indiana-scroll-container").forEach((item) => {
        item.removeEventListener(
          "contextmenu",
          handleRightClickScrollContainer
        );
      });
    };
  }, []);

  const executePostJoinLogic = (props, styles) => {
    if (props) 
        //hide cookie notification
        props.handleJoinEventCompleted();

    

    //SHOWBOAT.AVController.setUserMadeCameraAndMicrophoneSelections(true);

    //create intake data object
    let intakeData: Object = {
      firstName: contextFirstName,
      lastName: contextLastName,
      company: contextCompany,
      emailAddress: contextEmail,
      phone: contextPhone,
      address: contextAddress,
    };

    //call app-intake-post lambda
    callAppIntakePost(intakeData);

    SHOWBOAT.UIEventManager.OnIntakeComplete.Raise(true);

    if (!ServerHelper.useUIDevelopment) {
      (window as any).babylonCanvas.classList.add(`${styles.mainCanvas}`);
      document
        .getElementById("fullscreenApp")
        .appendChild((window as any).babylonCanvas);
    }

    document.addEventListener("mouseup", (event) => {
      if (
        document.activeElement &&
        document.activeElement instanceof HTMLInputElement
      ) {
        //Don't refocus canvas if input type is "text" or "search"  to allow text input
        if (
          document.activeElement.type === "text" ||
          document.activeElement.type === "search"
        ) {
          return;
        }
      }

      //Dont refocus canvas if clicking and dragging the slide preview
      if (document.activeElement.classList.contains("react-draggable")) {
        return;
      }

      //Don't refocus canvas if clicking a textarea
      if (document.activeElement.nodeName === "TEXTAREA") {
        return;
      }

      //Don't refocus canvas if clicking the inspector or the scene explorer
      let inspector = document.getElementById("inspector-host");

      if (inspector && inspector.contains(document.activeElement)) {
        return;
      }

      let sceneExplorer = document.getElementById("scene-explorer-host");

      if (sceneExplorer && sceneExplorer.contains(document.activeElement)) {
        return;
      }

      //Don't refocus canvas if highlighting chat message text
      if (document.activeElement.classList.contains("selectableText")) {
        return;
      }
      let aWindow: any = window;
      aWindow.babylonCanvas.focus();
    });

    //Add event listeners to scroll containers to raise blur event on canvas when right clicking
    document.querySelectorAll(".indiana-scroll-container").forEach((item) => {
      item.addEventListener("contextmenu", handleRightClickScrollContainer);
    });

    //Sign up to listen for AFK event
    SHOWBOAT.UIEventManager.OnAwayFromKeyboard.Add(handleAwayFromKeyboard);
  };

  const handleRightClickScrollContainer = () => {
    //Raise blur event on canvas when right-clicking
    let canvas: HTMLElement = document.getElementById("showboatCanvas");

    canvas.dispatchEvent(new Event("blur"));
  };

  //Inactive popup
  const handleAwayFromKeyboard = () => {

    SHOWBOAT.Logger.Log("Showing AFK warning");
    toggleInactivePopup(true);
  };

  return {
    inactivePopup,
    toggleInactivePopup,
    executePostJoinLogic,
  };
};

export default useExecutePostJoinLogic;
