import { makeStyles, createStyles } from '@material-ui/core/styles';

const TableButtonStyles = makeStyles((theme: any) =>
  createStyles({
    tableButton: {
      width: 200,
      height: 50,
      padding: 0,
      borderRadius: 46,
      position: "fixed",
      top: "90.5%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      webkitTransform: "translate(-50%, -50%)",
      mozTransform: "translate(-50%, -50%)",
      oTransform: "translate(-50%, -50%)",
      msTransform: "translate(-50%, -50%)",
      backgroundColor: theme.palette.primary.main,
      color: "white",
      border: "5px solid rgba(0, 0, 0, 0.85)",
      backgroundClip: "padding-box",
      WebkitBackgroundClip: "paddingBox",
      boxSizing: "content-box",
      zIndex: 11,
      "&:hover": {
        backgroundColor: "#00980C",
      },
    },
    tableButtonExit: {
      backgroundColor: "#0076FF",
    },
    tableButtonFull: {
      backgroundColor: "#464646",
      "&:hover": {
        backgroundColor: "#464646",
      },
    },
    label: {
      position: "relative",
      top: 1,
      marginLeft: 16,
    },
    labelExit: {
      left: 2,
    },
    tableButtonDisabled: {
      opacity: 0.6,
      backgroundColor: "#00980C !important",
    },
  })
);

export { TableButtonStyles };