import React, { ReactElement, useState } from "react";
import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import axios from "axios";

import { SendToSupportStyles } from "./SendToSupportStyles";
import styles from "./SendToSupport.module.css";

interface Props {
  handleBackClick: () => any;
  dataObj: any;
  bookingID: string;
  systemCheckID: string;
}

export default function SendToSupport(props: Props): ReactElement {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [comment, setComment] = useState("");
  const [, toggleFirstNameRequiredError] = useState(false);
  const [postSuccess, togglePostSuccess] = useState(false);
  const [postError, togglePostError] = useState(false);
  const [loadingSpinner, toggleLoadingSpinner] = useState(false);

  //Change handlers
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  //Submit handler
  const handleSubmit = async () => {
    //Show loader
    toggleLoadingSpinner(true);

    //Hide error message
    togglePostError(false);

    //See if firstName is missing (required)
    if (!firstName.trim().length) {
      toggleFirstNameRequiredError(true);
      return;
    }

    //Assemble speaker device array
    let speakerDeviceArray = [];
    if (props.dataObj.speakerDeviceArray && props.dataObj.speakerDeviceArray.length) {
      for (let i = 0; i < props.dataObj.speakerDeviceArray.length; i++) {
        speakerDeviceArray.push(props.dataObj.speakerDeviceArray[i]);
      }
    }

    props.dataObj.speakerDeviceArray = speakerDeviceArray;

    toggleFirstNameRequiredError(false);

    let bookingIDToSend: string;

    //Check if we have a bookingID
    if (props.bookingID !== "") {
      bookingIDToSend = props.bookingID;
    } else {
      bookingIDToSend = "00000000-0000-0000-0000-000000000000";
    }

    try {
      await axios.post("/system-check", {
        bookingID: bookingIDToSend,
        firstName: firstName,
        lastName: lastName,
        comment: comment,
        data: props.dataObj,
        systemCheckID: props.systemCheckID,
      });

      toggleLoadingSpinner(false);

      togglePostSuccess(true);
    } catch {
      toggleLoadingSpinner(false);

      //Show error
      togglePostError(true);
    }
  };

  const classes = SendToSupportStyles();

  return (
    <div className={styles.sendToSupportContentHolder}>
      <Button
        className={classes.backButton}
        onClick={props.handleBackClick}
        variant="contained"
      >
        BACK
      </Button>

      <Typography variant="h1" className={classes.sendToSupportHeader}>
        Send to Support
      </Typography>

      {postError && (
        <Typography variant="body1" className={classes.errorMessage}>
          An error occurred. Please try again.
        </Typography>
      )}

      {postSuccess ? (
        <div className={styles.successHolder}>
          <Typography variant="h2" className={classes.successHeader}>
            Success! Thank you for contacting Showboat support.
          </Typography>
        </div>
      ) : (
        <div className={styles.formHolder}>
          <div className={styles.formItem}>
            <Typography variant="h2" className={classes.formHeader}>
              First Name
            </Typography>

            <TextField
              variant="outlined"
              className={classes.formInput}
              value={firstName}
              onChange={handleFirstNameChange}
            ></TextField>
          </div>

          <div className={styles.formItem}>
            <Typography variant="h2" className={classes.formHeader}>
              Last Name
            </Typography>

            <TextField
              variant="outlined"
              className={classes.formInput}
              value={lastName}
              onChange={handleLastNameChange}
            ></TextField>
          </div>

          <div className={styles.formItem}>
            <Typography variant="h2" className={classes.formHeader}>
              Comment
            </Typography>

            <TextField
              variant="outlined"
              multiline
              fullWidth
              rows={6}
              className={`${classes.formInput} ${classes.formInputComment}`}
              InputProps={{
                className: classes.commentInput,
              }}
              value={comment}
              onChange={handleCommentChange}
            ></TextField>
          </div>

          {loadingSpinner && (
            <CircularProgress className={classes.loadingSpinner} />
          )}

          <Button
            className={classes.submitButton}
            variant="contained"
            onClick={handleSubmit}
            disabled={loadingSpinner}
          >
            SUBMIT
          </Button>
        </div>
      )}
    </div>
  );
}
