import { makeStyles, createStyles } from '@material-ui/core/styles';

const AvatarHolderStyles = makeStyles((theme: any) => createStyles({
  avatarHolder: {
    width: 370,
    height: 511,
    position: "absolute",
    display: "inline-block",
    backgroundColor: theme.palette.intakePaper.main,
    left: 0,
    /* bottom: 59, */
    bottom: -59,
    textAlign: "center",
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      width: 300,
      height: 450,
      /* bottom: 120, */
      bottom: 1,
    }
  },
  avatarHeader: {
    display: "inline-block",
    fontSize: 22,
    color: theme.palette.text.main,
    position: "relative",
    fontWeight: 400,
    fontFamily: "Open Sans, sans-serif",
    top: 34,
    right: 4,
    zIndex: 9999
  }
})
)

export { AvatarHolderStyles };