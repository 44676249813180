import React, {
  ReactElement,
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback,
} from "react";
import { Paper, Typography, Button } from "@material-ui/core";
import $ from "jquery";

import {
  ErrorContext,
  ErrorContextType,
} from "../../../../context/ErrorContext";

import { ErrorPopupStyles } from "./ErrorPopupStyles";
import { StringVariableHelper } from "../../../../Utilities/StringVariableHelper";

interface Props {
  message: string;
  errorID: number;
  handleErrorClose: (errorID: number) => any;
}

export default function ErrorPopup(props: Props): ReactElement {
  const { toggleHasVideoError, toggleHasServerError }: ErrorContextType =
    useContext(ErrorContext);

  const [isDisconnectError, toggleIsDisconnectError] = useState(false);
  const [isBackstageTeleportError, toggleIsBackstageTeleportError] =
    useState(false);

  const autoCloseTimer = useRef(null);
  const displayTimer = useRef(null);

  //Destructure props for useCallback dependencies
  const { handleErrorClose, errorID } = props;
  const handleServerConnectionErrorToggle = useCallback(
    (isDisplayed: boolean) => {
      if (!isDisplayed) {
        toggleHasServerError(false);

        var $thisParent = $(`#${props.errorID}`).closest("div");

        $thisParent.nextAll().each(function () {
          $(this)
            .stop()
            .animate(
              {
                top: `-=${$thisParent.outerHeight(true)}`,
              },
              300
            );
        });

        handleErrorClose(props.errorID);
      }
    },
    [handleErrorClose, errorID]
  );

  const handleVideoConnectionErrorToggle = useCallback(
    (isDisplayed: boolean) => {
      if (!isDisplayed) {
        toggleHasVideoError(false);

        var $thisParent = $(`#${props.errorID}`).closest("div");

        $thisParent.nextAll().each(function () {
          $(this)
            .stop()
            .animate(
              {
                top: `-=${$thisParent.outerHeight(true)}`,
              },
              300
            );
        });

        props.handleErrorClose(props.errorID);
      }
    },
    [handleErrorClose, errorID]
  );

  useEffect(() => {
    if (
      props.message !== StringVariableHelper.PopupErrorText.socketDisconnect &&
      props.message !== StringVariableHelper.PopupErrorText.webcamDisconnect
    ) {
      autoCloseTimer.current = setTimeout(function () {
        
        var $thisParent = $(`#${props.errorID}`).closest("div");

        $thisParent.nextAll().each(function (index) {
          $(this)
            .stop()
            .animate(
              {
                top: `-=${$thisParent.outerHeight(true)}`,
              },
              300
            );
        });

        props.handleErrorClose(props.errorID);
      }, 10000);
    } else {
      toggleIsDisconnectError(true);

      //add UI Listeners for toggle false
      if (
        props.message === StringVariableHelper.PopupErrorText.socketDisconnect
      ) {
        SHOWBOAT.UIEventManager.OnServerConnectionErrorToggle.Add(
          handleServerConnectionErrorToggle
        );
      } else if (
        props.message === StringVariableHelper.PopupErrorText.webcamDisconnect
      ) {
        SHOWBOAT.LiveswitchUpstreamController.OnVideoConnectionError.Add(
          handleVideoConnectionErrorToggle
        );
      }
    }

    if (
      props.message ===
      "Teleporting to this person is disabled while you are backstage"
    ) {
      toggleIsBackstageTeleportError(true);
    }

    displayTimer.current = setTimeout(function () {
      document.getElementById(props.errorID.toString()).style.display =
        "inline-block";
    }, 300);

    return function cleanup() {
      if (autoCloseTimer.current) {
        clearTimeout(autoCloseTimer.current);
      }

      if (displayTimer.current) {
        clearTimeout(displayTimer.current);
      }

      SHOWBOAT.LiveswitchUpstreamController.OnVideoConnectionError.Remove(
        handleVideoConnectionErrorToggle
      );
      SHOWBOAT.UIEventManager.OnServerConnectionErrorToggle.Remove(
        handleServerConnectionErrorToggle
      );
    };
  }, []);

  //DISMISS ERROR
  const handleDismissClick = () => {
    if (
      props.message === StringVariableHelper.PopupErrorText.socketDisconnect
    ) {
      toggleHasServerError(false);
    } else if (
      props.message === StringVariableHelper.PopupErrorText.webcamDisconnect
    ) {
      toggleHasVideoError(false);
    }

    props.handleErrorClose(props.errorID);
  };

  const classes = ErrorPopupStyles();

  return (
    <Paper
      elevation={5}
      classes={{
        root: isDisconnectError
          ? `${classes.errorPopup} ${classes.errorPopupDisconnect}`
          : isBackstageTeleportError
          ? `${classes.errorPopup} ${classes.errorPopupBackstageTeleport}`
          : classes.errorPopup,
      }}
      className="notification"
      id={props.errorID.toString()}
    >
      <Typography variant="h1" className={classes.errorText}>
        Error: {props.message}
      </Typography>

      <Button
        onClick={handleDismissClick}
        variant="text"
        className={`${classes.dismissButton} dismissButton`}
      >
        DISMISS
      </Button>
    </Paper>
  );
}
