import React, { ReactElement } from 'react';
import { Typography } from '@material-ui/core';

import CloseIconButton from '../CloseIconButton/CloseIconButton';

import styles from './ChatWithEveryone.module.css';
import { ChatWithEveryoneStyles } from './ChatWithEveryoneStyles';

interface Props {
  handleSectionClose: () => void,
  currentSection: number
}

export default function ChatWithEveryone(props: Props): ReactElement {

  const handleCloseButtonClick = (e?) => {
    props.handleSectionClose();

    if (e) e.stopPropagation();
  }
  
  const classes = ChatWithEveryoneStyles();
  
  return (
    <React.Fragment>

      {props.currentSection === 2 &&
        <React.Fragment>
          <div
            className={styles.closeDiv}
            onClick={handleCloseButtonClick}
            role="button"
          ></div>

          <CloseIconButton 
            handleCloseButtonClick={handleCloseButtonClick}        
          />
        </React.Fragment>
      }

      <img 
        className={styles.chatEveryoneImg} 
        alt="Chat button" 
        src={`assets/images/ChatEveryone${SHOWBOAT.ApplicationSkin.theme === "light" ? "Light" : "Dark"}.svg`}
      />

      <Typography 
        variant="body1" 
        className={classes.chatEveryoneText}
        style={{
          width: "250px"
        }}
      >
        Press the chat button to display the full event chat. Messages posted here are seen by everyone.
      </Typography>
    </React.Fragment>
  )
}
