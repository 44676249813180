import React, { useState } from "react";
import { StringVariableHelper } from "../Utilities/StringVariableHelper";

export interface BypassContextType {
  bypassJoinCompleted: boolean,
  setBypassJoinCompleted: (bypassJoinCompleted: boolean) => void
}

export const BypassContext = React.createContext(null);

export const BypassContextProvider = (props) => {
  const [bypassJoinCompleted, setBypassJoinCompleted] = useState(false);

  const drawerProviderValue: BypassContextType = {
    bypassJoinCompleted,
    setBypassJoinCompleted
  };

  return (
    <BypassContext.Provider value={drawerProviderValue}>
      {props.children}
    </BypassContext.Provider>
  );
};
