export class AdvancedLoggingHelper {
  public static intervalMap: Map<string, NodeJS.Timeout> = new Map();

  public static Init = () : void => {
    //Add listener for attendee leaving 
    SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Add(AdvancedLoggingHelper.ClearIntervalForUser)
  }

  public static StartLogging = (userID: string): void => {
    //Check if there is already an interval running for this user
    if (AdvancedLoggingHelper.CheckIfIntervalForUser(userID)) return;

    //Send AdvancedLogging event
    SHOWBOAT.UIEventManager.OnAdvancedDebugToggle.Raise([userID], true);

    //Start 5sec interval for the userID
    AdvancedLoggingHelper.intervalMap.set(
      userID,
      setInterval(function () {
        SHOWBOAT.UIEventManager.OnAdvancedDebugToggle.Raise([userID], true);
      }, 5000)
    );
  };

  public static StopLogging = (userID: string): void => {
    //Stop logging and clear 5 second interval for user

    //Send off event
    SHOWBOAT.UIEventManager.OnAdvancedDebugToggle.Raise([userID], false);

    //Remove update interval for this userID
    AdvancedLoggingHelper.ClearIntervalForUser(userID);
  };

  public static ClearIntervalForUser = (userID): void => {
    if (AdvancedLoggingHelper.CheckIfIntervalForUser(userID)) {
      //Clear the interval and remove from map
      clearInterval(AdvancedLoggingHelper.intervalMap.get(userID));
      AdvancedLoggingHelper.intervalMap.delete(userID);
    }
  }

  public static CheckIfIntervalForUser = (userID: string): boolean => {
    return AdvancedLoggingHelper.intervalMap.has(userID);
  };
}
