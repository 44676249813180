import { makeStyles, createStyles } from '@material-ui/core/styles';

const SendToSupportStyles = makeStyles((theme: any) => createStyles({
  sendToSupportHeader: {
    fontFamily: "Open Sans, sans-serif",
    fontSize: 24,
    fontWeight: 400,
  },
  backButton: {
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: "#00B6FF",
    color: "black"
  },
  formHeader: {
    color: "white",
    fontSize: 18,
    fontWeight: 400,
    fontFamily: "Roboto, sans-serif"
  },
  formInput: {
    position: "relative",
    top: 15,
    width: "65%",
    '@media (max-width: 460px)': {
      width: "100%"
    }
  },
  formInputComment: {
    width: "100%",
    height: 150,
    display: "inline-block"
  },
  commentInput: {
    height: 150,
    margin: "auto"
  },
  submitButton: {
    backgroundColor: "#00B6FF",
    position: "absolute",
    bottom: -40,
    left: 0,
    color: "black"
  },
  successHeader: {
    color: "white",
    fontSize: 20,
    display: "inline-block",
    position: "relative",
    top: 50,
    fontWeight: 400,
  },
  errorMessage: {
    color: "red",
    fontSize: 14,
    position: "relative",
    top: 15,
  },
  loadingSpinner: {
    position: "absolute",
    color: "grey",
    width: "25px !important",
    height: "25px !important",
    bottom: -35,
    left: 110,
  }
})
)

export { SendToSupportStyles };