import { makeStyles, createStyles } from '@material-ui/core/styles';

const PresenterMicStatusTextStyles = makeStyles((theme: any) => createStyles({
  micStatusPopup: {
    width: 186,
    height: 34,
    borderRadius: 25,
    position:"absolute",
    left: "50%",
    marginLeft: -93,
    zIndex: 10,
    top: 5,
    display: "inline-block",
    backgroundColor: "green",
  },
  micIcon: {
    fontSize: 25,
    position: "absolute",
    top: 2,
    left: 14,
    color: "red",
    '@media (max-width: 1280px)': {
      left: 0,
    },
    /* '@media (max-width: 1065px)': { */
    '@media (max-width: 950px)': {
      top: 12,
      left: 10,
    }
  },
  starIcon: {
    fontSize: 25,
    position: "absolute",
    top: 2,
    left: 14,
    color: "green",
    '@media (max-width: 1280px)': {
      left: 0,
    },
    /* '@media (max-width: 1065px)': { */
    '@media(max-width: 950px)': {
      top: 12,
      left: 10,
    }
  },
  text: {
    fontSize: 14,
    position: "relative",
    left: 10,
    top: 5,
    color: "red",
    fontWeight: 500,
    '@media (max-width: 1280px)': {
      display: "inline-block",
      width: 140,
      left: -41,
      top: -1,
      lineHeight: 1.2,
    },
    '@media (max-width: 1120px)': {
      width: 115,
      left: -58
    },  
    /* '@media (max-width: 1065px)': { */
    '@media(max-width: 950px)': {
      left: 15,
      top: 3,
      width: "auto"
    }
  },
  textBackstage: {
    color: "green",
    left: -20,
    '@media (max-width: 1280px)': {
      top: 3,
      left: -47,
    },
    '@media (max-width: 1120px)': {
      top: 0,
      left: -70
    },
    /* '@media (max-width: 1065px)': { */
    '@media (max-width: 950px)': {
      left: 15,
      top: 3,
      width: "auto"
    }
  }
})
)

export { PresenterMicStatusTextStyles };