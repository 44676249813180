import { Checkbox, Paper, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import { MovePopupItemStyles } from "./MovePopupItemStyles";

interface Props {
  attendee: any;
  selectedAttendeeIDs: Array<string>;
  handleRemoveAttendee: (userID: string) => void;
  handleAddAttendee: (userID: string) => void;
}

export default function MovePopupItem(props: Props): ReactElement {
  const handleAttendeeSelect = (e) => {
    //Call props function
    if (e.target.checked) {
      props.handleAddAttendee(props.attendee.userID)
    } else {
      props.handleRemoveAttendee(props.attendee.userID)
    }
  };

  const classes = MovePopupItemStyles();

  return (
    <Paper className={classes.attendeeHolder} key={props.attendee.userID}>
      <Checkbox
        color="primary"
        checked={props.selectedAttendeeIDs.includes(props.attendee.userID)}
        
        onChange={handleAttendeeSelect}
        name="Select attendee"
        className={classes.attendeeCheckbox}
      />

      <Typography
        variant="h6"
        classes={{
          root: classes.attendeeName,
        }}
      >
        {props.attendee.firstName} {props.attendee.lastName}
      </Typography>

      <Typography variant="body2" classes={{ root: classes.attendeeCompany }}>
        {props.attendee.company}
      </Typography>
    </Paper>
  );
}
