import { Paper, Typography, IconButton, Tab, Tabs } from "@material-ui/core";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import SupportIcon from "@material-ui/icons/Help";
import Linkify from "react-linkify";
import RightArrowIcon from "@material-ui/icons/ArrowForward";

import HowToMove from "./SidebarSections/HowToMove/HowToMove";
import FindPeople from "./SidebarSections/FindPeople/FindPeople";
import ChatWithEveryone from "./SidebarSections/ChatWithEveryone/ChatWithEveryone";
import PrivateMessageSection from "./SidebarSections/PrivateMessageSection/PrivateMessageSection";
import Teleport from "./SidebarSections/Teleport/Teleport";
import SwitchRooms from "./SidebarSections/SwitchRooms/SwitchRooms";
import ExitEvent from "./SidebarSections/ExitEvent/ExitEvent";
import { ServerHelper } from "../../../../Utilities/ServerHelper";
import { AppContext, AppContextType } from "../../../../context/AppContext";

import styles from "./SupportSidebar.module.css";
import { SupportSidebarStyles } from "./SupportSidebarStyles";

interface Props {}

export default function SupportSidebar(props: Props): ReactElement {
  const [activeSection, setActiveSection] = useState(null);

  const [tab, setTab] = useState(ServerHelper.enableSupport ? 0 : 1);

  const { setCurrentSidebarMode, isPresenter }: AppContextType =
    useContext(AppContext);

  let linkColor;
  if (SHOWBOAT.ApplicationSkin.theme === "light") {
    linkColor = "black";
  } else {
    linkColor = "white";
  }

  const handleTabSelect = (tab) => {
    setTab(tab);
  };

  const handleSectionClose = () => {
    setActiveSection(null);
  };

  const classes = SupportSidebarStyles();

  //Add mobile CSS classes for vh unit hack
  useEffect(() => {
    let supportContentHolder = document.getElementById("supportContentHolder");
    if (supportContentHolder) {
      if (isPresenter) {
        if (ServerHelper.enableSupport) {
          supportContentHolder.classList.add(
            `${classes.supportContentHolderMobile}`
          );
        } else {
          supportContentHolder.classList.add(
            `${classes.supportContentHolderNoSupportMobile}`
          );
        }
      } else {
        if (ServerHelper.enableSupport) {
          supportContentHolder.classList.add(
            `${classes.supportContentHolderNonPresenterMobile}`
          );
        } else {
          supportContentHolder.classList.add(
            `${classes.supportContentHolderNonPresenterNoSupportMobile}`
          );
        }
      }
    }
  }, [
    classes.supportContentHolderMobile,
    classes.supportContentHolderNoSupportMobile,
    classes.supportContentHolderNonPresenterMobile,
    classes.supportContentHolderNonPresenterNoSupportMobile,
    isPresenter
  ]);

  return (
    <React.Fragment>
      <Paper
        className={
          ServerHelper.enableSupport
            ? classes.supportHeaderHolder
            : `${classes.supportHeaderHolder} ${classes.supportHeaderHolderNoSupport}`
        }
      >
        <Paper
          elevation={0}
          className={
            ServerHelper.enableSupport
              ? classes.supportHeaderTop
              : `${classes.supportHeaderTop} ${classes.supportHeaderTopNoSupport}`
          }
        >
          <SupportIcon className={classes.supportIcon} />

          <Typography variant="h1" className={classes.supportHeader}>
            Support
          </Typography>

          <IconButton
            className={classes.closeIconButton}
            onClick={() => setCurrentSidebarMode("")}
          >
            <RightArrowIcon />
          </IconButton>
        </Paper>

        {ServerHelper.enableSupport && (
          <Tabs
            classes={{ root: classes.tabs }}
            indicatorColor="primary"
            value={tab}
            variant="fullWidth"
          >
            <Tab
              onClick={() => handleTabSelect(0)}
              classes={{
                root: `${classes.supportTab} ${classes.informationTab}`,
              }}
              label="INFORMATION"
            />
            <Tab
              onClick={() => handleTabSelect(1)}
              classes={{ root: classes.supportTab }}
              label="FAQ"
            />
          </Tabs>
        )}
      </Paper>

      <Paper
        className={
          isPresenter
            ? ServerHelper.enableSupport
              ? classes.supportContentHolder
              : `${classes.supportContentHolder} ${classes.supportContentHolderNoSupport}`
            : ServerHelper.enableSupport
            ? `${classes.supportContentHolder} ${classes.supportContentHolderNonPresenter}`
            : `${classes.supportContentHolder} ${classes.supportContentHolderNonPresenterNoSupport}`
        }
        id="supportContentHolder"
      >
        {tab === 0 && (
          <Paper
            className={
              SHOWBOAT.LocalAvatarDataManager.role === "presenter"
                ? classes.supportTextHolder
                : `${classes.supportTextHolder} ${classes.supportTextHolderNonPresenter}`
            }
          >
            <Typography variant="body1" className={classes.supportText}>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    target="_blank"
                    href={decoratedHref}
                    key={key}
                    style={{ color: linkColor, wordWrap: "break-word" }}
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {ServerHelper.supportMessage}
              </Linkify>
            </Typography>
          </Paper>
        )}

        {tab === 1 && (
          <div className={styles.faqHolder}>
            <Paper
              className={
                activeSection === 0
                  ? `${classes.sectionHolder} ${classes.sectionHolderActive}`
                  : classes.sectionHolder
              }
              style={{
                height: activeSection === 0 ? "395px" : "40px",
              }}
              onClick={() => setActiveSection(0)}
            >
              <Typography variant="h3" className={classes.sectionHeader}>
                HOW TO MOVE
              </Typography>

              <HowToMove
                handleSectionClose={handleSectionClose}
                currentSection={activeSection}
              />
            </Paper>

            <Paper
              className={
                activeSection === 1
                  ? `${classes.sectionHolder} ${classes.sectionHolderActive}`
                  : classes.sectionHolder
              }
              style={{
                height: activeSection === 1 ? "184px" : "40px",
              }}
              onClick={() => setActiveSection(1)}
            >
              <Typography variant="h3" className={classes.sectionHeader}>
                HOW TO FIND PEOPLE
              </Typography>

              <FindPeople
                handleSectionClose={handleSectionClose}
                currentSection={activeSection}
              />
            </Paper>

            <Paper
              className={
                activeSection === 2
                  ? `${classes.sectionHolder} ${classes.sectionHolderActive}`
                  : classes.sectionHolder
              }
              style={{
                height: activeSection === 2 ? "176px" : "40px",
              }}
              onClick={() => setActiveSection(2)}
            >
              <Typography variant="h3" className={classes.sectionHeader}>
                CHAT WITH EVERYONE
              </Typography>

              <ChatWithEveryone
                handleSectionClose={handleSectionClose}
                currentSection={activeSection}
              />
            </Paper>

            <Paper
              className={
                activeSection === 3
                  ? `${classes.sectionHolder} ${classes.sectionHolderActive}`
                  : classes.sectionHolder
              }
              style={{
                height: activeSection === 3 ? "315px" : "40px",
              }}
              onClick={() => setActiveSection(3)}
            >
              <Typography variant="h3" className={classes.sectionHeader}>
                SEND A PRIVATE MESSAGE
              </Typography>

              <PrivateMessageSection
                handleSectionClose={handleSectionClose}
                currentSection={activeSection}
              />
            </Paper>

            <Paper
              className={
                activeSection === 4
                  ? `${classes.sectionHolder} ${classes.sectionHolderActive}`
                  : classes.sectionHolder
              }
              style={{
                height: activeSection === 4 ? "411px" : "40px",
              }}
              onClick={() => setActiveSection(4)}
            >
              <Typography variant="h3" className={classes.sectionHeader}>
                TELEPORT
              </Typography>

              <Teleport
                handleSectionClose={handleSectionClose}
                currentSection={activeSection}
              />
            </Paper>

            <Paper
              className={
                activeSection === 5
                  ? `${classes.sectionHolder} ${classes.sectionHolderActive}`
                  : classes.sectionHolder
              }
              style={{
                height: activeSection === 5 ? "640px" : "40px",
              }}
              onClick={() => setActiveSection(5)}
            >
              <Typography variant="h3" className={classes.sectionHeader}>
                HOW TO SWITCH ROOMS
              </Typography>

              <SwitchRooms
                handleSectionClose={handleSectionClose}
                currentSection={activeSection}
              />
            </Paper>

            <Paper
              className={
                activeSection === 6
                  ? `${classes.sectionHolder} ${classes.sectionHolderActive}`
                  : classes.sectionHolder
              }
              style={{
                height: activeSection === 6 ? "172px" : "40px",
              }}
              onClick={() => setActiveSection(6)}
            >
              <Typography variant="h3" className={classes.sectionHeader}>
                HOW TO EXIT AN EVENT
              </Typography>

              <ExitEvent
                handleSectionClose={handleSectionClose}
                currentSection={activeSection}
              />
            </Paper>
          </div>
        )}
      </Paper>
    </React.Fragment>
  );
}
