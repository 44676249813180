import React, { useState, useContext, useEffect, useRef } from "react";
import { Paper } from "@material-ui/core";
import ReactDOM from "react-dom";
import { isMobile, isSafari } from "react-device-detect";

import "../App.css";
import NotificationsWrapper from "../ui/components/Popups/NotificationsWrapper/NotificationsWrapper";
import { AppContextProvider } from "../context/AppContext";
import { MessageContextProvider } from "../context/MessageContext";
import { PresenterContextProvider } from "../context/PresenterContext";
import { DeviceContextProvider } from "../context/DeviceContext";
import { RoomAvailabilityContextProvider } from "../context/RoomAvailabilityContext";
import IntakeHeader from "../Intake/IntakeHeader/IntakeHeader";
import WelcomePage from "../Intake/Pages/WelcomePage/WelcomePage";
import BioIntake from "../Intake/Pages/BioIntake/BioIntake";
import AvatarHolder from "../Intake/AvatarHolder/AvatarHolder";
import DeviceToggle from "../Intake/Pages/DeviceToggle/DeviceToggle";
import CameraAndMicPage from "../Intake/Pages/CameraAndMicPage/CameraAndMicPage";
import SpeakersPage from "../Intake/Pages/SpeakersPage/SpeakersPage";
import { NearbyAttendeeContextProvider } from "../context/NearbyAttendeeContext";
import { OneOnOneChatContextProvider } from "../context/OneOnOneChatContext";
import PrivateMessagesWrapper from "../ui/components/Popups/PrivateMessagesWrapper/PrivateMessagesWrapper";
import FullScreenLoadingScreen from "../LoadingScreen/FullScreenLoadingScreen/FullScreenLoadingScreen";
import EventEndedPage from "../Intake/Pages/EventEndedPage/EventEndedPage";
import { ServerHelper } from "../Utilities/ServerHelper";
import { IntakeContext, IntakeContextType } from "../context/IntakeContext";
import MobileControls from "../ui/components/MobileControls/MobileControls";
import MovementInstructionsPage from "../Intake/Pages/MovementInstructionsPage/MovementInstructionsPage";
import InactivePopup from "../ui/components/Popups/InactivePopup/InactivePopup";
import DeviceToggleWarning from "../Intake/Pages/DeviceToggleWarning/DeviceToggleWarning";
import PresenterMenu from "../ui/components/PresenterMenu/PresenterMenu";
import ControlCenter from "../ui/components/ControlCenter/ControlCenter";
import { UIHelper } from "../Utilities/UIHelper";
import PresenterToolsHolder from "../ui/components/PresenterToolsHolder/PresenterToolsHolder";
import SlidePresenterTool from "../ui/components/SlidePresenterTool/SlidePresenterTool";
import RightBar from "../ui/components/RightBar/RightBar";
import { StringVariableHelper } from "../Utilities/StringVariableHelper";
import ColorSelect from "../Intake/Pages/ColorSelect/ColorSelect";
import IntakeStepper from "../Intake/IntakeStepper/IntakeStepper";
import useLoadApp from "../hooks/useLoadApp";
import useExecutePostJoinLogic from "../hooks/useExecutePostJoinLogic";
import PhotoPopup from "../ui/components/Popups/PhotoPopup/PhotoPopup";
import useBypassIntake from "../hooks/useBypassIntake";
import MovePopup from "../ui/components/Popups/MovePopup/MovePopup";
import TwoDOverlay from "../ui/components/TwoDOverlay/TwoDOverlay";
import TableButton from "../ui/components/Popups/TableButton/TableButton";
import { BypassContext } from "../context/BypassContext";

import styles from "./PageLayout.module.css";
import { PageLayoutStyles } from "./PageLayoutStyles";
import TestCameraHolder from "../ui/components/TestCameraHolder/TestCameraHolder";


interface Props {
  handleJoinEventCompleted: () => any;
}

export default function PageLayout(props: Props) {
  //Context
  const { toggleHomeWasClicked }: IntakeContextType = useContext(IntakeContext);

  //Custom hooks
  const { loadApp, isIntakeCompleted, isJoinCompleted } = useLoadApp();

  const { executePostJoinLogic, inactivePopup, toggleInactivePopup } =
    useExecutePostJoinLogic();

  const { bypassJoinCompleted } =
    useContext(BypassContext);
    
  const { executeBypass } = useBypassIntake();

  //State
  const [currentFormPage, setCurrentFormPage] = useState({ formPage: null });
  const [currentStepNumber, setCurrentStepNumber] = useState(0);
  const [fullScreenLoad, toggleFullScreenLoad] = useState(false);

  //Refs
  const noHasBeenClicked = useRef(false);
  const micAndCameraRef = useRef(false);
  const backClickedRef = useRef(false);

  useEffect(() => {
    //ServerHelper.testingFoo();

    //Initialize the UIHelper
    UIHelper.Init();

    //Check for cached color
    UIHelper.CheckForCachedColor();

    //Check for cached face
    UIHelper.CheckForCachedFace();

    SHOWBOAT.ShowboatLoader.OnShowLoadScreen.Add(handleShowLoadingScreen);
    SHOWBOAT.ShowboatLoader.OnHideLoadScreen.Add(handleHideLoadingScreen);

    SHOWBOAT.SocketIOController.OnEventEnded.Add(handleEventEnded);

    //Check if we are doing a bypass
    const checkForBypass = async () => {
      if (ServerHelper.bypass) {
        toggleFullScreenLoad(true);

        //Do not immediately execute bypass on Safari
        //Safari requires a user action
        if (!isSafari) {
          let bypassResult = await executeBypass();

          if (!bypassResult || bypassResult === "NoIntakeData") {
            //Refresh the page with no query param
            window.location.replace(
              UIHelper.removeQueryParam("bypass", window.location.href)
            );
          }
        }
      }
    };
    checkForBypass();

    return function cleanup() {
      SHOWBOAT.ShowboatLoader.OnShowLoadScreen.Add(handleShowLoadingScreen);
      SHOWBOAT.ShowboatLoader.OnHideLoadScreen.Add(handleHideLoadingScreen);

      SHOWBOAT.SocketIOController.OnEventEnded.Remove(handleEventEnded);

      //Remove confirm close event listener
      window.onbeforeunload = null;
    };
  }, []);

  const handleEventEnded = () => {
    SHOWBOAT.LiveSwitchClientController.disconnect();

    SHOWBOAT.SocketIOController.Disconnect();

    //Remove confirm close event listener
    window.onbeforeunload = null;

    ReactDOM.render(<EventEndedPage />, document.getElementById("root"));
  };

  /*Handle home button click (returns to main page) */
  const handleHomeClick = () => {
    //Stop devices if they are running
    SHOWBOAT.StreamingUserMedia.SetDeviceStates(false, false);

    setCurrentStepNumber(0);

    toggleHomeWasClicked(true);

    setCurrentFormPage({
      formPage: null,
    });
  };
  /**/

  /* Handlers for completing parts of intake */
  const handleWelcomePageComplete = () => {
    SHOWBOAT.StreamingUserMedia.Init(
      false,
      SHOWBOAT.RemotePlayersZoneConfig.doAvatarSimulation
    );

    let newState = {
      formPage: (
        <BioIntake onBioIntakeComplete={handleBioIntakeComplete}></BioIntake>
      ),
    };

    setCurrentFormPage(newState);
  };

  const handleBioIntakeComplete = () => {
    setCurrentStepNumber(1);

    let newState = {
      formPage: (
        <ColorSelect
          onColorSelectBackClick={handleColorBackButtonClick}
          onColorSelectComplete={handleColorSelectComplete}
        ></ColorSelect>
      ),
    };
    setCurrentFormPage(newState);
  };

  const handleColorSelectComplete = async () => {
    //Prepare devices
    await SHOWBOAT.SystemInformation.Load();

    //Call custom hook to get device preferences

    setCurrentStepNumber(2);

    let newState = {
      formPage: (
        <DeviceToggle
          onDeviceToggleBack={handleDeviceToggleBack}
          onDeviceToggleAccept={handleDeviceToggleAccept}
          onDeviceToggleDecline={handleDeviceToggleDecline}
          onDeviceToggleSkip={handleDeviceToggleSkip}
        />
      ),
    };

    setCurrentFormPage(newState);
  };

  const handleDeviceToggleAccept = (cameraAndMic: boolean) => {
    setCurrentStepNumber(4);

    noHasBeenClicked.current = false;

    micAndCameraRef.current = cameraAndMic;

    //Set back clicked to false
    backClickedRef.current = false;

    let newState = {
      formPage: (
        <CameraAndMicPage
          onCameraAndMicPageComplete={handleCameraAndMicPageComplete}
          onCameraAndMicPageBack={handleCameraAndMicPageBack}
          cameraAndMicChosen={micAndCameraRef.current}
          backClicked={backClickedRef.current}
        />
      ),
    };

    setCurrentFormPage(newState);
  };

  const handleDeviceToggleDecline = () => {
    setCurrentStepNumber(4);

    noHasBeenClicked.current = true;

    let newState = {
      formPage: (
        <SpeakersPage
          onSpeakersPageComplete={handleSpeakersPageComplete}
          onSpeakersPageBack={handleSpeakersPageBack}
        />
      ),
    };

    setCurrentFormPage(newState);
  };

  const handleDeviceToggleSkip = () => {
    noHasBeenClicked.current = true;

    let newState = {
      formPage: (
        <DeviceToggleWarning
          deviceToggleCameraAndMicClick={handleDeviceToggleAccept}
          deviceToggleWarningNextClick={handleDeviceToggleDecline}
        />
      ),
    };

    setCurrentFormPage(newState);
  };

  const handleCameraAndMicPageComplete = () => {
    //Show movement instructions page
    let newState = {
      formPage: (
        <MovementInstructionsPage
          onMovementPageComplete={handleMovementPageComplete}
          onMovementPageBack={handleMovementPageBack}
        />
      ),
    };

    setCurrentFormPage(newState);
  };

  const handleSpeakersPageComplete = () => {
    let newState = {
      formPage: (
        <MovementInstructionsPage
          onMovementPageComplete={handleMovementPageComplete}
          onMovementPageBack={handleMovementPageBack}
        />
      ),
    };

    setCurrentFormPage(newState);
  };

  const handleMovementPageComplete = () => {
    //Mark that intake has been completed
    localStorage.setItem(
      StringVariableHelper.LocalStorageProperties.HasCompletedIntakeData,
      "true"
    );

    //Call main app loading function from custom hook
    loadApp();
  };

  /* Back button handlers */
  const handleDeviceToggleBack = () => {
    setCurrentStepNumber(1);

    let newState: any = {
      formPage: (
        <ColorSelect
          onColorSelectBackClick={handleColorBackButtonClick}
          onColorSelectComplete={handleColorSelectComplete}
        ></ColorSelect>
      ),
    };

    setCurrentFormPage(newState);
  };

  /* Back button handlers */
  const handleColorBackButtonClick = () => {
    setCurrentStepNumber(0);

    let newState: any = {
      formPage: (
        <BioIntake onBioIntakeComplete={handleBioIntakeComplete}></BioIntake>
      ),
    };

    setCurrentFormPage(newState);
  };

  const handleCameraAndMicPageBack = () => {
    setCurrentStepNumber(2);

    let newState: any = {
      formPage: (
        <DeviceToggle
          onDeviceToggleBack={handleDeviceToggleBack}
          onDeviceToggleAccept={handleDeviceToggleAccept}
          onDeviceToggleDecline={handleDeviceToggleDecline}
          onDeviceToggleSkip={handleDeviceToggleSkip}
        />
      ),
    };

    setCurrentFormPage(newState);
  };

  const handleSpeakersPageBack = () => {
    //Set back clicked boolean to true
    backClickedRef.current = true;

    //If noHasBeenClicked, bring back to device toggle
    if (noHasBeenClicked.current) {
      setCurrentStepNumber(2);

      let newState: any = {
        formPage: (
          <DeviceToggle
            onDeviceToggleBack={handleDeviceToggleBack}
            onDeviceToggleAccept={handleDeviceToggleAccept}
            onDeviceToggleDecline={handleDeviceToggleDecline}
            onDeviceToggleSkip={handleDeviceToggleSkip}
          />
        ),
      };

      setCurrentFormPage(newState);
    } else {
      let newState: any = {
        formPage: (
          <CameraAndMicPage
            onCameraAndMicPageComplete={handleCameraAndMicPageComplete}
            onCameraAndMicPageBack={handleCameraAndMicPageBack}
            cameraAndMicChosen={micAndCameraRef.current}
            backClicked={backClickedRef.current}
          />
        ),
      };

      setCurrentFormPage(newState);
    }
  };

  const handleMovementPageBack = () => {
    //If no was clicked previously, return to speaker page. Otherwise, return to AV settings page
    let newState: any;

    //Set back clicked to true
    backClickedRef.current = true;

    if (noHasBeenClicked.current) {
      newState = {
        formPage: (
          <SpeakersPage
            onSpeakersPageComplete={handleSpeakersPageComplete}
            onSpeakersPageBack={handleSpeakersPageBack}
          />
        ),
      };
    } else {
      newState = {
        formPage: (
          <CameraAndMicPage
            onCameraAndMicPageComplete={handleCameraAndMicPageComplete}
            onCameraAndMicPageBack={handleCameraAndMicPageBack}
            cameraAndMicChosen={micAndCameraRef.current}
            backClicked={backClickedRef.current}
          />
        ),
      };
    }

    setCurrentFormPage(newState);
  };

  /**/

  //MaterialUI classes
  const classes = PageLayoutStyles();

  useEffect(() => {
    if (isIntakeCompleted) {
      //show loading screen
      SHOWBOAT.ShowboatLoader.OnShowLoadScreen.Raise();
      toggleFullScreenLoad(true);
    }
  }, [isIntakeCompleted]);

  useEffect(() => {
    if (isJoinCompleted) {
      noHasBeenClicked.current = false;

      //Execute post-join logic from custom hook
      executePostJoinLogic(props, styles);
    }
  }, [isJoinCompleted]);

  const handleShowLoadingScreen = () => {
    toggleFullScreenLoad(true);
  };

  const handleHideLoadingScreen = async () => {
    let aWindow: any = window;
    aWindow.babylonCanvas.focus();

    toggleFullScreenLoad(false);
  };

  //Inactive popup
  const handleInactivePopupClose = () => {
    toggleInactivePopup(false);
  };

  return (
    <React.Fragment>
      {!ServerHelper.useUIDevelopment && fullScreenLoad && (
        <FullScreenLoadingScreen />
      )}

      {(isJoinCompleted || bypassJoinCompleted) && (
        <AppContextProvider>
          <MessageContextProvider>
            <PresenterContextProvider>
              <DeviceContextProvider>
                <NearbyAttendeeContextProvider>
                  <RoomAvailabilityContextProvider>
                    <OneOnOneChatContextProvider>
                      {/* Only show this test div if we are in dev mode */}
                      {process.env.REACT_APP_API_STAGE === "dev" && (
                        <div className="testHolder" id="testHolder"></div>
                      )}

                      <div className="appHolder" id="fullscreenApp">
                        {/* Extra wrapper div to prevent Babylon crashing */}
                        <div>
                          <ControlCenter />
                          <RightBar />
                          {/* <TestCameraHolder /> */}
                          <NotificationsWrapper />
                          <PrivateMessagesWrapper />

                          {/* Render mobile controls if on mobile or tablet device */}
                          {isMobile && <MobileControls />}

                          <PhotoPopup />

                          {/* <TwoDOverlay /> */}

                          <TableButton />

                          {SHOWBOAT.LocalAvatarDataManager.avatarData.role ===
                            "presenter" && <MovePopup />}

                          {SHOWBOAT.LocalAvatarDataManager.avatarData.role ===
                            "presenter" && <PresenterMenu />}

                          {SHOWBOAT.LocalAvatarDataManager.avatarData.role ===
                            "presenter" && <PresenterToolsHolder />}

                          {SHOWBOAT.LocalAvatarDataManager.avatarData.role ===
                            "presenter" && <SlidePresenterTool />}

                          {inactivePopup && (
                            <InactivePopup
                              open={inactivePopup}
                              handlePopupClose={handleInactivePopupClose}
                            />
                          )}
                        </div>
                      </div>
                    </OneOnOneChatContextProvider>
                  </RoomAvailabilityContextProvider>
                </NearbyAttendeeContextProvider>
              </DeviceContextProvider>
            </PresenterContextProvider>
          </MessageContextProvider>
        </AppContextProvider>
      )}

      {!isIntakeCompleted && !bypassJoinCompleted && (
        <Paper classes={{ root: classes.intakeWrapper }} square>
          <div className={styles.intakeHolder}>
            {currentFormPage.formPage === null ? (
              <WelcomePage onWelcomePageComplete={handleWelcomePageComplete} />
            ) : (
              <React.Fragment>
                <IntakeHeader handleHomeClick={handleHomeClick} />
                <IntakeStepper currentStepNumber={currentStepNumber} />
                <AvatarHolder />

                <Paper className={classes.intakeContentHolder}>
                  {currentFormPage.formPage}
                </Paper>
              </React.Fragment>
            )}
          </div>
        </Paper>
      )}
    </React.Fragment>
  );
}
