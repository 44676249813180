import React, { useState, useEffect, useRef } from 'react';

export interface AttendeeContextType {
  attendeesInRoom: Array<SHOWBOAT.AvatarData>,
  setAttendeesInRoom: (attendeesInRoom: Array<SHOWBOAT.AvatarData>) => void,
  attendeesInEvent: Array<SHOWBOAT.AvatarData>,
  setAttendeesInEvent: (attendeesInEvent: Array<SHOWBOAT.AvatarData>) => void,
  previousTeleportRequestUserID: string,
  setPreviousTeleportRequestUserID: (previousTeleportRequestUserID: string) => void,
  contextRoomID: string,
  setContextRoomID: (roomID: string) => void,
  handleChangeRoomID: (roomID: string) => void,
  handleInitialEventAttendeesLoad: () => void,
}

export const AttendeeContext = React.createContext(null);

export const AttendeeContextProvider = (props) => {

  const [attendeesInRoom, setAttendeesInRoom] = useState([]);
  const [attendeesInEvent, setAttendeesInEvent] = useState([]);
  const [previousTeleportRequestUserID, setPreviousTeleportRequestUserID] = useState("");
  const [contextRoomID, setContextRoomID] = useState(SHOWBOAT.LocalAvatarDataManager.roomID);

  const inRoomRef = useRef([]);
  const inEventRef = useRef([]);

  const redrawTimeoutConnect = useRef(null);
  const dirtyTimeRefConnect = useRef(0);

  const redrawTimeoutDisconnect = useRef(null);
  const dirtyTimeRefDisconnect = useRef(0);

  /* Set initial attendees */
  const handleInitialEventAttendeesLoad = () => {
    inEventRef.current = SHOWBOAT.RemoteAvatarDataManager.getAllAvatarsInEvent(SHOWBOAT.LocalAvatarDataManager.userID);

    setAttendeesInEvent(inEventRef.current);
  };

  /* const handleInitialRoomAttendeesLoad = () => {
    
    inRoomRef.current = SHOWBOAT.RemoteAvatarDataManager.getAllAvatarsInRoom(SHOWBOAT.LocalAvatarDataManager.roomID, SHOWBOAT.LocalAvatarDataManager.userID);

    setAttendeesInRoom(inRoomRef.current);
  }; */

  const handleChangeRoomID = (roomID: string) => {

    setContextRoomID(roomID);

    inRoomRef.current = SHOWBOAT.RemoteAvatarDataManager.getAllAvatarsInRoom(roomID, SHOWBOAT.LocalAvatarDataManager.userID);

    setAttendeesInRoom(inRoomRef.current);

  }

  //Handle player connecting
  const handleOnRemotePlayerConnected = () => {

    //Check if 5 seconds have elapsed. If so, do nothing
    if (dirtyTimeRefConnect.current === 0) {
      //Mark dirty time now
      dirtyTimeRefConnect.current = Date.now();

      redrawTimeoutConnect.current = setTimeout(handleRedrawOnConnect, 1000);

    } else {

      //Check how much time has elapsed
      let elapsed = Date.now() - dirtyTimeRefConnect.current;

      if (elapsed < 5000) {
        //Cancel redraw timeout
        clearTimeout(redrawTimeoutConnect.current);

        //Reset redraw timeout for 1 second from now 
        redrawTimeoutConnect.current = setTimeout(handleRedrawOnConnect, 1000);
      } else {
        //Do nothing and let scheduled timeout run and redraw
      }

    }

  }

  const handleRedrawOnConnect = () => {
    inEventRef.current = SHOWBOAT.RemoteAvatarDataManager.getAllAvatarsInEvent(SHOWBOAT.LocalAvatarDataManager.userID);

    setAttendeesInEvent(inEventRef.current);

    dirtyTimeRefConnect.current = 0;
  }

  //Handle player disconnecting
  const handleOnRemotePlayerDisconnected = () => {

    /* 
        if (avatarData.roomID === SHOWBOAT.LocalAvatarDataManager.avatarData.roomID) {
    
          inRoomRef.current = SHOWBOAT.RemoteAvatarDataManager.getAllAvatarsInRoom(SHOWBOAT.LocalAvatarDataManager.avatarData.roomID, SHOWBOAT.LocalAvatarDataManager.userID);
          
          setAttendeesInRoom(inRoomRef.current);
    
        }  */

    //Check if 5 seconds have elapsed. If so, do nothing
    if (dirtyTimeRefDisconnect.current === 0) {
      //Mark dirty time now
      dirtyTimeRefDisconnect.current = Date.now();

      redrawTimeoutDisconnect.current = setTimeout(handleRedrawOnDisconnect, 500);

    } else {

      //Check how much time has elapsed
      let elapsed = Date.now() - dirtyTimeRefDisconnect.current;

      if (elapsed < 3000) {
        //Cancel redraw timeout
        clearTimeout(redrawTimeoutDisconnect.current);

        //Reset redraw timeout for 1 second from now 
        redrawTimeoutDisconnect.current = setTimeout(handleRedrawOnDisconnect, 500);
      } else {
        //Do nothing and let scheduled timeout run and redraw
      }

    }

  };

  const handleRedrawOnDisconnect = () => {

    inEventRef.current = SHOWBOAT.RemoteAvatarDataManager.getAllAvatarsInEvent(SHOWBOAT.LocalAvatarDataManager.userID);

    setAttendeesInEvent(inEventRef.current);

    dirtyTimeRefDisconnect.current = 0;
  }


  useEffect(() => {

    //player connects
    SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerConnected.Add(handleOnRemotePlayerConnected);
    //player disconnects
    SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Add(handleOnRemotePlayerDisconnected);


    //player changes room
    /*  SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerRoomChange.Add(handleOnRemotePlayerRoomChange); */

    //return cleanup function
    return function cleanup() {
      SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerConnected.Remove(handleOnRemotePlayerDisconnected);
      SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerDisconnected.Remove(handleOnRemotePlayerDisconnected);
      /*  SHOWBOAT.RemoteAvatarDataManager.OnRemotePlayerRoomChange.Remove(handleOnRemotePlayerRoomChange); */

      if (redrawTimeoutDisconnect.current) {
        clearTimeout(redrawTimeoutDisconnect.current);
      }

      if (redrawTimeoutConnect.current) {
        clearTimeout(redrawTimeoutConnect.current);
      }
    }
  }, []);


  const attendeesProviderValue: AttendeeContextType = {
    attendeesInRoom,
    setAttendeesInRoom,
    attendeesInEvent,
    setAttendeesInEvent,
    previousTeleportRequestUserID,
    setPreviousTeleportRequestUserID,
    contextRoomID,
    setContextRoomID,
    handleChangeRoomID,
    handleInitialEventAttendeesLoad,
  }

  return (
    <AttendeeContext.Provider
      value={attendeesProviderValue}
    >
      {props.children}
    </AttendeeContext.Provider>
  )
}