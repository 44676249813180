import React, {
  ReactElement,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import { isMobile } from "react-device-detect";
import {
  Typography,
  FormControl,
  Select,
  Button,
  MenuItem,
  Paper,
} from "@material-ui/core";
import MicIcon from "@material-ui/icons/Mic";
import MicMuteIcon from "@material-ui/icons/MicOff";
import CameraIcon from "@material-ui/icons/Videocam";
import CameraMuteIcon from "@material-ui/icons/VideocamOff";
import WarningIcon from "@material-ui/icons/Warning";

import MicTestModule from "../../ui/components/Popups/SettingsPopup/MicTestModule/MicTestModule";
import { IntakeContext, IntakeContextType } from "../../context/IntakeContext";

import styles from "./CameraAndMicSettings.module.css";
import { CameraAndMicSettingsStyles } from "./CameraAndMicSettingsStyles";
import { ChangeEvent } from "react";
import { StringVariableHelper } from "../../Utilities/StringVariableHelper";
import useGetDevicePreferences from "../../hooks/useGetDevicePreferences";

interface Props {
  intake: boolean;
  backClicked?: boolean;
  cameraAndMicChosen: boolean;
  toggleShowEchoWarning?: (hasEchoPotentialBool: boolean) => void;
  onCameraAndMicSettingsBack?: () => void;
  onCameraAndMicSettingsComplete?: () => void;
}

let longErrorMessage =
  "Unable to access devices. Please close any other software that might be using your camera or mic.";

export default function CameraAndMicSettings(props: Props): ReactElement {
  //Context
  const { toggleDisableButtons, disableButtons }: IntakeContextType =
    useContext(IntakeContext);

  const {
    getDevicePreferences,
    checkCachedPreferredCameraState,
    checkCachedPreferredMicState,
    storeCachedPreferredCamState,
    storeCachedPreferredMicState,
  } = useGetDevicePreferences();

  //State
  const [useMic, toggleUseMic] = useState(false);
  const [useCamera, toggleUseCamera] = useState(false);
  const [deviceError, toggleDeviceError] = useState(false);

  const [deviceErrorStore, setDeviceErrorStore] = useState([]);
  const [isTestingSpeakers, toggleIsTestingSpeakers] = useState(false);

  const [currentMic, setCurrentMic] = useState(null);
  const [currentCamera, setCurrentCamera] = useState(null);
  const [currentSpeaker, setCurrentSpeaker] = useState(undefined);

  const [currentMicList, setCurrentMicList] = useState([]);
  const [currentCameraList, setCurrentCameraList] = useState([]);
  const [currentSpeakerList, setCurrentSpeakerList] = useState([]);

  const [devicesHaveBeenLoaded, setDevicesHaveBeenLoaded] = useState(false);

  const [removeDisabledColor, toggleRemoveDisabledColor] = useState(false);

  const [showEchoWarningState, toggleShowEchoWarningState] = useState(false);

  //Refs
  const firstUpdate = useRef(true);
  const currentErrorStateRef = useRef(false);

  //Initial component mount
  useEffect(() => {
    //Check for cached devices
    const getCachedDevices = async () => {
      await getDevicePreferences();
    };

    if (props.intake) {
      getCachedDevices();
    }

    //Register for devices getting plugged or unplugged
    const onDeviceChange = () => {
      //TODO:... check if our device got unplugged

      refreshDevices();
    };

    //Add an event listener for available devices changing
    SHOWBOAT.SystemInformation.OnDeviceListChanged.Add(onDeviceChange);

    //Listen for when media operations are done processing to unlock buttons
    const handleMediaOperationComplete = () => {
      toggleDisableButtons(false);
    };
    SHOWBOAT.StreamingUserMedia.OnMediaOperationComplete.Add(
      handleMediaOperationComplete
    );

    //Listen for the camera starting
    const OnCameraStarted = () => {
      currentErrorStateRef.current = false;

      refreshDevices()
        .then(() => {
          //show camera button on
          toggleUseCamera(true);

          //update the current camera listed
          updateCurrentDevices(true);

          //clear any previous errors
          if (currentErrorStateRef.current === false) {
            toggleDeviceError(false);
          }
        })
        .catch(() => {});
    };
    SHOWBOAT.StreamingUserMedia.OnCameraStarted.Add(OnCameraStarted);

    //Listen for the mic starting
    const OnMicrophoneStarted = () => {
      currentErrorStateRef.current = false;

      refreshDevices()
        .then(() => {
          //turn the mic button on
          toggleUseMic(true);

          //update the currently displayed mic
          updateCurrentDevices(true);

          //clear any previous errors
          if (currentErrorStateRef.current === false) {
            toggleDeviceError(false);
          }
        })
        .catch(() => {});
    };
    SHOWBOAT.StreamingUserMedia.OnMicrophoneStarted.Add(OnMicrophoneStarted);

    //Listen for camera stopping
    const OnCameraStopped = () => {
      toggleUseCamera(false);
      //setAudioLevel(0);
    };
    SHOWBOAT.StreamingUserMedia.OnCameraStopped.Add(OnCameraStopped);

    //Listen for microphone stopping
    const OnMicrophoneStopped = () => {
      toggleUseMic(false);
    };
    SHOWBOAT.StreamingUserMedia.OnMicrophoneStopped.Add(OnMicrophoneStopped);

    //Listen for changes to the current camera
    const OnCameraDeviceChanged = () => {
      updateCurrentCamera(SHOWBOAT.StreamingUserMedia.isCameraRunning());
    };
    SHOWBOAT.StreamingUserMedia.OnCameraDeviceChanged.Add(
      OnCameraDeviceChanged
    );

    //Listen for changes to the current mic
    const OnMicrophoneDeviceChanged = () => {
      updateCurrentMicrophone(
        SHOWBOAT.StreamingUserMedia.isMicrophoneRunning()
      );
    };
    SHOWBOAT.StreamingUserMedia.OnMicrophoneDeviceChanged.Add(
      OnMicrophoneDeviceChanged
    );

    //Event listener for AV Errors
    const OnAVMediaError = (errorMsg: string) => {
      currentErrorStateRef.current = true;

      toggleDeviceError(true);

      let readableErrorMsg;

      //Set up correct readable error message based on errorMsg string
      switch (errorMsg) {
        case "SecurityError":
        case "OverconstrainedError":
        case "AbortError":
        case "TypeError":
          readableErrorMsg = "An error occurred starting devices";
          break;
        case "NotAllowedError":
          readableErrorMsg = "Device permissions have not been granted";
          break;
        case "NotFoundError":
          readableErrorMsg = "No devices found to start";
          break;
        case "NotReadableError":
          readableErrorMsg =
            "Unable to access devices. Please close any other software that might be using your camera or mic.";
          break;
        default:
          readableErrorMsg = "An error occurred starting devices";
      }

      setDeviceErrorStore([readableErrorMsg]);
    };
    SHOWBOAT.StreamingUserMedia.OnAVMediaError.Add(OnAVMediaError);

    //If not in intake, check for initial error
    if (!props.intake) {
      if (SHOWBOAT.StreamingUserMedia.lastErrorReason !== "") {
        SHOWBOAT.Logger.Log(
          "RAISING ERROR",
          SHOWBOAT.StreamingUserMedia.lastErrorReason
        );

        //We have an existing error
        SHOWBOAT.StreamingUserMedia.OnAVMediaError.Raise(
          SHOWBOAT.StreamingUserMedia.lastErrorReason
        );
      }
    }

    //Update our device list
    refreshDevices()
      .then(() => {
        doInitialSetup();
      })
      .catch(() => {});

    return function cleanup() {
      //Remove listeners
      SHOWBOAT.StreamingUserMedia.OnCameraStarted.Remove(OnCameraStarted);
      SHOWBOAT.StreamingUserMedia.OnMicrophoneStarted.Remove(
        OnMicrophoneStarted
      );
      SHOWBOAT.StreamingUserMedia.OnCameraStopped.Remove(OnCameraStopped);
      SHOWBOAT.StreamingUserMedia.OnMicrophoneStopped.Remove(
        OnMicrophoneStopped
      );
      SHOWBOAT.StreamingUserMedia.OnCameraDeviceChanged.Remove(
        OnCameraDeviceChanged
      );
      SHOWBOAT.StreamingUserMedia.OnMicrophoneDeviceChanged.Remove(
        OnMicrophoneDeviceChanged
      );
      SHOWBOAT.StreamingUserMedia.OnAVMediaError.Remove(OnAVMediaError);
      SHOWBOAT.SystemInformation.OnDeviceListChanged.Remove(onDeviceChange);
    };
  }, []);

  //Effect for camera preview
  useEffect(() => {
    //Make sure we are not in intake
    if (!props.intake) {
      let cameraPreviewVideo = document.getElementById(
        "cameraPreviewVideo"
      ) as HTMLVideoElement;

      //Make sure cameraPreviewVideo is not null
      if (cameraPreviewVideo !== null) {
        //If useCamera is on, set the src and correct size
        if (useCamera) {
          let cameraHeight = SHOWBOAT.StreamingUserMedia.actualCameraHeight;
          let cameraWidth = SHOWBOAT.StreamingUserMedia.actualCameraWidth;

          //Multiply actual width by 125, then divide by actual height to get displayed width
          let displayedWidth = (cameraWidth * 125) / cameraHeight;

          //Set width of video holder div
          let videoHolderPopup = document.getElementById("videoHolderPopup");

          if (videoHolderPopup !== null) {
            //Make sure video holder is showing
            videoHolderPopup.style.display = "inline-block";

            videoHolderPopup.style.width = `${displayedWidth}px`;
          }

          //Set video src
          cameraPreviewVideo.srcObject =
            SHOWBOAT.StreamingUserMedia.getCameraStream();

          //Set video volume to 0
          cameraPreviewVideo.volume = 0;

          //Play video
          cameraPreviewVideo
            .play()
            .then((response) => {})
            .catch((error) => {
              SHOWBOAT.Logger.Error(
                "Error playing camera preview video",
                error
              );
            });
        } else {
          //Hide video holder
          let videoHolderPopup: HTMLElement =
            document.getElementById("videoHolderPopup");

          if (videoHolderPopup !== null) {
            videoHolderPopup.style.display = "none";
          }

          //Pause the video
          cameraPreviewVideo.pause();
        }
      }
    }
  }, [useCamera]);

  //Check for echo when devices change
  useEffect(() => {
    let hasEchoPotentialBool: boolean = hasEchoPotential();

    //Send bool to props toggle, and update local state

    //If we are in intake, send to parent component
    if (props.intake) {
      props.toggleShowEchoWarning(hasEchoPotentialBool);
    }

    toggleShowEchoWarningState(hasEchoPotentialBool);
  }, [currentMic, currentSpeaker]);

  //HANDLE GETTING UPDATED DEVICE LISTS
  const refreshDevices = async () => {
    try {
      //Check if we need to retry getting device names
      if (
        !SHOWBOAT.SystemInformation.HaveCameraNames ||
        !SHOWBOAT.SystemInformation.HaveMicrophoneNames
      ) {
        await SHOWBOAT.SystemInformation.Load();
      }

      //Set the current device lists
      setCurrentMicList(SHOWBOAT.SystemInformation.AudioInputDevices);
      setCurrentCameraList(SHOWBOAT.SystemInformation.VideoInputDevices);
      setCurrentSpeakerList(SHOWBOAT.SystemInformation.AudioOutputDevices);

      //Check if we need to do an initial setup
      //if(!initialSetupComplete.current){
      //  initialSetupComplete.current = true;
      //  await doInitialSetup();
      // }
    } catch (e) {
      SHOWBOAT.Logger.Error(e);
    }
  };

  const doInitialSetup = async () => {
    //Check if the component is being used on initial intake or later once the app is running
    if (props.intake) {
      //Check if we got here via a back click, or via new settings
      if (props.backClicked) {
        //If back was clicked and we are using a camera, remove disabled button color
        if (SHOWBOAT.StreamingUserMedia.isCameraRunning()) {
          toggleRemoveDisabledColor(true);
        }

        //Set the toggle state to match the current devices in use
        toggleUseCamera(SHOWBOAT.StreamingUserMedia.isCameraRunning());
        toggleUseMic(SHOWBOAT.StreamingUserMedia.isMicrophoneRunning());

        //Update the dropdowns with users current selections
        updateCurrentDevices();
      } else {
        //Try to start the camera/mic

        //Check which options the user selected before coming to this page
        //also ensure the system has the necessary device type
        let initialCameraState: boolean =
          props.cameraAndMicChosen &&
          SHOWBOAT.SystemInformation.HasWebCamera &&
          checkCachedPreferredCameraState(props);
        let initialMicrophoneState: boolean =
          true &&
          SHOWBOAT.SystemInformation.HasMicrophone &&
          checkCachedPreferredMicState(props);

        //Ensure we have a device to start
        if (initialCameraState || initialMicrophoneState) {
          //Disable our toggle buttons until device starts are complete
          toggleDisableButtons(true);

          //Check for current cached cam
          await checkCurrentCachedCam();

          //Check for current cached mic
          await checkCurrentCachedMic();

          //Check for current cached speaker
          checkCurrentCachedSpeaker();

          //Ensure buttons are disabled
          toggleDisableButtons(true);

          //Ensure cached values are set correctly
          storeCachedPreferredCamState(initialCameraState);
          storeCachedPreferredMicState(initialMicrophoneState);

          //Start the devices
          await SHOWBOAT.StreamingUserMedia.SetDeviceStates(
            initialCameraState,
            initialMicrophoneState
          );

          //Update our current device selection
          updateCurrentMicrophone();
          updateCurrentCamera();
        } else {
          //We don't have any devices
          //TODO:

          setDeviceErrorStore(["No camera or microphone has been detected"]);
        }
      }
    } else {
      //We are in the app in a settings view

      //Set the toggle state to match the current devices in use
      toggleUseCamera(SHOWBOAT.StreamingUserMedia.isCameraRunning());
      toggleUseMic(SHOWBOAT.StreamingUserMedia.isMicrophoneRunning());

      //Update the dropdowns with users current selections
      updateCurrentDevices();
    }
  };

  const checkCurrentCachedCam = async () => {
    let currentCachedCam =
      SHOWBOAT.StreamingUserMedia.getPrefferedCameraDevice();
    if (!SHOWBOAT.SystemInformation.getCameraName(currentCachedCam)) {
      await SHOWBOAT.StreamingUserMedia.SetCameraDeviceID("");
    }
  };

  const checkCurrentCachedMic = async () => {
    let currentCachedMic =
      SHOWBOAT.StreamingUserMedia.getPrefferedMicrophoneDevice();
    if (!SHOWBOAT.SystemInformation.getMicrophoneName(currentCachedMic)) {
      await SHOWBOAT.StreamingUserMedia.SetMicrophoneDeviceID("");
    }
  };

  const checkCurrentCachedSpeaker = () => {
    let currentCachedSpeaker =
      SHOWBOAT.StreamingUserMedia.getCurrentSpeakerDevice();
    if (!SHOWBOAT.SystemInformation.getSpeakerName(currentCachedSpeaker)) {
      SHOWBOAT.StreamingUserMedia.SetSpeakerDevice("");
    }
  };

  const updateCurrentDevices = (useCurrent: boolean = false) => {
    updateCurrentMicrophone(useCurrent);
    updateCurrentCamera(useCurrent);
    updateCurrentSpeaker();
  };

  const updateCurrentMicrophone = (useCurrent: boolean = false) => {
    let mediaDeviceID: string = useCurrent
      ? SHOWBOAT.StreamingUserMedia.getCurrentMicrophoneDevice()
      : SHOWBOAT.StreamingUserMedia.getPrefferedMicrophoneDevice();
    if (mediaDeviceID && mediaDeviceID.length > 0) {
      if (SHOWBOAT.SystemInformation.AudioInputDevices) {
        for (
          let i = 0;
          i < SHOWBOAT.SystemInformation.AudioInputDevices.length;
          ++i
        ) {
          if (
            SHOWBOAT.SystemInformation.AudioInputDevices[i].deviceId ==
            mediaDeviceID
          ) {
            //Set value for preferred microphone in localStorage
            localStorage.setItem(
              StringVariableHelper.LocalStorageProperties.PreferredMicID,
              SHOWBOAT.SystemInformation.AudioInputDevices[i].deviceId
            );

            setCurrentMic(SHOWBOAT.SystemInformation.AudioInputDevices[i]);
            break;
          }
        }
      }
    }
  };

  const updateCurrentCamera = (useCurrent: boolean = false) => {
    let mediaDeviceID: string = useCurrent
      ? SHOWBOAT.StreamingUserMedia.getCurrentCameraDevice()
      : SHOWBOAT.StreamingUserMedia.getPrefferedCameraDevice();
    if (mediaDeviceID && mediaDeviceID.length > 0) {
      if (SHOWBOAT.SystemInformation.VideoInputDevices) {
        for (
          let i = 0;
          i < SHOWBOAT.SystemInformation.VideoInputDevices.length;
          ++i
        ) {
          if (
            SHOWBOAT.SystemInformation.VideoInputDevices[i].deviceId ==
            mediaDeviceID
          ) {
            //Set initial value for camera preference in localStorage
            localStorage.setItem(
              StringVariableHelper.LocalStorageProperties.PreferredCameraID,
              SHOWBOAT.SystemInformation.VideoInputDevices[i].deviceId
            );

            setCurrentCamera(SHOWBOAT.SystemInformation.VideoInputDevices[i]);
            break;
          }
        }
      }
    }
  };

  const updateCurrentSpeaker = () => {
    if (
      SHOWBOAT.SystemInformation.HasSpeakers &&
      SHOWBOAT.SystemInformation.AudioOutputDevices &&
      SHOWBOAT.SystemInformation.AudioOutputDevices.length > 0
    ) {
      let mediaDeviceID: string =
        SHOWBOAT.StreamingUserMedia.getCurrentSpeakerDevice();

      if (mediaDeviceID && mediaDeviceID.length > 0) {
        //locate the current speaker
        for (
          let i = 0;
          i < SHOWBOAT.SystemInformation.AudioOutputDevices.length;
          ++i
        ) {
          if (
            SHOWBOAT.SystemInformation.AudioOutputDevices[i].deviceId ==
            mediaDeviceID
          ) {
            //set initial value in local storage for preferredSpeaker
            localStorage.setItem(
              StringVariableHelper.LocalStorageProperties.PreferredSpeakerID,
              SHOWBOAT.SystemInformation.AudioOutputDevices[i].deviceId
            );

            setCurrentSpeaker(SHOWBOAT.SystemInformation.AudioOutputDevices[i]);
            break;
          }
        }
      } else {
        //try and assign the system default as the current speaker
        for (
          let i = 0;
          i < SHOWBOAT.SystemInformation.AudioOutputDevices.length;
          ++i
        ) {
          if (
            SHOWBOAT.SystemInformation.AudioOutputDevices[i].deviceId &&
            SHOWBOAT.SystemInformation.AudioOutputDevices[
              i
            ].deviceId.toLowerCase() === "default"
          ) {
            SHOWBOAT.StreamingUserMedia.SetSpeakerDevice(
              SHOWBOAT.SystemInformation.AudioOutputDevices[i].deviceId
            );
            setCurrentSpeaker(SHOWBOAT.SystemInformation.AudioOutputDevices[i]);
            break;
          }
        }
      }
    }
  };

  const onCameraDeviceChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) return;
    if (currentCameraList && currentCameraList.length > 0) {
      for (let i = 0; i < currentCameraList.length; ++i) {
        if (currentCameraList[i].deviceId == event.target.value) {
          toggleDisableButtons(true);
          SHOWBOAT.StreamingUserMedia.SetCameraDeviceID(event.target.value);

          //Change preferred camera device in local storage
          localStorage.setItem(
            StringVariableHelper.LocalStorageProperties.PreferredCameraID,
            currentCameraList[i].deviceId
          );
          setCurrentCamera(currentCameraList[i]);
          break;
        }
      }
    }
  };

  const onAudioDeviceChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) return;
    if (currentMicList && currentMicList.length > 0) {
      for (let i = 0; i < currentMicList.length; ++i) {
        if (currentMicList[i].deviceId == event.target.value) {
          toggleDisableButtons(true);
          SHOWBOAT.StreamingUserMedia.SetMicrophoneDeviceID(event.target.value);

          //Set preferred microphone value in local storage
          localStorage.setItem(
            StringVariableHelper.LocalStorageProperties.PreferredMicID,
            currentMicList[i].deviceId
          );

          setCurrentMic(currentMicList[i]);
          break;
        }
      }
    }
  };

  const onSpeakerChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) return;
    if (currentSpeakerList && currentSpeakerList.length > 0) {
      for (let i = 0; i < currentSpeakerList.length; ++i) {
        if (currentSpeakerList[i].id == event.target.value) {
          SHOWBOAT.StreamingUserMedia.SetSpeakerDevice(event.target.value);

          //Set preferred speaker value in local storage
          localStorage.setItem(
            StringVariableHelper.LocalStorageProperties.PreferredSpeakerID,
            currentSpeakerList[i].deviceId
          );

          setCurrentSpeaker(currentSpeakerList[i]);
          break;
        }
      }
    }
  };

  useEffect(() => {
    let testAudio: any;

    testAudio = document.getElementById("camAndMicTestAudio");

    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (typeof testAudio.sinkId !== "undefined") {
      testAudio
        .setSinkId(currentSpeaker.deviceId)
        .then(() => {})
        .catch((ex) => {
          SHOWBOAT.Logger.Log("Error setting sink ID on test audio");
          SHOWBOAT.Logger.Log(ex);
        });
    } else {
      SHOWBOAT.Logger.Log("Browser does not support changing speakers");
    }
  }, [currentSpeaker]);

  const toggleMuteCamera = () => {
    if (SHOWBOAT.StreamingUserMedia.isOperationInProgress()) return;

    //Store preference in Local Storage
    storeCachedPreferredCamState(!useCamera);

    //Toggle state override for grey color
    toggleRemoveDisabledColor(true);

    toggleDisableButtons(true);
    SHOWBOAT.StreamingUserMedia.SetDeviceStates(!useCamera, useMic);
  };

  const toggleMuteMic = () => {
    if (SHOWBOAT.StreamingUserMedia.isOperationInProgress()) return;

    //Store preference in Local Storage
    storeCachedPreferredMicState(!useMic);

    toggleDisableButtons(true);
    SHOWBOAT.StreamingUserMedia.SetDeviceStates(useCamera, !useMic);
  };

  const handleStopTestAudio = () => {
    let testAudio: any;

    testAudio = document.getElementById("camAndMicTestAudio");

    toggleIsTestingSpeakers(false);

    testAudio.pause();
    testAudio.currentTime = 0;
  };

  const handleTestSpeakersButtonClick = () => {
    let testAudio: any;

    testAudio = document.getElementById("camAndMicTestAudio");

    toggleIsTestingSpeakers(true);

    testAudio.play();

    testAudio.onended = () => {
      toggleIsTestingSpeakers(false);

      testAudio.pause();
      testAudio.currentTime = 0;
    };
  };

  const handleBackButtonClick = () => {
    SHOWBOAT.StreamingUserMedia.SetDeviceStates(false, false);
    //SHOWBOAT.AVController.setUserMadeSpeakerSelections(false);

    props.onCameraAndMicSettingsBack();
  };

  const handleNextButtonClick = () => {
    props.onCameraAndMicSettingsComplete();
  };

  const hasEchoPotential = (): boolean => {
    if (
      !currentMic ||
      !currentSpeaker ||
      !currentMic.groupId ||
      !currentSpeaker.groupId
    ) {
      //we don't really know...
      return false;
    }

    return currentMic.groupId != currentSpeaker.groupId;
  };

  //MICROPHONE
  let currentAudioDeviceID: string = currentMic ? currentMic.deviceId : "";
  let audioInputMenuItems: JSX.Element[] = [];

  if (
    currentMicList &&
    currentMicList.length > 0 &&
    currentMicList[0].deviceId
  ) {
    audioInputMenuItems = currentMicList.map((mediaDevice: MediaDeviceInfo) => {
      return (
        <MenuItem
          key={`${mediaDevice.deviceId} ${mediaDevice.label}`}
          value={mediaDevice.deviceId}
        >
          {mediaDevice.label}
        </MenuItem>
      );
    });
  } else {
    currentAudioDeviceID = "Unknown";
    audioInputMenuItems.push(
      <MenuItem key={"Unknown"} value={"Unknown"}>
        {"No microphones found"}
      </MenuItem>
    );
  }

  //CAMERA
  let currentVideoDeviceID: string = currentCamera ? currentCamera.id : "";
  let cameraInputMenuItems: JSX.Element[] = [];

  if (
    currentCameraList &&
    currentCameraList.length > 0 &&
    currentCameraList[0].deviceId
  ) {
    cameraInputMenuItems = currentCameraList.map(
      (mediaDevice: MediaDeviceInfo) => {
        return (
          <MenuItem
            key={`${mediaDevice.deviceId} ${mediaDevice.label}`}
            value={mediaDevice.deviceId}
          >
            {mediaDevice.label}
          </MenuItem>
        );
      }
    );
  } else {
    currentVideoDeviceID = "Unknown";
    cameraInputMenuItems.push(
      <MenuItem key={"Unknown"} value={"Unknown"}>
        {"No cameras found"}
      </MenuItem>
    );
  }

  //SPEAKERS
  let currentSpeakerDeviceID: string = currentSpeaker
    ? currentSpeaker.deviceId
    : "";
  let speakerMenuItems: JSX.Element[] = [];

  if (
    currentSpeakerList &&
    currentSpeakerList.length > 0 &&
    currentSpeakerList[0].deviceId
  ) {
    speakerMenuItems = currentSpeakerList.map((mediaDevice: any) => {
      return (
        <MenuItem
          key={`${mediaDevice.deviceId} ${mediaDevice.label}`}
          value={mediaDevice.deviceId}
        >
          {mediaDevice.label}
        </MenuItem>
      );
    });
  } else {
    currentSpeakerDeviceID = "Unknown";
    speakerMenuItems.push(
      <MenuItem key={"Unknown"} value={"Unknown"}>
        {"Browser default speaker"}
      </MenuItem>
    );
  }

  //materialUI classes
  const classes = CameraAndMicSettingsStyles();

  //classes based on if is intake or not
  let cameraMuteClasses;
  props.intake
    ? (cameraMuteClasses = `${classes.muteCameraButton}`)
    : (cameraMuteClasses = `${classes.muteCameraButton} ${classes.muteCameraButtonPopup}`);

  //Check cameraAndMicChosen prop to change initial button color
  if (!props.cameraAndMicChosen && !removeDisabledColor) {
    cameraMuteClasses = `${cameraMuteClasses} ${classes.muteCameraButtonJustMic}`;
  }

  let cameraSelectClasses;
  props.intake
    ? (cameraSelectClasses = `${styles.cameraSelectHolder}`)
    : (cameraSelectClasses = `${styles.cameraSelectHolder} ${styles.cameraSelectHolderPopup}`);

  let micMuteClasses;
  props.intake
    ? (micMuteClasses = `${classes.muteMicButton}`)
    : (micMuteClasses = `${classes.muteMicButton} ${classes.muteMicButtonPopup}`);

  let micSelectClasses;
  props.intake
    ? (micSelectClasses = `${styles.micSelectHolder}`)
    : (micSelectClasses = `${styles.micSelectHolder} ${styles.micSelectHolderPopup}`);

  if (!props.intake) {
    return (
      <React.Fragment>
        {deviceError && (
          <div className={styles.sidebarErrorHolder}>
            <WarningIcon
              className={
                deviceErrorStore[0] === longErrorMessage
                  ? `${classes.warningIcon} ${classes.warningIconSidebar} ${classes.warningIconSidebarLong}`
                  : `${classes.warningIcon} ${classes.warningIconSidebar}`
              }
            />
            {deviceErrorStore.map((error, i) => {
              return (
                <Typography
                  variant="body1"
                  className={
                    error === longErrorMessage
                      ? `${classes.errorSidebar} ${classes.errorSidebarLong}`
                      : classes.errorSidebar
                  }
                >
                  {error}
                </Typography>
              );
            })}
          </div>
        )}
        <Paper
          className={`${classes.avSettingsHolder} ${classes.cameraSettingsHolder}`}
        >
          <Typography variant="h2" className={classes.avHeaderSettings}>
            VIDEO
          </Typography>

          <FormControl classes={{ root: classes.deviceSelectSettings }}>
            <Select
              value={currentVideoDeviceID}
              onChange={onCameraDeviceChange}
            >
              {cameraInputMenuItems}
            </Select>
          </FormControl>
        </Paper>

        <Paper
          className={`${classes.avSettingsHolder} ${classes.micSettingsHolder}`}
        >
          <Typography variant="h2" className={classes.avHeaderSettings}>
            MICROPHONE
          </Typography>

          <MicTestModule intake={false} useMic={useMic} />

          <FormControl classes={{ root: classes.deviceSelectSettings }}>
            <Select value={currentAudioDeviceID} onChange={onAudioDeviceChange}>
              {audioInputMenuItems}
            </Select>
          </FormControl>
        </Paper>

        {showEchoWarningState && (
          <React.Fragment>
            <WarningIcon
              className={`${classes.warningIcon} ${classes.warningIconSidebar}`}
            />

            <Typography
              variant="body1"
              className={`${classes.echoWarning} ${classes.echoWarningSidebar}`}
            >
              Using different devices as your microphone and speaker can cause
              echo. Please update your settings before continuing.
            </Typography>
          </React.Fragment>
        )}

        <Paper
          className={`${classes.avSettingsHolder} ${classes.speakersSettingsHolder}`}
        >
          <Typography variant="h2" className={classes.avHeaderSettings}>
            SPEAKERS
          </Typography>

          <FormControl
            classes={{ root: classes.deviceSelectSettings }}
            /* disabled={!SHOWBOAT.StreamingUserMedia.IsSetSinkIDSupported} */
          >
            <Select value={currentSpeakerDeviceID} onChange={onSpeakerChange}>
              {speakerMenuItems}
            </Select>
          </FormControl>

          {isTestingSpeakers ? (
            <Button
              onClick={handleStopTestAudio}
              className={`${classes.testButtonSidebar} ${classes.testButtonSidebarPlaying}`}
              variant="contained"
            >
              PLAYING...
            </Button>
          ) : (
            <Button
              onClick={handleTestSpeakersButtonClick}
              className={classes.testButtonSidebar}
              variant="contained"
            >
              TEST
            </Button>
          )}
        </Paper>

        <audio
          id="camAndMicTestAudio"
          src="assets/audio/speaker-test.wav"
        ></audio>
      </React.Fragment>
    );
  } else {
    return (
      <div
        className={styles.cameraAndMicSettingsHolder}
        style={{ top: props.intake ? 0 : -50 }}
      >
        {props.intake && (
          <Typography classes={{ root: classes.avHeader }} variant="h1">
            AV Settings
          </Typography>
        )}

        <div
          className={
            props.intake
              ? styles.errorMsgHolder
              : `${styles.errorMsgHolder} ${styles.errorMsgHolderPopup}`
          }
        >
          {deviceError &&
            deviceErrorStore.map((error, i) => {
              return (
                <Typography
                  color="error"
                  key={error}
                  classes={{ root: classes.deviceErrorMsg }}
                >
                  {error}
                </Typography>
              );
            })}
        </div>

        <div className={cameraSelectClasses}>
          <Typography
            classes={{ root: classes.cameraSelectHeader }}
            variant="h2"
          >
            Camera
          </Typography>

          <FormControl classes={{ root: classes.cameraSelectHolder }}>
            <Select
              value={currentVideoDeviceID}
              onChange={onCameraDeviceChange}
            >
              {cameraInputMenuItems}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            classes={{ root: cameraMuteClasses }}
            color="primary"
            onClick={toggleMuteCamera}
          >
            {useCamera ? (
              <CameraIcon classes={{ root: classes.cameraIcon }} />
            ) : (
              <CameraMuteIcon classes={{ root: classes.cameraIcon }} />
            )}
          </Button>
        </div>

        <div className={micSelectClasses}>
          <Typography classes={{ root: classes.micSelectHeader }} variant="h2">
            Microphone
          </Typography>
          <FormControl classes={{ root: classes.micSelectHolder }}>
            <Select value={currentAudioDeviceID} onChange={onAudioDeviceChange}>
              {audioInputMenuItems}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            classes={{ root: micMuteClasses }}
            color="primary"
            onClick={toggleMuteMic}
          >
            {useMic ? (
              <MicIcon classes={{ root: classes.micIcon }} />
            ) : (
              <MicMuteIcon classes={{ root: classes.micIcon }} />
            )}
          </Button>
        </div>

        <div
          className={
            props.intake
              ? styles.testMicHolder
              : `${styles.testMicHolder} ${styles.testMicHolderPopup}`
          }
        >
          <MicTestModule useMic={useMic} intake={props.intake} />
        </div>

        <div className={styles.speakerSelectHolder}>
          <Typography
            classes={{ root: classes.cameraSelectHeader }}
            variant="h2"
          >
            Speakers
          </Typography>

          {isTestingSpeakers ? (
            <Button
              onClick={handleStopTestAudio}
              className={`${classes.testSpeakerButtonIntake} ${classes.testSpeakerButtonIntakePlaying}`}
              variant="contained"
            >
              PLAYING...
            </Button>
          ) : (
            <Button
              onClick={handleTestSpeakersButtonClick}
              className={classes.testSpeakerButtonIntake}
              variant="contained"
            >
              TEST
            </Button>
          )}

          {SHOWBOAT.SystemInformation.IsSetSinkIdSupported && (
            <FormControl classes={{ root: classes.speakerSelectHolder }}>
              <Select value={currentSpeakerDeviceID} onChange={onSpeakerChange}>
                {speakerMenuItems}
              </Select>
            </FormControl>
          )}
        </div>

        {showEchoWarningState && (
          <React.Fragment>
            <WarningIcon
              className={
                !SHOWBOAT.SystemInformation.IsSetSinkIdSupported
                  ? `${classes.warningIcon} ${classes.warningIconNonChrome}`
                  : classes.warningIcon
              }
            />

            <Typography
              variant="body1"
              className={
                !SHOWBOAT.SystemInformation.IsSetSinkIdSupported
                  ? `${classes.echoWarning} ${classes.echoWarningNonChrome}`
                  : classes.echoWarning
              }
            >
              Using different devices as your microphone and speaker can cause
              echo. Please update your settings before continuing.
            </Typography>
          </React.Fragment>
        )}

        <div className={styles.buttonHolder}>
          <Button
            disabled={disableButtons}
            classes={{ root: classes.backButton }}
            onClick={handleBackButtonClick}
            variant="text"
          >
            BACK
          </Button>

          <Button
            disabled={
              disableButtons ||
              (isMobile &&
                !SHOWBOAT.StreamingUserMedia.isMicrophoneRunning() &&
                !SHOWBOAT.StreamingUserMedia.isCameraRunning())
            }
            classes={{ root: classes.nextButton }}
            onClick={handleNextButtonClick}
            variant="contained"
          >
            NEXT
          </Button>
        </div>

        <audio
          id="camAndMicTestAudio"
          src="assets/audio/speaker-test.wav"
        ></audio>
      </div>
    );
  }
}
