import { IconButton, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import SettingsIcon from '@material-ui/icons/Settings';
import ArrowIcon from '@material-ui/icons/ArrowForward';
import { isMobile } from 'react-device-detect';

import AVSettingsSidebar from './AVSettingsSidebar/AVSettingsSidebar';
import AvatarSettings from './AvatarSettings/AvatarSettings';
import MiscSettings from './MiscSettings/MiscSettings';
import { AppContext, AppContextType } from '../../../../context/AppContext';

import { SettingsSidebarStyles } from "./SettingsSidebarStyles";
import styles from "./SettingsSidebar.module.css";

interface Props {
  
}

export default function SettingsSidebar(props: Props): ReactElement {

  const { 
    setCurrentSidebarMode,
    isPresenter 
  } : AppContextType = useContext(AppContext);
  
  const [ currentTab, setCurrentTab ] = useState(0);

  useEffect(() => {
    let contentHolder = document.getElementById("settingsContentHolder");
    
    //Check if mobile to apply hack for CSS vh units
    if (isMobile) {
      if (isPresenter) {
        contentHolder.classList.add(`${styles.settingsContentHolderMobile}`);
      } else {
        contentHolder.classList.add(`${styles.settingsContentHolderNonPresenterMobile}`)
      }
    }
  }, [isPresenter]);

  //Tab select handler
  const handleTabSelect = (tab: number) => {
    setCurrentTab(tab);
  }
  
  const classes = SettingsSidebarStyles();

  //If we are an attendee and on tab 1 or 2, apply special class
  if (!isPresenter) {
    let contentHolder = document.getElementById("settingsContentHolder");
    if (currentTab === 1 || currentTab === 2) {

      if (contentHolder && contentHolder.classList) {
        if (isMobile) {
          contentHolder.classList.add(`${styles.contentHolderSpecialCaseMobile}`)
        } else {
          contentHolder.classList.add(`${styles.contentHolderSpecialCase}`);
        }
      } 
    } else {
 
      if (contentHolder && contentHolder.classList) {
        if (isMobile) {
          contentHolder.classList.remove(`${styles.contentHolderSpecialCaseMobile}`);
        } else {
          contentHolder.classList.remove(`${styles.contentHolderSpecialCase}`)
        }
      }
    }
  }


  return (
    <React.Fragment>
      <Paper elevation={0} className={classes.settingsHeaderHolder}>
        <Paper elevation={0} className={classes.settingsHeaderTop}>
          <SettingsIcon className={classes.settingsIcon} />

          <Typography variant="h1" className={classes.settingsHeader}>
            Settings
          </Typography>    

          <IconButton 
            className={classes.closeSidebarButton}
            onClick={() => setCurrentSidebarMode("")}
          >
            <ArrowIcon />
          </IconButton>
        </Paper>

        <Tabs
          classes={{ root: classes.tabs }}
          indicatorColor="primary"
          value={currentTab}
          variant="fullWidth"
        >
          <Tab onClick={() => handleTabSelect(0)} classes={{ root: classes.settingsTab }} label="AV" />
          <Tab onClick={() => handleTabSelect(1)} classes={{ root: classes.settingsTab }} label="AVATAR" />
          <Tab onClick={() => handleTabSelect(2)} classes={{ root: classes.settingsTab }} label="MISC" />
        </Tabs>

      </Paper>

      <div 
        className={
          isPresenter 
          ? styles.settingsContentHolder
          : styles.settingsContentHolderNonPresenter
        }
        id="settingsContentHolder"
      >
        {
        
          {
            0: <AVSettingsSidebar />,
            1: <AvatarSettings />,
            2: <MiscSettings />
          }[currentTab]
        }
      </div>
      

    </React.Fragment>
  )
}
