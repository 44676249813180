import { makeStyles, createStyles } from '@material-ui/core/styles';

const WelcomeElementStyles = makeStyles((theme: any) => createStyles({
  welcomeElementHolder: {
    display: "inline-block",
    width: 960,
    height: 550,
    position: "relative",
    backgroundColor: theme.palette.intakePaper.main,
    top: 100,
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      width: 720,
      height: 405,
    }
  },
  playIcon: {
    display: "inline-block",
    width: 135,
    height: 135,
    position: 'absolute',
    color: "white",
    left: 0,
    right: 0,
    top: 0,
    bottom: 10,
    margin: "auto",
    zIndex: 9999,
    cursor: "pointer"
  },
  welcomeElementHolderCountdown: {
    width: 560,
    height: 330,
    position: "absolute",
    right: 0,
    top: 260,
    [theme.breakpoints.down('sm')]: {
      top: 260,
      width: 480,
      height: 270
    }
  },
  link: {
    color: theme.palette.landingLink.main,
    fontSize: 16,
    fontFamily: "Roboto, sans-serif",
    "&:hover": {
      cursor: "pointer"
    }
  }
  
})
)

export { WelcomeElementStyles };