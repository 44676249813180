import { Button, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";
import WarningIcon from "@material-ui/icons/Warning";

import IntakeContentHolder from "../../components/IntakeContentHolder/IntakeContentHolder";

import { DeviceToggleWarningStyles } from "./DeviceToggleWarningStyles";
import useGetDevicePreferences from "../../../hooks/useGetDevicePreferences";

interface Props {
  deviceToggleWarningNextClick: () => void;
  deviceToggleCameraAndMicClick: (cameraAndMic: boolean) => void;
}

export default function DeviceToggleWarning(props: Props): ReactElement {

  const { storeCachedPreferredCamState, storeCachedPreferredMicState } =
    useGetDevicePreferences();

  //Camera and mic
  const handleCameraAndMicClick = () => {
    //Show camera and mic settings
    props.deviceToggleCameraAndMicClick(true);
  };

  //Next
  const handleNextClick = () => {
    //Show speakers page
    props.deviceToggleWarningNextClick();

    //Set false states for cached cam/mic preferences
    storeCachedPreferredCamState(false);
    storeCachedPreferredMicState(false);
  };

  const classes = DeviceToggleWarningStyles();

  return (
    <IntakeContentHolder marginBottom={"250px"}>
      <WarningIcon className={classes.warningIcon}></WarningIcon>

      <Typography
        classes={{ root: classes.deviceToggleWarningHeader }}
        variant="h1"
      >
        Without a mic or camera you will not be able to talk with others.
      </Typography>

      <Button
        className={classes.cameraAndMicButton}
        onClick={handleCameraAndMicClick}
        variant="contained"
      >
        TURN ON CAMERA AND MIC
      </Button>

      <Button
        className={classes.nextButton}
        onClick={handleNextClick}
        variant="contained"
        id="camAndMicSkipConfirm"
      >
        NEXT
      </Button>
    </IntakeContentHolder>
  );
}
