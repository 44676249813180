import { Button, Paper, Typography } from "@material-ui/core";
import React, { ReactElement, useContext } from "react";
import PlayArrow from "@material-ui/icons/PlayArrow";

import {
  IntakeContext,
  IntakeContextType,
} from "../../../context/IntakeContext";

import styles from "./MovementInstructionsPage.module.css";
import { MovementInstructionsPageStyles } from "./MovementInstructionsPageStyles";
import IntakeContentHolder from "../../components/IntakeContentHolder/IntakeContentHolder";

interface Props {
  onMovementPageComplete: () => void;
  onMovementPageBack: () => void;
}

export default function MovementInstructionsPage(props: Props): ReactElement {
  const { avatarLoadComplete }: IntakeContextType = useContext(IntakeContext);

  const classes = MovementInstructionsPageStyles();

  return (
    <IntakeContentHolder marginBottom={"0px"}>
      <div className={styles.movementInstructionsHolder}>
        <Typography variant="h1" className={classes.movementPageHeader}>
          Quick Tip: when in the event room you can
          <span style={{ color: "#00B6FF" }} className={styles.textSpan}>
            use your arrow keys to move {"\n"}
          </span>
          around the space.
        </Typography>

        <div className={styles.arrowKeyHolder}>
          {/* Col 1 Row 1 */}
          <Paper
            style={{ backgroundColor: "transparent" }}
            elevation={0}
          ></Paper>

          {/* Col 2 Row 1 */}
          <Paper className={classes.arrowKeyHolder}>
            <PlayArrow className={`${classes.icon} ${classes.iconUp}`} />
          </Paper>

          {/* Col 3 Row 1 */}
          <Paper
            style={{ backgroundColor: "transparent" }}
            elevation={0}
          ></Paper>

          {/* Col 1 Row 2 */}
          <Paper className={classes.arrowKeyHolder}>
            <PlayArrow className={`${classes.icon} ${classes.iconLeft}`} />
          </Paper>

          {/* Col 2 Row 2 */}
          <Paper className={classes.arrowKeyHolder}>
            <PlayArrow className={`${classes.icon} ${classes.iconDown}`} />
          </Paper>

          {/* Col 3 Row 2 */}
          <Paper className={classes.arrowKeyHolder}>
            <PlayArrow className={`${classes.icon} ${classes.iconRight}`} />
          </Paper>
        </div>

        <div className={styles.buttonHolder}>
          <Button
            className={classes.backButton}
            onClick={props.onMovementPageBack}
          >
            BACK
          </Button>

          <Button
            classes={{
              root: classes.enterButton,
              disabled: classes.enterButtonDisabled,
            }}
            onClick={props.onMovementPageComplete}
            id="finalEnterButton"
            disabled={!avatarLoadComplete}
          >
            ENTER
          </Button>
        </div>
      </div>
    </IntakeContentHolder>
  );
}
