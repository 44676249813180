import { makeStyles, createStyles } from '@material-ui/core/styles';

const SystemCheckPageStyles = makeStyles((theme: any) =>
  createStyles({
    "@keyframes pulse": {
      "0%": {
        boxShadow: `0 0 0 0px #71D6FF`,
      },
      "100%": {
        boxShadow: `0 0 0 100px #71D6FF`,
      },
    },
    systemCheckPageWrapper: {
      backgroundColor: theme.palette.intakeBackground.main,
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      boxShadow: "none",
      textAlign: "center",
      overflowY: "auto",
      overflowX: "hidden",
      [theme.breakpoints.down("sm")]: {},
    },
    systemCheckHolder: {
      position: "relative",
      top: 30,
      left: 200,
      width: 560,
      minHeight: 700,
      height: "auto",
      backgroundColor: theme.palette.intakePaper.main,
      borderRadius: 10,
      display: "inline-block",
      marginBottom: 100,
      [theme.breakpoints.down("sm")]: {
        top: 150,
        left: 0,
        marginBottom: 230,
      },
      "@media (max-width: 600px)": {
        width: 520,
      },
      "@media (max-width: 540px)": {
        width: 450,
      },
      "@media (max-width: 460px)": {
        width: 350,
      },
      "@media (max-width: 365px)": {
        width: 320,
      },
    },
    showboatHeader: {
      fontSize: 40,
      fontFamily: "Open Sans, sans-serif",
      position: "absolute",
      top: 47,
      left: 150,
      color: "#FFB700",
      [theme.breakpoints.down("sm")]: {
        left: 138,
      },
    },
    systemsCheckItemHeader: {
      color: "white",
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      position: "relative",
      left: 43,
      top: 2,
      "@media (max-width: 540px)": {
        position: "relative",
      },
      "@media (max-width: 460px)": {
        display: "inline-block",
        width: 250,
      },
    },
    systemCheckItemHeaderCamOrMic: {
      "@media (max-width: 460px)": {
        width: 140,
      },
    },
    systemsCheckItemHeaderError: {
      marginBottom: 5,
    },
    itemLoadingSpinner: {
      color: "#454545",
      width: "20px !important",
      height: "20px !important",
      position: "absolute",
      left: 0,
      "@media (max-width: 540px)": {
        position: "absolute",
      },
    },
    itemLoadingSpinnerSpeakers: {
      top: 13,
    },
    itemIcon: {
      position: "absolute",
      left: 0,
    },
    itemIconError: {
      position: "absolute",
      top: 10,
    },
    itemErrorText: {
      fontFamily: "Roboto, sans-serif",
      color: "#00B6FF",
      position: "relative",
      left: 43,
      width: 410,
      "@media (max-width: 540px)": {
        width: 350,
        position: "relative",
        display: "inline-block",
      },
      "@media (max-width: 460px)": {
        width: 250,
      },
      "@media (max-width: 365px)": {
        width: 235,
      },
    },
    itemErrorTextDismissed: {
      top: 17,
      marginBottom: 12,
    },
    permissionsButton: {
      backgroundColor: "#00B6FF",
      color: "black",
      position: "absolute",
      right: 0,
      display: "flex",
      alignContent: "flex-start",
      justifyContent: "center",
      flexDirection: "row",
      alignItems: "center",
      overflow: "hidden",
    },
    permissionsButtonDismissed: {
      position: "absolute",
      top: 10,
    },
    badge: {
      width: 1,
      height: 1,
      position: "absolute",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      borderRadius: "50%",
      backgroundColor: "#71D6FF",
      margin: "auto",
      animation: `$pulse 1s infinite cubic-bezier(.36, .11, .39, .59)`,
      WebkitAnimation: "$pulse 1s infinite cubic-bezier(.36, .11, .89, .32)",
      MozAnimation: "$pulse 1s infinite cubic-bezier(.36, .11, .89, .32)",
    },
    camAndMicListItem: {
      fontSize: 14,
      color: "white",
    },
    notAvailableHeader: {
      fontSize: 16,
      color: "white",
      position: "relative",
      top: 33,
      left: 45,
    },
    sendToSupport: {
      position: "absolute",
      top: 0,
      right: 0,
      backgroundColor: "#00B6FF",
      color: "white",
      "@media (max-width: 460px)": {
        left: 0,
        top: 45,
      },
    },
    permissionsLabel: {
      zIndex: 1,
      position: "relative"
    }
  })
);

export { SystemCheckPageStyles };