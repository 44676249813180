import useExecutePostJoinLogic from "./useExecutePostJoinLogic";
import useLoadApp from "./useLoadApp";
import { ServerHelper } from "../Utilities/ServerHelper";
import { useContext, useEffect, useState } from "react";
import { BioFormContext } from "../context/BioFormContext";
import { StringVariableHelper } from "../Utilities/StringVariableHelper";
import useGetDevicePreferences from "./useGetDevicePreferences";

import styles from "../PageLayout/PageLayout";
import { UIHelper } from "../Utilities/UIHelper";
import { AppContext } from "../context/AppContext";
import { BypassContext } from "../context/BypassContext";

const useCallAppIntakePost = () => {
  const { loadApp, toggleIsIntakeCompleted } = useLoadApp();
  const { executePostJoinLogic } = useExecutePostJoinLogic();
  const {
    getDevicePreferences,
    checkCachedPreferredMicState,
    checkCachedPreferredCameraState,
  } = useGetDevicePreferences();

  const { checkCachedIntakeData } = useContext(BioFormContext);

  const { bypassJoinCompleted, setBypassJoinCompleted } =
    useContext(BypassContext);

  const checkCurrentCachedCam = async (): Promise<void> => {
    let currentCachedCam: string =
      SHOWBOAT.StreamingUserMedia.getPrefferedCameraDevice();
    if (!SHOWBOAT.SystemInformation.getCameraName(currentCachedCam)) {
      await SHOWBOAT.StreamingUserMedia.SetCameraDeviceID("");
    }
  };

  const checkCurrentCachedMic = async (): Promise<void> => {
    let currentCachedMic: string =
      SHOWBOAT.StreamingUserMedia.getPrefferedMicrophoneDevice();
    if (!SHOWBOAT.SystemInformation.getMicrophoneName(currentCachedMic)) {
      await SHOWBOAT.StreamingUserMedia.SetMicrophoneDeviceID("");
    }
  };

  const checkCurrentCachedSpeaker = (): void => {
    let currentCachedSpeaker: string =
      SHOWBOAT.StreamingUserMedia.getCurrentSpeakerDevice();
    if (!SHOWBOAT.SystemInformation.getSpeakerName(currentCachedSpeaker)) {
      SHOWBOAT.StreamingUserMedia.SetSpeakerDevice("");
    }
  };

  const doInitialDeviceSetup = async (): Promise<void> => {
    let initialCameraState: boolean =
      SHOWBOAT.SystemInformation.HasWebCamera &&
      checkCachedPreferredCameraState(null);
    let initialMicrophoneState: boolean =
      SHOWBOAT.SystemInformation.HasMicrophone &&
      checkCachedPreferredMicState(null);

    //Check cam and mic cache

    //Ensure we have a device to start
    if (initialCameraState || initialMicrophoneState) {
      //Check for current cached cam
      await checkCurrentCachedCam();

      //Check for current cached mic
      await checkCurrentCachedMic();

      //Check for current cached speaker
      checkCurrentCachedSpeaker();

      //Start the devices
      await SHOWBOAT.StreamingUserMedia.SetDeviceStates(
        initialCameraState,
        initialMicrophoneState
      );
    } else {
      //We don't have any devices. Just set device states to off
      await SHOWBOAT.StreamingUserMedia.SetDeviceStates(false, false);
    }
  };

  const initializeShowboatLoader = async (): Promise<boolean> => {
    let audioContext: AudioContext = SHOWBOAT.AudioContextManager.AudioContext;
    let audioDestination = null;
    if (ServerHelper.useAudioManager) {
      await SHOWBOAT.AudioManager.Init(ServerHelper.useAudioManagerLoopback);
      audioDestination = SHOWBOAT.AudioManager.getAudioDestination();
    }

    //Initialize the Showboat Loader
    try {
      await SHOWBOAT.ShowboatLoader.Init(
        "showboatCanvas",
        "./core/" + SHOWBOAT.ApplicationSkin.showboatCore,
        "./core/" + SHOWBOAT.ApplicationSkin.showboatCoreConfig,
        audioContext,
        audioDestination,
        ServerHelper.useAudioManager
      );

      return true;
    } catch (error) {
      SHOWBOAT.Logger.Error("Error initializing Showboat Loader");
      SHOWBOAT.Logger.Error(error);

      return false;
    }
  };

  const executeBypass = async (): Promise<boolean | string> => {
    //This function gets called after successful call to AWS - so we have login info

    try {
      //Ensure we have loading screen shown
      SHOWBOAT.ShowboatLoader.OnShowLoadScreen.Raise();

      //First ensure that user has completed intake at least once
      if (
        localStorage.getItem(
          StringVariableHelper.LocalStorageProperties.HasCompletedIntakeData
        ) != "true"
      ) {
        SHOWBOAT.Logger.Error(
          "Rejecting bypass, user has never completed intake"
        );
        return false;
      }

      //Use cached login info - should be already set from BioFormContext

      //Check for cached intake data
      checkCachedIntakeData();

      //Check if all intake data values are empty strings
      //If so, no intake data is stored. Make user go through regular intake
      if (
        SHOWBOAT.LocalAvatarDataManager.firstName === "" &&
        SHOWBOAT.LocalAvatarDataManager.lastName === ""
      ) {
        SHOWBOAT.Logger.Log("No intake data, rejecting bypass");
        return "NoIntakeData";
      }

      //Check for cached color
      UIHelper.CheckForCachedColor();

      //Check for cached face
      UIHelper.CheckForCachedFace();

      //Init devices
      await SHOWBOAT.StreamingUserMedia.Init(
        false,
        SHOWBOAT.RemotePlayersZoneConfig.doAvatarSimulation
      );

      //Check for cached devices
      await getDevicePreferences();

      //Attempt to start cached devices
      await doInitialDeviceSetup();

      //Append babylon canvas to the DOM
      let div: HTMLElement = document.createElement("div");
      document.getElementById("root").appendChild(div);
      (window as any).babylonCanvas.classList.add("bypassCanvas");
      div.appendChild((window as any).babylonCanvas);

      toggleIsIntakeCompleted(true);

      //Initialize the Showboat 3D Loader
      let initialize: boolean = await initializeShowboatLoader();

      if (!initialize) return false;

      //Call loadApp function from useLoadApp hook
      await loadApp();

      setBypassJoinCompleted(true);

      //Execute post-join logic from useExecutePostJoinLogic hook
      await executePostJoinLogic(null, styles);

      return true;
    } catch (error) {
      SHOWBOAT.Logger.Error("Error during bypass", error);

      //Remove canvas from the dom
      if ((window as any).babylonCanvas) (window as any).babylonCanvas.remove();

      return false;
    }
  };

  return {
    executeBypass,
    bypassJoinCompleted,
  };
};

export default useCallAppIntakePost;
