import React, { ReactElement, useRef, useEffect } from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import $ from 'jquery';

import { EventEndingPopupStyles } from './EventEndingPopupStyles';

interface Props {
  eventEndingID: number,
  handleEventEndingClose: (eventEndingID: number) => any,
}

export default function EventEndingPopup(props: Props): ReactElement {

  const autoCloseTimer = useRef(null);
  const displayTimer = useRef(null);

  useEffect(() => {

    autoCloseTimer.current = setTimeout(function() {

      var $thisParent = $(`#${props.eventEndingID}`).closest('div');
      
      $thisParent.nextAll().each(function(index){ 
        $(this).stop().animate({
          top: `-=${$thisParent.outerHeight(true)}`
        }, 300)
      });

      props.handleEventEndingClose(props.eventEndingID);

    }, 60000);

    displayTimer.current = setTimeout(function() {
      document.getElementById(props.eventEndingID.toString()).style.display = "inline-block";
    }, 300);

    return function cleanup() {
      clearTimeout(autoCloseTimer.current);
      clearTimeout(displayTimer.current);
    }

  }, []);

  const handleDismissClick = () => {
    props.handleEventEndingClose(props.eventEndingID);
  }
  
  const classes = EventEndingPopupStyles();
  
  return (
    <Paper 
      elevation={5} 
      classes={{ root: classes.eventEndingPopup }}
      className="notification"
      id={props.eventEndingID.toString()}
    >
      <Typography variant="h1" className={classes.eventEndingText}>
        The scheduled end time for this event has arrived. The room will be closing shortly.
      </Typography>

      <Button onClick={handleDismissClick} variant="text" className={`${classes.dismissButton} dismissButton`}>
        DISMISS
      </Button>
    </Paper>
  )
}
