import React, {useState } from 'react';
import { StringVariableHelper } from '../Utilities/StringVariableHelper';

export interface AppContextType {
  isPresenter : boolean,
  toggleIsPresenter : (isPresenter : boolean) => void,
  isTeleportationBlocked : boolean,
  toggleIsTeleportationBlocked : (isTeleportationBlocked : boolean) => void,
  isDebugModeActive : boolean,
  toggleIsDebugModeActive : (isDebugModeActive : boolean) => void,
  selectedAttendeeIDContext : string,
  setSelectedAttendeeIDContext : (attendeeID : string) => void,
  jumbotronFullscreenView : boolean,
  toggleJumbotronFullscreenView : (isJumbotronViewActivated : boolean) => void,
  avatarLoadComplete: boolean,
  toggleAvatarLoadComplete : (loadComplete: boolean) => void,
  currentSidebarMode : string,
  setCurrentSidebarMode : (mode : string) => void,
  photoPreview: boolean,
  setPhotoPreview : (photoPreview: boolean) => void,
  showMoveMenu : boolean,
  setShowMoveMenu : (showMoveMenu: boolean) => void,
  bypassJoinCompleted : boolean,
  setBypassJoinCompleted : (bypassJoinCompleted : boolean) => void
}

export const AppContext = React.createContext(null);

export const AppContextProvider = (props) => {

  const [ isPresenter, toggleIsPresenter ] = useState(
    SHOWBOAT.LocalAvatarDataManager.avatarData.role === 
      StringVariableHelper.ShowboatRoles.presenter ? true : false
  );
  const [ isTeleportationBlocked, toggleIsTeleportationBlocked ] = useState(false);
  const [ isDebugModeActive, toggleIsDebugModeActive ] = useState(false);
  const [ selectedAttendeeIDContext, setSelectedAttendeeIDContext ] = useState(null);
  const [ currentSidebarMode, setCurrentSidebarMode ] = useState("");
  const [ jumbotronFullscreenView, toggleJumbotronFullscreenView ] = useState(false);
  const [ avatarLoadComplete, toggleAvatarLoadComplete ] = useState(false);
  const [photoPreview, setPhotoPreview] = useState(false);

  const [showMoveMenu, setShowMoveMenu] = useState(false);

  const [bypassJoinCompleted, setBypassJoinCompleted] = useState(false);

  const drawerProviderValue : AppContextType = {
    isPresenter,
    toggleIsPresenter,
    isTeleportationBlocked,
    toggleIsTeleportationBlocked,
    isDebugModeActive,
    toggleIsDebugModeActive,
    selectedAttendeeIDContext,
    setSelectedAttendeeIDContext,
    jumbotronFullscreenView,
    toggleJumbotronFullscreenView,
    avatarLoadComplete,
    toggleAvatarLoadComplete,
    currentSidebarMode,
    setCurrentSidebarMode,
    photoPreview,
    setPhotoPreview,
    showMoveMenu,
    setShowMoveMenu,
    bypassJoinCompleted,
    setBypassJoinCompleted
  }

  return (
    <AppContext.Provider
      value={drawerProviderValue}
    >
      {props.children}
    </AppContext.Provider>
  )
}
