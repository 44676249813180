import { makeStyles, createStyles } from '@material-ui/core/styles';

const SystemCheckHeaderStyles = makeStyles((theme: any) =>
  createStyles({
    systemsCheckHeader: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 24,
      fontWeight: 400,
      position: "absolute",
      top: 8,
      left: 75,
    },
    systemsCheckSubheader: {
      fontFamily: "Roboto, sans-serif",
      fontSize: 16,
      fontWeight: 400,
      position: "absolute",
      left: 75,
      top: 47,
      "@media (max-width: 460px)": {
        top: 65,
      },
    },
    sendToSupportText: {},
    submitReportButton: {
      backgroundColor: "#00B6FF",
      color: "black",
      display: "inline-block",
      width: 146,
      height: 35,
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#00B6FF",
      },
      "@media (max-width: 540px)": {
        width: 120,
      },
    },
    moreInformationHeader: {
      position: "relative",
      top: 0,
      left: 0,
      fontSize: 16,
      fontWeight: 400,
      fontFamily: "Roboto, sans-serif",
    },
  })
);

export { SystemCheckHeaderStyles }; 