import React, { ReactElement } from 'react';
import CameraAndMicSettings from '../../../../../Intake/CameraAndMicSettings/CameraAndMicSettings';

import styles from './AVSettingsSidebar.module.css';

export default function AVSettingsSidebar(props: any): ReactElement {
  return (
    <div className={styles.avSettingsHolder}>
      <CameraAndMicSettings 
        intake={false}
        cameraAndMicChosen={true}
      />
    </div>
  )
}
