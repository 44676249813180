import React, { ReactElement, useContext, useEffect, useState } from "react";
import ChatIcon from "@material-ui/icons/Chat";
import EmojiIcon from "@material-ui/icons/EmojiEmotions";
import ScrollContainer from "react-indiana-drag-scroll";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import RightArrowIcon from "@material-ui/icons/ArrowForward";
import {
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { isMobile } from "react-device-detect";

import { AppContext, AppContextType } from "../../../../context/AppContext";
import {
  MessageContext,
  MessageContextType,
} from "../../../../context/MessageContext";
import ChatMessage from "./ChatSidebarMessage/ChatSidebarMessage";

import styles from "./ChatSidebar.module.css";
import { ChatSidebarStyles } from "./ChatSidebarStyles";

var Filter = require("bad-words");

export default function ChatSidebar(): ReactElement {
  const { setCurrentSidebarMode, isPresenter }: AppContextType =
    useContext(AppContext);

  const { chatMessages, toggleNewMessage }: MessageContextType =
    useContext(MessageContext);

  const [chatMessage, setChatMessage] = useState("");
  const [showEmojis, toggleShowEmojis] = useState(false);

  let filter = new Filter();

  useEffect(() => {
    //Check for mobile to use CSS hack for vh units
    let chatContentHolder = document.getElementById("chatContentHolder");
    if (chatContentHolder) {
      if (isMobile) {
        if (isPresenter) {
          chatContentHolder.classList.add(`${styles.chatContentHolderMobile}`);
        } else {
          chatContentHolder.classList.add(
            `${styles.chatContentHolderAttendeeMobile}`
          );
        }
      }
    }

    //On open, scroll chat window to bottom
    let chatWindow = document.getElementsByClassName(
      "indiana-scroll-container"
    )[0];

    if (chatWindow) {
      let scrollHeight = chatWindow.scrollHeight;
      chatWindow.scrollTo(0, scrollHeight);
    }

    return function cleanup() {
      toggleNewMessage(false);
    };
  }, [isPresenter, toggleNewMessage]);

  //Scroll to bottom of chat message container when new message is received
  useEffect(
    () => {
      let chatWindow = document.getElementsByClassName(
        "indiana-scroll-container"
      )[0];

      if (chatWindow) {
        let scrollHeight = chatWindow.scrollHeight;
        chatWindow.scrollTo(0, scrollHeight);
      }
    },
    //only cause scroll effect when messages are updated in messageContext
    [chatMessages]
  );

  const handleChatMessageChange = (e) => {
    setChatMessage(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    //Don't allow submission if text field is blank
    if (chatMessage === "") return;

    //Refocus canvas
    let aWindow: any = window;
    aWindow.babylonCanvas.focus();

    //Submit message to server
    SHOWBOAT.SocketIOController.SendEventAll("chat", {
      msg: filter.clean(chatMessage),
      color: SHOWBOAT.LocalAvatarDataManager.avatarData.color,
      face: SHOWBOAT.LocalAvatarDataManager.avatarData.face,
      name: `${SHOWBOAT.LocalAvatarDataManager.avatarData.firstName} ${SHOWBOAT.LocalAvatarDataManager.avatarData.lastName}`,
      date: new Date(),
    });

    //Reset text field
    setChatMessage("");
  };

  const handleEmojiClick = () => {
    toggleShowEmojis(!showEmojis);
  };

  const handleEmojiSelect = (e) => {
    //parse emoji code from event object
    let sym = e.unified.split("-");
    let codesArray = [];
    sym.forEach((el) => codesArray.push("0x" + el));
    let emoji = String.fromCodePoint(...codesArray);

    //append emoji code to message input
    setChatMessage(`${chatMessage} ${emoji}`);

    //hide emoji picker
    toggleShowEmojis(false);
  };

  const classes = ChatSidebarStyles();

  return (
    <React.Fragment>
      <Paper className={classes.chatHeaderHolder}>
        <ChatIcon className={classes.chatIcon} />

        <Typography variant="h1" className={classes.chatHeader}>
          Chat
        </Typography>

        <IconButton
          className={classes.closeIconButton}
          onClick={() => setCurrentSidebarMode("")}
        >
          <RightArrowIcon />
        </IconButton>
      </Paper>

      <div
        className={
          isPresenter
            ? styles.chatContentHolder
            : `${styles.chatContentHolder} ${styles.chatContentHolderAttendee}`
        }
        id="chatContentHolder"
      >
        <div className={styles.chatMessageHolder}>
          <ScrollContainer
            hideScrollbars={false}
            horizontal={false}
            className={styles.scrollContainer}
          >
            {chatMessages.map((chatMessage, i) => {
              return (
                <ChatMessage
                  key={(chatMessage as any).id}
                  msg={chatMessage.message.msg}
                  name={chatMessage.message.name}
                  color={chatMessage.message.color}
                  face={chatMessage.message.face}
                  date={chatMessage.message.date}
                  id={(chatMessage as any).id}
                />
              );
            })}
          </ScrollContainer>
        </div>

        {showEmojis && (
          <div className={styles.emojiSelectHolder}>
            <Picker
              theme={SHOWBOAT.ApplicationSkin.theme}
              onSelect={(e) => handleEmojiSelect(e)}
              style={{ width: "100%" }}
            />
          </div>
        )}

        <Paper className={classes.chatInputHolderBackdrop}></Paper>

        <Paper className={classes.chatMessageInputHolder}>
          <TextField
            multiline
            rows={3}
            value={chatMessage}
            variant="outlined"
            onChange={handleChatMessageChange}
            className={classes.chatTextField}
            placeholder="Send a message"
            inputProps={{
              maxLength: 500,
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                handleFormSubmit(e);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" className={classes.emojiIcon}>
                  <EmojiIcon onClick={handleEmojiClick} />
                </InputAdornment>
              ),
              classes: {
                notchedOutline: classes.noBorder,
                root: classes.chatInput,
                inputAdornedEnd: classes.chatInputAdornedEnd,
              },
              type: "text",
            }}
          ></TextField>

          <Typography variant="body1" className={classes.chatHelperText}>
            This message will be seen by everyone
          </Typography>
        </Paper>
      </div>
    </React.Fragment>
  );
}
