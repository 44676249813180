import React, { useState } from "react";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";

import PageLayout from "./PageLayout/PageLayout";
import { AttendeeContextProvider } from "./context/AttendeeContext";
import { BioFormContextProvider } from "./context/BioFormContext";
import { ErrorContextProvider } from "./context/ErrorContext";
import { IntakeContextProvider } from "./context/IntakeContext";
import SystemCheckPage from "./SystemCheck/SystemCheckPage/SystemCheckPage";
import CookieNotification from "./Intake/CookieNotification/CookieNotification";
import { StringVariableHelper } from "./Utilities/StringVariableHelper";
import { PopupContextProvider } from "./context/PopupContext";
import { BypassContextProvider } from "./context/BypassContext";

export default function App(props) {
  const [hideCookieNotification, toggleHideCookieNotification] =
    useState(false);

  /* Material UI themes */
  const DarkTheme = createMuiTheme({
    typography: {
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    },
    breakpoints: {
      values: {
        lg: 1281,
        md: 960,
      },
    },
    palette: {
      type: "dark",
      primary: {
        main: props.primaryThemeColor,
      },
      secondary: {
        main: "#25252B",
        dark: "#19191F",
      },
      intakeInput: {
        main: "#111112",
      },
      intakeBackground: {
        main: "#1B1B1D",
      },
      inputBackgroundColor: {
        main: "#3E4044",
      },
      intakePaper: {
        main: "black",
      },
      intakeBlue: {
        main: "#00B6FF",
      },
      selectedMode: {
        main: "#5c5c5c",
      },
      background: {
        paper: "#2F3136",
        default: "#1A1A1C",
      },
      emojiIcon: {
        main: "white",
      },
      toAttendeeSelect: {
        main: "#505050",
      },
      controlCenterButton: {
        main: "#464646",
      },
      controlCenterButtonMuted: {
        main: "#FFFFFF",
      },
      tooltip: {
        main: "#DEDEDE",
      },
      sidebarHeader: {
        main: "#272727",
      },
      chatInputBackground: {
        main: "#4E4E4E",
      },
      teleportButton: {
        main: "#707170",
      },
      plain: {
        main: "black",
      },
      presenterMenuItemActive: {
        main: "black",
      },
      roomTabIcon: {
        main: "#807676",
      },
      avatarSelectedBorder: {
        main: "white",
      },
      avatarBorder: {
        main: "#272727",
      },
      colorSelectedBorder: {
        main: "white",
      },
      colorBorder: {
        main: "#272727",
      },
      attendeeCompany: {
        main: "#BABABA",
      },
      tooltip: {
        main: "#525252",
      },
      tooltipBorder: {
        main: "#242424",
      },
      landingLink: {
        main: "lightgrey",
      },
      messageCopyButton: {
        main: "white",
      },
      settingsPaper: {
        main: "#393939",
      },
      nameTagInputField: {
        main: "#474747",
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "*": {
            "scrollbar-width": "thin",
          },
          "*::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
          },
        },
      },
    },
  });

  const LightTheme = createMuiTheme({
    typography: {
      fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    },
    breakpoints: {
      values: {
        lg: 1281,
        md: 960,
      },
    },
    palette: {
      type: "light",
      primary: {
        main: props.primaryThemeColor,
      },
      secondary: {
        main: "#fff",
        dark: "#f0f0f0",
      },
      intakeBlue: {
        main: "#00B6FF",
      },
      intakeBackground: {
        main: "#ededed",
      },
      inputBackgroundColor: {
        main: "white",
      },
      emojiIcon: {
        main: "grey",
      },
      selectedMode: {
        main: "#5c5c5c",
      },
      intakePaper: {
        main: "white",
      },
      toAttendeeSelect: {
        main: "#c9c9c9",
      },
      intakeInput: {},
      controlCenterButton: {
        main: "white",
      },
      controlCenterButtonMuted: {
        main: "#FFFFFF",
      },
      tooltip: {
        main: "#575757",
      },
      sidebarHeader: {
        main: "white",
      },
      chatInputBackground: {
        main: "#e0e0e0",
      },
      teleportButton: {
        main: "#707170",
      },
      plain: {
        main: "white",
      },
      presenterMenuItemActive: {
        main: "#bdbdbd",
      },
      roomTabIcon: {
        main: "black",
      },
      avatarSelectedBorder: {
        main: "lightgrey",
      },
      avatarBorder: {
        main: "#272727",
      },
      colorSelectedBorder: {
        main: "lightgrey",
      },
      colorBorder: {
        main: "#272727",
      },
      attendeeCompany: {
        main: "#787878",
      },
      landingLink: {
        main: "black"
      },
      messageCopyButton: {
        main: "grey"
      },
      settingsPaper: {
        main: "white"
      },
      nameTagInputField: {
        main: "white"
      }
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          "*": {
            "scrollbar-width": "thin",
          },
          "*::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
          },
        },
      },
    },
  });
  /**/

  //hide cookie notification when event is joined
  const handleJoinEventCompleted = () => {
    localStorage.setItem(
      StringVariableHelper.LocalStorageProperties.CookieWarning,
      true
    );

    toggleHideCookieNotification(true);
  };

  //Check if we are doing a system check

  if (props.systemCheck) {
    return (
      <ThemeProvider theme={props.theme === "dark" ? DarkTheme : LightTheme}>
        <SystemCheckPage />
      </ThemeProvider>
    );
  } else {
    return (
      <BioFormContextProvider>
        <BypassContextProvider>
          <AttendeeContextProvider>
            <PopupContextProvider>
              <IntakeContextProvider>
                <ErrorContextProvider>
                  <ThemeProvider
                    theme={props.theme === "dark" ? DarkTheme : LightTheme}
                  >
                    <PageLayout
                      handleJoinEventCompleted={handleJoinEventCompleted}
                    />

                    <CookieNotification
                      hideCookieNotification={hideCookieNotification}
                    />

                    {/* Image pre-load for chat icon */}
                    <span
                      className="material-icons"
                      style={{ visibility: "hidden" }}
                    >
                      content_copy
                    </span>
                  </ThemeProvider>
                </ErrorContextProvider>
              </IntakeContextProvider>
            </PopupContextProvider>
          </AttendeeContextProvider>
        </BypassContextProvider>
      </BioFormContextProvider>
    );
  }
}
