import { makeStyles, createStyles } from '@material-ui/core/styles';

const MovementInstructionsPageStyles = makeStyles((theme: any) => createStyles({
  movementPageWrapper: {
    display: "inline-block",
    position: "absolute",
    bottom: -86,
    right: 0,
    backgroundColor: theme.palette.intakePaper.main,
    width: 560,
    height: 538,
    textAlign: "center",
    borderRadius: 10,
    animation: "fadeIn .5s",
    WebkitAnimation: "fadeIn .5s",
    MozAnimation: "fadeIn .5s",
    [theme.breakpoints.down('sm')]: {
      width: 400,
      height: 500,
      bottom: -49,
    }
  },
  movementPageHeader: {
    fontWeight: 400,
    fontSize: 24,
    fontFamily: "Open Sans, sans-serif",
    position: "absolute",
    top: 34,
    left: 50,
    display: "inline-block",
    width: 441,
    lineHeight: 1.35,
    textAlign: "left",
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      left: 30,
      width: 345,
    }
  },
  enterButton: {
    backgroundColor: theme.palette.intakeBlue.main,
    height: 46,
    width: 115,
    color: "white",
  },
  enterButtonDisabled: {
    backgroundColor: theme.palette.intakePaper.main
  },
  backButton: {
    height: 46,
    width: 115,
  },
  arrowKeyHolder: {
    border: "2px solid",
    borderColor: theme.palette.text.primary,
    borderRadius: 15,
    backgroundColor: "transparent",
    width: 102,
    height: 102,
    display: "inline-block",
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      width: 85,
      height: 85,
    }
  },
  icon: {
    color: theme.palette.text.primary,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    margin: "auto",
    fontSize: 50,
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    }
  },
  iconUp: {
    transform: "rotate(-90deg)"
  },
  iconLeft: {
    transform: "rotate(180deg)"
  },
  iconDown: {
    transform: "rotate(90deg)"

  },
  iconRight: {

  }
})
)

export { MovementInstructionsPageStyles };