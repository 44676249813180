import { makeStyles, createStyles } from '@material-ui/core/styles';

const BioIntakeFormStyles = makeStyles((theme: any) => createStyles({
  bioInput: {
    width: 460,
    height: 56,
    backgroundColor: theme.palette.intakeInput.main,
    marginBottom: 37,
    [theme.breakpoints.down('sm')]: {
      width: 340,
    }
  },
  bioNext: {
    backgroundColor: theme.palette.intakeBlue.main,
    color: "white",
    height: 46,
    width: 115,
    position: "absolute",
    right: 50,
    bottom: 54,
  },
  bioInputNotRequired: {
    width: 460,
    height: 56,
    backgroundColor: theme.palette.intakeInput.main,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      width: 340,
    }
  },
  errorText: {
    fontSize: 12
  },
  input: {
    height: 460,
    display: "inline-block",
  }
})
)

export { BioIntakeFormStyles };