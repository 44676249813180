import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const LivestreamShareStyles = makeStyles((theme: Theme) => createStyles({
  shareLivestreamToggle: {
    position: "absolute",
    bottom: "6%",
    right: "6%"
  },
  shareLivestreamLabel: {
    fontSize: 14,
    fontWeight: 500,
  },
  livestreamURLTextField: {
    position: "absolute",
    top: "6%",
    width: "75%",
    left: "6.9%",
  },
  arrowIconButton: {
    padding: 4,
    position: "absolute",
    right: "4.5%",
    top: "5.5%",
  },
  arrowIcon: {
    fontSize: 32
  },
  livestreamURLInput: {
    height: 42,
  },
  loadingSpinner: {
    width: "80px !important",
    height: "80px !important",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    color: theme.palette.text.primary,
    zIndex: 10,
  }
})
)

export { LivestreamShareStyles };