import { Button, Dialog, Typography } from '@material-ui/core';
import React, { ReactElement, useRef, useEffect } from 'react';

import { ServerHelper } from '../../../../Utilities/ServerHelper';

import { InactivePopupStyles } from './InactivePopupStyles';

interface Props {
  open: boolean,
  handlePopupClose: () => any,
}

export default function InactivePopup(props: Props): ReactElement {

  const logoutTimerRef = useRef(null);
  
  useEffect(() => {
    
    //Start logout timer (5 minutes) after popup shows
    logoutTimerRef.current = setTimeout(function() {
      //If ServerHelper.hangUpURL is undefined or an empty string, just reload to home page. Otherwise, redirect to specified hangUpURL for event
      if (ServerHelper.hangUpURL === undefined || ServerHelper.hangUpURL === "") {
        window.location.reload();
      } else {

        //Double-check if URL starts with http first
        if (!ServerHelper.hangUpURL.trim().startsWith("http")) {
          window.location.href = "https://" + ServerHelper.hangUpURL;
          return false;
        } else {
          window.location.href = ServerHelper.hangUpURL;
          return false;
        }
      }
    }, 300000);

    return function cleanup() {
      if (logoutTimerRef.current) {
        clearTimeout(logoutTimerRef.current);
      }
    }

  }, []);
  
  const classes = InactivePopupStyles();
  
  return (
    <Dialog
      open={props.open}
      classes={{ paper: classes.dialogPaper}}
    >
      <div className={classes.popup}>
        <Typography variant="body1" className={classes.inactiveText}>
          Still here?
        </Typography>

        <Button 
          onClick={() => props.handlePopupClose()} 
          className={classes.yesButton} 
          variant="contained" 
          color="primary"
        >
          Yes
        </Button>
        
      </div>
    </Dialog>
  )
}
