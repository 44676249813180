import { Paper, Typography } from "@material-ui/core";
import React, { ReactElement } from "react";

import styles from "./MobileAndTabletErrorPage.module.css";
import { MobileAndTabletErrorPageStyles } from "./MobileAndTabletErrorPageStyles";

export default function MobileAndTabletErrorPage(): ReactElement {
  const classes = MobileAndTabletErrorPageStyles();

  return (
    <Paper square className={classes.mobileAndTabletErrorMessageWrapper}>
      <div className={styles.mobileAndTabletErrorMessageHolder}>
        <img
          className={styles.errorLogo}
          alt="Showboat logo"
          src="assets/images/sb-logo-error.png"
        />

        <Typography variant="body1" className={classes.errorText}>
          {/* Showboat is not currently supported on tablets and mobile devices */}
          Showboat is currently only supported on desktops, laptops, and tablets
          via Safari
        </Typography>
      </div>
    </Paper>
  );
}
