import { makeStyles, createStyles } from '@material-ui/core/styles';

const AttendeeListStyles = makeStyles((theme: any) => createStyles({
  searchBar: {
    display: "inline-block",
    position: "relative",
    height: "100%",
    width: "100%",
    border: "none",
    [theme.breakpoints.down('md')]: {
    },
    /* ['@media(max-height: 750px)']: {
      top: 215,
    }, */
  },
  input: {
    height: 45,
    position: "relative",
    top: 5,
    border: "none"
  },
  noBorder: {
    border: "none"
  },
  inputLabel: {
    transition: "none",
  },
  inputLabelFocused: {
    display: "none",
  },
  searchIcon: {
  },
  searchWarning: {
    position: "relative",
    top: 170,
    float: "left",
    left: 25,
    fontSize: 12,
    color: theme.palette.text.disabled,
    [theme.breakpoints.down('md')]: {
      fontSize: 11,
      top: 170
    },
    /* ['@media(max-height: 750px)']: {
      top: 215,
    }, */
  },
  focusedInput: {
    borderBottomColor: "white"
  },
  cancelIcon: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  attendeesHeader: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    borderRadius: 4,
    height: 40,
    position: "relative",
    color: "white"
  },
  directoryHeader: {
    fontSize: 16,
    fontWeight: 400,
    position: "absolute",
    left: 43,
    top: 10,
  },
  peopleIcon: {
    position: 'absolute',
    top: 7,
    left: 10,
    color: "white"
  },
  closeSidebarButton: {
    padding: 4,
    position: 'absolute',
    top: 3,
    right: 7,
    color: "white"
  },
  searchBarHolder: {
    width: "100%",
    height: 58,
    backgroundColor: theme.palette.controlCenterButton.main,
    position: "relative",
    top: 4,
  },
  paginationCaption: {
    display: "none",
  },
  paginationSelect: {
    display: "none"
  },
  paginationActions: {
    width: "95%",
    marginLeft: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
    bottom: 6,
    right: 7,
    color: "white",
    "& button": {
      padding: 4,
    }
  },
  paginationToolbar: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  paginationHeader: {
    position: "relative",
    display: "inline-block",
    color: "white"
  }
 
})
)

export { AttendeeListStyles }