import React, { ReactElement, useRef, useEffect } from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import $ from 'jquery';

import { NewRoomPopupStyles } from './NewRoomPopupStyles';

interface Props {
  popupKey: number,
  handlePopupClose: (popupKey: number) => any,
}

export default function NewRoomPopup(props: Props): ReactElement {

  const autoCloseTimer = useRef(null);
  const displayTimer = useRef(null);

  useEffect(() => {

    autoCloseTimer.current = setTimeout(function () {

      var $thisParent = $(`#${props.popupKey}`).closest('div');

      $thisParent.nextAll().each(function (index) {
        $(this).stop().animate({
          top: `-=${$thisParent.outerHeight(true)}`
        }, 300)
      });


      props.handlePopupClose(props.popupKey);

    }, 10000);

    displayTimer.current = setTimeout(function () {
      document.getElementById(props.popupKey.toString()).style.display = "inline-block";
    }, 300);

    return function cleanup() {
      clearTimeout(autoCloseTimer.current);
      clearTimeout(displayTimer.current);
    }

  }, [props])

  const handleDismissClick = () => {
    props.handlePopupClose(props.popupKey);
  }

  const classes = NewRoomPopupStyles();

  return (
    <Paper
      elevation={5}
      className={`${classes.newRoomPopup} notification`}
      id={props.popupKey.toString()}
    >
      <Typography variant="h1" className={classes.newRoomText}>
        A new room has opened! Click the room button in the toolbar to open the room menu.
      </Typography>

      <Button onClick={handleDismissClick} variant="text" className={`${classes.dismissButton} dismissButton`}>
        DISMISS
      </Button>
    </Paper>
  )

}
