import { makeStyles, createStyles } from '@material-ui/core/styles';

const AttendeeItemStyles = makeStyles((theme: any) => createStyles({
  attendeeItem: {
    width: "98.5%",
    height: 50,
    right: 1,
    marginTop: 4,
    position: "relative",
    textAlign: "left",
    backgroundColor: theme.palette.controlCenterButton.main,
    "&:hover": {
      cursor: "pointer"
    },  
  },
  attendeeName: {
    position: "absolute",
    width: 217,
    top: 5,
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    left: 26,
    fontSize: 16,
    fontWeight: 500,
  }, 
  attendeeNameDebug: {
    width: 183,
  },
  attendeeCompany: {
    fontSize: 12,
    position: "absolute",
    left: 26,
    top: 26,
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 217,
    color: theme.palette.attendeeCompany.main,
  
  },
  expandedAttendeeHolder: {
    height: 91,
    "&:hover": {
      cursor: "pointer"
    }, 
  },
  attendeeNameExpanded: {
    position: "absolute",
    fontSize: 18,
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 217,
    top: 6,
    left: 68,
  },
  attendeeNameExpandedDebug: {
    width: 155,
  },
  attendeeIconExpanded: {
    height: 40,
    width: 40,
    position: "absolute",
    marginLeft: 13,
    top: 10,
  },
  attendeeCompanyExpanded: {
    position: "absolute",
    fontSize: 14,
    display: "inline-block",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    width: 217,
    top: 33,
    fontWeight: 500,
    left: 68,
  },
  sendMessageButton: {
    position: "absolute",
    top: 6,
    right: 8,
  },
  sendMessageButtonExpanded: {
    position: "absolute",
    top: 6,
    right: 8,
  },
  sendMessageButtonExpandedDebug: {
    top: 6
  },
  sendMessageButtonTeleport: {
    position: "absolute",
    top: 6,
    right: 8,
  },
  sendMessageButtonTeleportDebug: {
    top: 9
  },
  manageButton: {
    height: 30,
    width: 107,
    position: "absolute",
    left: 127,
    bottom: 17,
  },
  muteButton: {
    height: 30,
    width: 105,
    position: "absolute",
    left: 130,
    bottom: 10,
  },
  manageIcon: {

  },
  locationIconTeleport: {
    position: "absolute",
    left: 20,
    top: 55,
    fontSize: 20,
  },
  teleportMessage: {
    position: "absolute",
    top: 55,
    left: 55,
    fontSize: 14,
    fontWeight: 500,
  },
  teleportMessageResult: {
    left: 50,
  },
  sendMessageButtonDebug: {
    top: 6,
  },
  teleportButton: {
    backgroundColor: theme.palette.teleportButton.main,
    padding: 0,
    display: "inline-block",
    position: "absolute",
    width: 238,
    height: 30,
    color: "white",
    left: 26,
    bottom: 12,
  },
  teleportButtonLabel: {
    position: "relative",
    display: "inline-block",
    textAlign: "center",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: 220,
    bottom: 4,
  },
  arrowRightIcon: {
    marginRight: 7,
    marginLeft: 7,
    position: "relative",
    top: 6,
  },
  changeRoomIconButton: {
    padding: 6,
    position: "absolute",
    top: 7,
    right: 10,
    color: theme.palette.text.primary,
  }
})
)

export { AttendeeItemStyles }