import { makeStyles, createStyles } from '@material-ui/core/styles';

const RoomOptionsSidebarStyles = makeStyles((theme: any) => createStyles({
  roomOptionsHeader: {
    fontSize: 16,
    fontWeight: 400,
    position: "absolute",
    left: 43,
    top: 10,
    color: "white"
  },
  peopleIcon: {
    position: 'absolute',
    top: 7,
    left: 10,
    color: "white"
  },
  closeSidebarButton: {
    padding: 4,
    position: 'absolute',
    top: 3,
    right: 7,
    color: "white"
  },
  roomOptionsHeaderHolder: {
    position: "relative",
    height: 90,
    backgroundColor: theme.palette.sidebarHeader.main,
    color: "white"
  },
  roomOptionsHeaderHolderTop: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    borderRadius: 4,
    height: 40,
    position: "absolute",
    top: 0,
    left: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  openRoomButton: {
    position: "relative",
    width: "100%",
    padding: 0,
    height: 40,
    left: 0,
    borderRadius: 4,
    top: 5,
    backgroundColor: theme.palette.controlCenterButton.main,
    color: theme.palette.text.primary
  },
  tabs: {
    position: "absolute",
    bottom: -3,
    height: 50,
  },
  roomOptionsTab: {
    color: theme.palette.text.primary
  },
  addIcon: {
    marginRight: 10,
    fontSize: 30,
    position: "relative",
    bottom: 2
  },
  loadingSpinner: {
    position: "relative",
    color: "grey",
    display: "inline-block",
    top: 25,
    marginBottom: 50,
  },
  roomOptionsTabPerson: {
    width: 120,
  },
  tabIndicator: {
    bottom: 3,
  }
})
)

export{ RoomOptionsSidebarStyles };