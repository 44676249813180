import { IconButton, Paper, Typography } from '@material-ui/core';
import React, { ReactElement, useContext } from 'react';
import EnterIcon from '@material-ui/icons/ExitToApp';

import { AttendeeContext, AttendeeContextType } from '../../../../../context/AttendeeContext';
import { PeopleAlt } from '@material-ui/icons';
import {
  PresenterContextType,
  PresenterContext,
} from "../../../../../context/PresenterContext";
import { StringVariableHelper } from "../../../../../Utilities/StringVariableHelper";

import styles from './RoomOptionsSidebarItem.module.css';
import { RoomOptionsSidebarItemStyles } from './RoomOptionsSidebarItemStyles';

interface Props {
  roomName: string,
  roomCount: number,
  roomID: string,
  roomCapacity: number,
  handleChangeRoom: () => any,
}

export default function RoomOptionsSidebarItem(props: Props): ReactElement {

  const {
    handleChangeRoomID
  } : AttendeeContextType = useContext(AttendeeContext);
  
  const { toggleIsOnStage, currentMode }: PresenterContextType =
    useContext(PresenterContext);
  
  const handleChangeRoomClick = async () => {
    //Change room to roomID sent down via props
    //Call room change method
    SHOWBOAT.UIEventManager.RoomChangeInProgress = true;
    try{
      let roomChange = await SHOWBOAT.SocketIOController.ChangeToSpecificRoom(props.roomID)

      if (roomChange.success === false) {

        if (roomChange.failReason === "Room Full") {
          //Show fail message, do not execute room change logic
          SHOWBOAT.UIEventManager.OnUIError.Raise("Sorry, room is full!");
        } else {
          //Show generic fail reason
          SHOWBOAT.UIEventManager.OnUIError.Raise("Error changing rooms")
        }

        SHOWBOAT.UIEventManager.RoomChangeInProgress = false;

      } else {

        //If we are not in presentation mode, ensure onStage is toggled to false
        if (currentMode !== StringVariableHelper.ShowboatModeType.presenter) {
          toggleIsOnStage(false);
        }

        props.handleChangeRoom();

        //Set local roomID equal to selected roomID
        SHOWBOAT.LocalAvatarDataManager.roomID = props.roomID;

        //Execute room change logic
        SHOWBOAT.UIEventManager.OnRoomChange.Raise(props.roomID);
        SHOWBOAT.UIEventManager.RoomChangeInProgress = false;
        handleChangeRoomID(props.roomID);

      }
    } catch(e){
      SHOWBOAT.UIEventManager.RoomChangeInProgress = false;
      SHOWBOAT.Logger.Error("RoomOptionsSidebarItems.ChangeToSpecificRoom", e);
    }
  }

  
  const classes = RoomOptionsSidebarItemStyles();
  
  return (
    <Paper className={classes.roomItemHolder}>
      <Typography variant="h2" className={classes.roomOptionName}>
        {props.roomName}
      </Typography>

      <div className={styles.border}></div>

      <PeopleAlt 
        className={classes.peopleIcon}
      />

      <Typography variant="body1" className={classes.roomOptionCount}>
        {props.roomCount}
      </Typography>

      <IconButton 
        className={classes.changeRoomIconButton}
        onClick={handleChangeRoomClick}
        disabled={
          ((SHOWBOAT.LocalAvatarDataManager.roomID === props.roomID)
            || (props.roomCount >= props.roomCapacity))
        }
      >
        <EnterIcon />
      </IconButton>
    </Paper>
  )
}
