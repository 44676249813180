import React, { ReactElement, useState, useContext } from "react";
import { Link, Paper, Typography } from "@material-ui/core";
import PlayIcon from "@material-ui/icons/PlayCircleFilledOutlined";

import EventCountdown from "../EventCountdown/EventCountdown";
import { IntakeContext, IntakeContextType } from "../../context/IntakeContext";

import styles from "./WelcomeElement.module.css";
import { WelcomeElementStyles } from "./WelcomeElementStyles";

interface Props {
  failReason: string;
}

export default function WelcomeElement(props: Props): ReactElement {
  const { homeWasClicked }: IntakeContextType = useContext(IntakeContext);

  const [showPlayButton, toggleShowPlayButton] = useState(true);

  const handleVideoPlayClick = () => {
    let video = document.getElementById("welcomeVideo") as HTMLVideoElement;

    video.play();

    toggleShowPlayButton(false);
  };

  const classes = WelcomeElementStyles();

  let welcomeElement: ReactElement;

  if (SHOWBOAT.ApplicationSkin.landingPageGraphicType === "Video") {
    welcomeElement = (
      <Paper
        elevation={0}
        classes={{
          root:
            props.failReason === "NotStarted" && !homeWasClicked
              ? `${classes.welcomeElementHolder} ${classes.welcomeElementHolderCountdown}`
              : `${classes.welcomeElementHolder}`,
        }}
      >
        <video
          controls
          id="welcomeVideo"
          className={styles.welcomeVideo}
          src={SHOWBOAT.ApplicationSkin.landingPageGraphicURL}
        ></video>

        {showPlayButton === true && (
          <PlayIcon
            className={classes.playIcon}
            onClick={handleVideoPlayClick}
          />
        )}
      </Paper>
    );
  } else {
    welcomeElement = (
      <Paper
        elevation={0}
        classes={{
          root:
            props.failReason === "NotStarted" && !homeWasClicked
              ? `${classes.welcomeElementHolder} ${classes.welcomeElementHolderCountdown}`
              : `${classes.welcomeElementHolder}`,
        }}
      >
        <img
          className={styles.welcomeImage}
          crossOrigin="anonymous"
          alt="welcome page element"
          src={SHOWBOAT.ApplicationSkin.landingPageGraphicURL}
        />

        <div
          className={
            props.failReason === "NotStarted"
              ? `${styles.linkHolder} ${styles.linkHolderNotStarted}`
              : styles.linkHolder
          }
        >
          <Link
            className={classes.link}
            target="_blank"
            href="https://showboat.live/privacy"
            style={{
              marginRight: 7,
            }}
          >
            Privacy
          </Link>
          <Typography style={{ fontSize: 10, display: "inline" }}>•</Typography>
          <Link
            className={classes.link}
            target="_blank"
            href="https://showboat.live/terms"
            style={{
              marginLeft: 7,
            }}
          >
            Terms of Use
          </Link>
        </div>
      </Paper>
    );
  }

  return (
    <React.Fragment>
      {props.failReason === "NotStarted" && !homeWasClicked ? (
        /* (!homeWasClicked || (ServerHelper.enableDoors && (new Date(ServerHelper.doorsOpen).getTime() > new Date().getTime())))) */
        /* && (ServerHelper.allowEarlyAccess && (new Date(ServerHelper.accessTime).getTime() > new Date().getTime())))  */
        <React.Fragment>
          <EventCountdown />

          {welcomeElement}
        </React.Fragment>
      ) : (
        welcomeElement
      )}
    </React.Fragment>
  );
}
