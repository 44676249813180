import { IconButton, Paper, Slider } from '@material-ui/core';
import React, { ReactElement, useContext } from 'react'
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeMuteIcon from "@material-ui/icons/VolumeMute";

import { LivestreamScreenshareVolumeStyles } from './LivestreamScreenshareVolumeStyles'
import { PresenterContext, PresenterContextType } from '../../../../context/PresenterContext';
import styles from "./LivestreamScreenshareVolume.module.css";

interface Props {
  localVideoVolume: number,
  handleVolumeChange: (newVolume: number) => void,
}

export default function LivestreamScreenshareVolume(props: Props): ReactElement {

  const {
    localVideoVolume,
    setLocalVideoVolume,
  } : PresenterContextType = useContext(PresenterContext);
  
  const classes = LivestreamScreenshareVolumeStyles();
  
  const handleVolumeChange = (e, newValue: number) => {
    props.handleVolumeChange(newValue);

    e.stopPropagation();
  }

  const handleMuteClick = () => {
    props.handleVolumeChange(0);
  }

  const handleFullVolumeClick = () => {
    props.handleVolumeChange(100)
  }
  
  return (
    <Paper className={classes.volumeHolder} id="livestreamScreenshareVolume">
      <IconButton onClick={handleMuteClick} className={classes.volumeIcon}>
        <VolumeMuteIcon />
      </IconButton>

      <div className={styles.sliderHolder}>
        <Slider
          color="primary"
          className={classes.volumeSlider}
          value={props.localVideoVolume}
          onChange={handleVolumeChange}
        ></Slider>
      </div>

      <IconButton
        className={classes.volumeIcon}
        onClick={handleFullVolumeClick}
      >
        <VolumeUpIcon />
      </IconButton>
    </Paper>
  );
}
