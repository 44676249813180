import { StringVariableHelper } from "../Utilities/StringVariableHelper";

const useGetDevicePreferences = () => {
  const getDevicePreferences = async () => {
    let cameraIDString =
      StringVariableHelper.LocalStorageProperties.PreferredCameraID;

    //Camera cookie
    if (
      SHOWBOAT.SystemInformation.HasWebCameraPermissions &&
      SHOWBOAT.SystemInformation.HaveCameraNames
    ) {
      if (localStorage.getItem(cameraIDString) !== null) {
        let preferredCameraID = localStorage.getItem(cameraIDString);
        if (preferredCameraID !== undefined) {
          await SHOWBOAT.StreamingUserMedia.SetCameraDeviceID(
            preferredCameraID
          );
        }
      }
    }

    let micIDString =
      StringVariableHelper.LocalStorageProperties.PreferredMicID;

    //Mic cookie
    if (
      SHOWBOAT.SystemInformation.HasMicrophonePermissions &&
      SHOWBOAT.SystemInformation.HaveMicrophoneNames
    ) {
      if (localStorage.getItem(micIDString) !== null) {
        let preferredMicrophoneID = localStorage.getItem(micIDString);
        if (preferredMicrophoneID !== undefined) {
          await SHOWBOAT.StreamingUserMedia.SetMicrophoneDeviceID(
            preferredMicrophoneID
          );
        }
      }
    }

    //Speaker Cookie
    if (
      SHOWBOAT.SystemInformation.IsSetSinkIdSupported &&
      SHOWBOAT.SystemInformation.HaveSpeakerNames
    ) {
      if (
        localStorage.getItem(
          StringVariableHelper.LocalStorageProperties.PreferredSpeakerID
        ) !== null
      ) {
        let preferredSpeakerID = localStorage.getItem(
          StringVariableHelper.LocalStorageProperties.PreferredSpeakerID
        );
        if (preferredSpeakerID !== undefined) {
          SHOWBOAT.StreamingUserMedia.SetSpeakerDevice(preferredSpeakerID);
        }
      }
    }
  };

  const checkCachedPreferredMicState = (props) => {
    //If we are in intake and no back clicked, return true
    if (props && props.intake && !props.backClicked) return true;

    return localStorage.getItem(
      StringVariableHelper.LocalStorageProperties.PreferredMicState
    ) === "true"
      ? true
      : false;
  };

  const checkCachedPreferredCameraState = (props) => {
    //If we are in intake and no back clicked, return true
    if (props && props.intake && !props.backClicked) return true;

    return localStorage.getItem(
      StringVariableHelper.LocalStorageProperties.PreferredCameraState
    ) === "true"
      ? true
      : false;
  };

  const storeCachedPreferredMicState = (state) => {
    localStorage.setItem(
      StringVariableHelper.LocalStorageProperties.PreferredMicState,
      JSON.stringify(state)
    );
  };

  const storeCachedPreferredCamState = (state) => {
    localStorage.setItem(
      StringVariableHelper.LocalStorageProperties.PreferredCameraState,
      JSON.stringify(state)
    );
  };

  return {
    getDevicePreferences,
    checkCachedPreferredCameraState,
    checkCachedPreferredMicState,
    storeCachedPreferredCamState,
    storeCachedPreferredMicState
  };
};

export default useGetDevicePreferences;
