import React, { useState, useEffect } from "react";
import { ServerHelper } from "../Utilities/ServerHelper";
import { StringVariableHelper } from "../Utilities/StringVariableHelper";

export interface BioFormContextType {
  contextFirstName: string;
  setContextFirstName: (firstName: string) => void;
  contextLastName: string;
  setContextLastName: (lastName: string) => void;
  contextCompany: string;
  setContextCompany: (company: string) => void;
  contextEmail: string;
  setContextEmail: (email: string) => void;
  contextPhone: string;
  setContextPhone: (phoneNumber: string) => void;
  contextAddress: any;
  setContextAddress: (address: any) => void;
  checkCachedIntakeData: () => void;
}

export const BioFormContext = React.createContext(null);

export const BioFormContextProvider = (props) => {
  const [contextFirstName, setContextFirstName] = useState(
    SHOWBOAT.LocalAvatarDataManager.registrationData &&
      SHOWBOAT.LocalAvatarDataManager.registrationData.firstName !== undefined
      ? SHOWBOAT.LocalAvatarDataManager.registrationData.firstName
      : ""
  );
  const [contextLastName, setContextLastName] = useState(
    SHOWBOAT.LocalAvatarDataManager.registrationData &&
      SHOWBOAT.LocalAvatarDataManager.registrationData.lastName !== undefined
      ? SHOWBOAT.LocalAvatarDataManager.registrationData.lastName
      : ""
  );
  const [contextCompany, setContextCompany] = useState(
    SHOWBOAT.LocalAvatarDataManager.registrationData &&
      SHOWBOAT.LocalAvatarDataManager.registrationData.company !== undefined
      ? SHOWBOAT.LocalAvatarDataManager.registrationData.company
      : ""
  );
  const [contextEmail, setContextEmail] = useState(
    SHOWBOAT.LocalAvatarDataManager.registrationData &&
      SHOWBOAT.LocalAvatarDataManager.registrationData.emailAddress !==
        undefined
      ? SHOWBOAT.LocalAvatarDataManager.registrationData.emailAddress
      : ""
  );
  const [contextPhone, setContextPhone] = useState(
    SHOWBOAT.LocalAvatarDataManager.registrationData &&
      SHOWBOAT.LocalAvatarDataManager.registrationData.phone !== undefined
      ? SHOWBOAT.LocalAvatarDataManager.registrationData.phone
      : ""
  );
  const [contextAddress, setContextAddress] = useState(
    SHOWBOAT.LocalAvatarDataManager.registrationData &&
      SHOWBOAT.LocalAvatarDataManager.registrationData.address !== undefined
      ? SHOWBOAT.LocalAvatarDataManager.registrationData.address
      : ""
  );

  const checkCachedIntakeData = () => {
      let intakeDataStringVariable =
          StringVariableHelper.LocalStorageProperties.IntakeData;

      //Make sure we are not in dev mode before executing any of this logic
      if (process.env.REACT_APP_API_STAGE !== "dev") {
      //Check if we already have intake data cookied for this login code
      if (localStorage.getItem(intakeDataStringVariable) !== null) {
          let intakeDataObj = JSON.parse(
              localStorage.getItem(intakeDataStringVariable)
          );

          //Check if intake data obj contains this login code
          if (intakeDataObj.hasOwnProperty(ServerHelper.loginCode)) {
              //We have intake data for this login code, so overwrite context values, and local avatar data values
              //Ensure properties are visible on the booking first

              if (SHOWBOAT.ApplicationSkin.intakeFields[0].visible) {
                  SHOWBOAT.LocalAvatarDataManager.registrationData.firstName =
                      intakeDataObj[ServerHelper.loginCode].firstName;
                  SHOWBOAT.LocalAvatarDataManager.firstName =
                      intakeDataObj[ServerHelper.loginCode].firstName;

                  setContextFirstName(
                      intakeDataObj[ServerHelper.loginCode].firstName
                  );
              }

              if (SHOWBOAT.ApplicationSkin.intakeFields[1].visible) {
                  SHOWBOAT.LocalAvatarDataManager.registrationData.lastName =
                      intakeDataObj[ServerHelper.loginCode].lastName;
                  SHOWBOAT.LocalAvatarDataManager.lastName =
                      intakeDataObj[ServerHelper.loginCode].lastName;

                  setContextLastName(
                      intakeDataObj[ServerHelper.loginCode].lastName
                  );
              }

              if (SHOWBOAT.ApplicationSkin.intakeFields[2].visible) {
                  SHOWBOAT.LocalAvatarDataManager.registrationData.company =
                      intakeDataObj[ServerHelper.loginCode].company;
                  SHOWBOAT.LocalAvatarDataManager.company =
                      intakeDataObj[ServerHelper.loginCode].company;

                  setContextCompany(
                      intakeDataObj[ServerHelper.loginCode].company
                  );
              }

              if (SHOWBOAT.ApplicationSkin.intakeFields[3].visible) {
                  SHOWBOAT.LocalAvatarDataManager.registrationData.emailAddress =
                      intakeDataObj[ServerHelper.loginCode].emailAddress;

                  setContextEmail(
                      intakeDataObj[ServerHelper.loginCode].emailAddress
                  );
              }
          } else {
              //If no intake data stored for this login code, override with the master (if it is there)
              //Ensure properties are visible on the booking first

              //Double check the master object exists
              if (intakeDataObj["master"] !== undefined) {
                  if (SHOWBOAT.ApplicationSkin.intakeFields[0].visible) {
                      SHOWBOAT.LocalAvatarDataManager.registrationData.firstName =
                          intakeDataObj["master"].firstName;

                      SHOWBOAT.LocalAvatarDataManager.firstName =
                          intakeDataObj["master"].firstName;

                      setContextFirstName(intakeDataObj["master"].firstName);
                  }

                  if (SHOWBOAT.ApplicationSkin.intakeFields[1].visible) {
                      SHOWBOAT.LocalAvatarDataManager.registrationData.lastName =
                          intakeDataObj["master"].lastName;

                      SHOWBOAT.LocalAvatarDataManager.lastName =
                          intakeDataObj["master"].lastName;

                      setContextLastName(intakeDataObj["master"].lastName);
                  }

                  if (SHOWBOAT.ApplicationSkin.intakeFields[2].visible) {
                      SHOWBOAT.LocalAvatarDataManager.registrationData.company =
                          intakeDataObj["master"].company;

                      SHOWBOAT.LocalAvatarDataManager.company =
                          intakeDataObj["master"].company;

                      setContextCompany(intakeDataObj["master"].company);
                  }

                  if (SHOWBOAT.ApplicationSkin.intakeFields[3].visible) {
                      SHOWBOAT.LocalAvatarDataManager.registrationData.emailAddress =
                          intakeDataObj["master"].emailAddress;

                      setContextEmail(intakeDataObj["master"].emailAddress);
                  }
              }
          }
      }
      }
  };

  useEffect(() => {
    checkCachedIntakeData();
  }, []);

  const bioFormProviderValue: BioFormContextType = {
    contextFirstName,
    setContextFirstName,
    contextLastName,
    setContextLastName,
    contextCompany,
    setContextCompany,
    contextEmail,
    setContextEmail,
    contextPhone,
    setContextPhone,
    contextAddress,
    setContextAddress,
    checkCachedIntakeData
  };

  return (
    <BioFormContext.Provider value={bioFormProviderValue}>
      {props.children}
    </BioFormContext.Provider>
  );
};
