import { makeStyles, createStyles } from '@material-ui/core/styles';

const DeviceToggleStyles = makeStyles((theme: any) => createStyles({
  deviceToggleWrapper: {
    width: 560,
    height: 460,
    backgroundColor: theme.palette.intakePaper.main,
    position: "absolute",
    right: 0,
    bottom: -8,
    textAlign: "left",
    borderRadius: 10,
    animation: "fadeIn .5s",
    WebkitAnimation: "fadeIn .5s",
    MozAnimation: "fadeIn .5s",
    [theme.breakpoints.down('sm')]: {
      width: 400,
      height: 450,
      bottom: 0,
    }
  },
  deviceToggleHeader: {
    color: theme.palette.text.main,
    fontSize: 24,
    position: "relative",
    top: 34,
    left: 50,
    display: "inline-block",
    width: 440,
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      left: 30,
      fontSize: 20,
      width: 350,
    }
  },
  deviceToggleText: {
    fontSize: 20,
    position: "relative",
    top:75,
    left: 50,
    display: "inline-block",
    fontFamily: "Open Sans, sans-serif",
    width: 430,
    [theme.breakpoints.down('sm')]: {
      width: 325,
      fontSize: 18,
      left: 30,
    }
  },
  deviceToggleTextBottom: {
    top: 160,
  },
  yesButton: {
    backgroundColor: "#7B7B7B",
    height: 46,
    width: 115,
    color: "white",
  },
  noButton: {
    height: 46,
    width: 115,
  },
  backButton: {
    height: 46,
    width: 115,
    position: "relative",
    right: 130,
    [theme.breakpoints.down('sm')]: {
      right: -10,
    }
  },
  cameraAndMicButton: {
    display: "inline-block",
    padding: 0,
    width: 269,
    height: 47,
    backgroundColor: theme.palette.intakeBlue.main,
    position: "absolute",
    top: 172,
    left: 50,
    color: "white",
    [theme.breakpoints.down('sm')]: {
      left: 30,
      top: 185,
    }
  },
  micButton: {
    padding: 0,
    position: "absolute",
    top: 288,
    left: 50,
    width: 160,
    height: 47,
    color: "#00B6FF",
    backgroundColor: "rgba(255, 255, 255, 0.08)",
    [theme.breakpoints.down('sm')]: {
      left: 30,
      top: 293,
    }
  }
})
)

export { DeviceToggleStyles };