export class StringVariableHelper {
  //Server variables
  public static LocalVideoVolumeEventName = "PRESENTER#LocalVideoVolume";
  public static PresentationEventName = "PRESENTER#Presentation";
  public static SilenceAudienceEventName = "PRESENTER#SilenceAudience";
  public static SpatialAudioEventName = "PRESENTER#SpatialAudio";
  public static AudioDistanceEventName = "PRESENTER#AudioDistance";
  public static EventMuteEventName = "PRESENTER#EventMute";
  public static AdvancedDebuggingMessageName = "AdvancedDebugging";
  public static ForceMoveMessageName = "ForceMove";

  public static ShowboatPartitions = {
    backstage: "backstage",
    attendees: "attendees",
    presenter: "presenter",
  };

  public static ShowboatRoles = {
    presenter: "presenter",
    attendees: "attendees",
  };

  //UI Variables
  public static ShowboatModeType = {
    attendees: "attendees",
    presenter: "presenter",
    backstage: "backstage",
  };

  public static PresenterToolNames = {
    livestream: "livestream",
    localVideo: "localVideo",
    screenshare: "screenshare",
    slides: "slides",
    webcam: "webcam",
  };

  public static LoginFailReasons = {
    DeletedLoginCode: "DeletedLoginCode",
    Ended: "Ended",
    CapacityFull: "CapacityFull",
    NotStarted: "NotStarted",
    SystemCheck: "SystemCheck",
    SystemCheckLoginCodes: [
      "systemcheck",
      "SystemCheck",
      "Systemcheck",
      "systemCheck",
    ],
    
  };

  public static PopupErrorText = {
    socketDisconnect:
      "Temporarily disconnected from server, attempting auto reconnect.",
    webcamDisconnect:
      "Your video stream is temporarily interrupted, attempting auto reconnect.",
  };

  public static LocalStorageProperties = {
    SystemCheckID: "systemCheckID",
    BannedLoginCodes: "bannedLoginCodes",
    PreferredCameraID: "preferredCameraID",
    PreferredCameraState: "preferredCameraState",
    PreferredMicID: "preferredMicrophoneID",
    PreferredMicState: "preferredMicrophoneState",
    PreferredSpeakerID: "preferredSpeakerID",
    CookieWarning: "hasBeenShownCookieWarning",
    AttendeesIDArray: "attendeesIDArray",
    IntakeData: "intakeData",
    HasCompletedIntakeData: "hasCompletedIntakeData",
    PreferredColorHex: "preferredColorHex",
    PreferredFaceIndex: "preferredFaceIndex"
  };
}

(window as any).StringVariableHelper = StringVariableHelper;
