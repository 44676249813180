import { Button, IconButton, Paper, Typography } from "@material-ui/core";
import React, { ReactElement, useContext, useEffect } from "react";
import HangupIcon from "@material-ui/icons/CallEnd";
import RightArrowIcon from "@material-ui/icons/ArrowForward";
import { isMobile } from "react-device-detect";

import { AppContext, AppContextType } from "../../../../context/AppContext";
import { ServerHelper } from "../../../../Utilities/ServerHelper";
import { UIHelper } from "../../../../Utilities/UIHelper";

import { HangUpSidebarStyles } from "./HangUpSidebarStyles";

export default function HangUpSidebar(): ReactElement {
  const { setCurrentSidebarMode, isPresenter }: AppContextType =
    useContext(AppContext);

  //Hang up click
  const handleHangUpClick = () => {
    //If ServerHelper.hangUpURL is undefined or an empty string, just reload to home page. Otherwise, redirect to specified hangUpURL for event
    if (ServerHelper.hangUpURL === undefined || ServerHelper.hangUpURL === "") {
      //Ensure bypass gets cut from the URL
      
      window.location.replace(
        UIHelper.removeQueryParam("bypass", window.location.href)
      );
    } else {
      //Double-check if URL starts with http first
      if (!ServerHelper.hangUpURL.trim().startsWith("http")) {
        window.location.href = "https://" + ServerHelper.hangUpURL;
        return false;
      } else {
        window.location.href = ServerHelper.hangUpURL;
        return false;
      }
    }
  };

  const classes = HangUpSidebarStyles();

  //Check for mobile to apply CSS classes for vh unit hack
  useEffect(() => {
    let contentHolder = document.getElementById("hangUpContentHolder");
    if (contentHolder) {
      if (isMobile) {
        if (isPresenter) {
          contentHolder.classList.add(`${classes.hangUpContentHolderMobile}`);
        } else {
          contentHolder.classList.add(
            `${classes.hangUpContentHolderNonPresenterMobile}`
          );
        }
      }
    }
  }, [
    classes.hangUpContentHolderMobile,
    classes.hangUpContentHolderNonPresenterMobile,
    isPresenter,
  ]);

  return (
    <React.Fragment>
      <Paper className={classes.hangUpHeaderHolder}>
        <HangupIcon className={classes.hangUpIcon} />

        <Typography variant="h1" className={classes.hangUpHeader}>
          Exit Meeting
        </Typography>

        <IconButton
          className={classes.closeIconButton}
          onClick={() => setCurrentSidebarMode("")}
        >
          <RightArrowIcon />
        </IconButton>
      </Paper>

      <Paper
        className={
          isPresenter
            ? classes.hangUpContentHolder
            : `${classes.hangUpContentHolder} ${classes.hangUpContentHolderNonPresenter}`
        }
        id="hangUpContentHolder"
      >
        <Paper className={classes.hangUpButtonsHolder}>
          <Button
            className={classes.hangUpButton}
            variant="contained"
            onClick={handleHangUpClick}
          >
            <HangupIcon className={classes.hangUpIconButton} />
            EXIT
          </Button>

          <Button
            className={classes.closeButton}
            variant="text"
            onClick={() => setCurrentSidebarMode("")}
          >
            CANCEL
          </Button>
        </Paper>
      </Paper>
    </React.Fragment>
  );
}
