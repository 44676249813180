import { makeStyles, createStyles } from '@material-ui/core/styles';

const ExitEventStyles = makeStyles((theme: any) => createStyles({
  exitEventText: {
    fontSize: 14,
    position: "relative",
    textAlign: "left",
    left: 11,
    marginBottom: 10,
  },
})
)

export { ExitEventStyles };