import { Button, Tooltip } from "@material-ui/core";
import React, { ReactElement } from "react";

import { ControlCenterButtonStyles } from "./ControlCenterButtonStyles";

interface Props {
  children: any;
  title: string;
  enterDelay: number;
  enterNextDelay: number;
  onClick: () => any;
  buttonClasses: string;
  onTooltipOpen?: () => any;
  buttonOnMouseLeave?: () => any;
  buttonDisable?: boolean;
  tooltipOpen?: boolean;
  buttonCustomStyles?: any;
  buttonLabelClasses?: string;
  style?: any;
}

export default function ControlCenterButton(props: Props): ReactElement {
  const classes = ControlCenterButtonStyles();

  return (
    <Tooltip
      title={props.title}
      arrow
      placement="left"
      classes={{
        tooltip: classes.tooltip,
        popper: classes.tooltipPopover,
        arrow: classes.tooltipArrow,
      }}
      enterDelay={props.enterDelay}
      enterNextDelay={props.enterNextDelay}
      onOpen={props.onTooltipOpen ? props.onTooltipOpen : null}
      {...(props.tooltipOpen !== undefined ? { open: props.tooltipOpen } : {})}
    >
      <Button
        className={props.buttonClasses}
        variant="contained"
        onClick={props.onClick}
        onMouseLeave={
          props.buttonOnMouseLeave ? props.buttonOnMouseLeave : null
        }
        disabled={props.buttonDisable}
        style={props.buttonCustomStyles ? props.buttonCustomStyles : null}
        classes={{
          label: props.buttonLabelClasses ? props.buttonLabelClasses : null,
        }}
        {...(props.style !== undefined ? { style: props.style } : {})}
      >
        {props.children}
      </Button>
    </Tooltip>
  );
}
