import { Button, Typography } from "@material-ui/core";
import { Warning, Autorenew, Check } from "@material-ui/icons";
import { SystemCheckHeaderStyles } from "./SystemCheckHeaderStyles";
import { isChrome } from "react-device-detect";
import styles from "./SystemCheckHeader.module.css";
import React, { ReactElement } from "react";

interface Props {
  currentState: string;
  sendToSupport: () => void;
  hasCameraPermissions: boolean;
  hasMicPermissions: boolean;
}

export default function SystemCheckHeader(props: Props): ReactElement {
  const classes = SystemCheckHeaderStyles();

  return (
    <>
      {
        {
          running: (
            <>
              <div
                className={styles.systemCheckCircle}
                style={{ border: "1px solid #FFB700" }}
              >
                <Autorenew
                  className={`${styles.headerIcon} ${styles.loadingIcon}`}
                  style={{ color: "#FFB700" }}
                />
              </div>

              <Typography variant="h1" className={classes.systemsCheckHeader}>
                Systems Check Running
              </Typography>

              {(!props.hasCameraPermissions || !props.hasMicPermissions) && (
                <div className={styles.moreInformationHolder}>
                  <Typography
                    variant="h2"
                    className={classes.moreInformationHeader}
                  >
                    Action Needed
                  </Typography>

                  <ul>
                    {!props.hasCameraPermissions && (
                      <li>User action needed on Camera Permissions</li>
                    )}

                    {!props.hasMicPermissions && (
                      <li>User action needed on Microphone Permissions</li>
                    )}
                  </ul>
                </div>
              )}
            </>
          ),
          completed: (
            <>
              <div
                className={styles.systemCheckCircle}
                style={{ border: "1px solid #93FF00" }}
              >
                <Check
                  className={styles.headerIcon}
                  style={{ color: "#93FF00" }}
                />
              </div>

              <Typography variant="h1" className={classes.systemsCheckHeader}>
                Systems Check Complete
              </Typography>

              <Typography
                variant="h2"
                className={classes.systemsCheckSubheader}
              >
                No incompatibility detected
              </Typography>

              {!isChrome && (
                <div className={`${styles.moreInformationHolder} ${styles.moreInformationHolderCompleted}`}>
                  <Typography
                    variant="h2"
                    className={classes.moreInformationHeader}
                  >
                    More Information
                  </Typography>

                  <ul>
                    <li>Your browser may not support listing speakers</li>
                  </ul>
                </div>
              )}

              <div
                className={styles.sendToSupportHolder}
                style={{ border: "1px solid #93FF00" }}
              >
                <Typography
                  variant="body1"
                  className={classes.sendToSupportText}
                >
                  Submit System Check to Support
                </Typography>

                <Button
                  className={classes.submitReportButton}
                  onClick={() => props.sendToSupport()}
                >
                  Submit Report
                </Button>
              </div>
            </>
          ),
          failed: (
            <>
              <div
                className={styles.systemCheckCircle}
                style={{ border: "1px solid #FF0000" }}
              >
                <Warning
                  className={styles.headerIcon}
                  style={{ color: "#FF0000" }}
                />
              </div>

              <Typography variant="h1" className={classes.systemsCheckHeader}>
                Systems Check Complete
              </Typography>

              <Typography
                variant="h2"
                className={classes.systemsCheckSubheader}
              >
                Incompatibility detected
              </Typography>

              <div
                className={styles.sendToSupportHolder}
                style={{ border: "1px solid #FF0000" }}
              >
                <Typography
                  variant="body1"
                  className={classes.sendToSupportText}
                >
                  Submit System Check to Support
                </Typography>

                <Button
                  className={classes.submitReportButton}
                  onClick={() => props.sendToSupport()}
                >
                  Submit Report
                </Button>
              </div>
            </>
          ),
        }[props.currentState]
      }
    </>
  );
}
