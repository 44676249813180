import React, { ReactElement, useEffect, useState } from "react";
import { Button, Paper, Typography } from "@material-ui/core";
import { isSafari } from "react-device-detect";

import { ServerHelper } from "../../Utilities/ServerHelper";
import useBypassIntake from "../../hooks/useBypassIntake";
import { UIHelper } from "../../Utilities/UIHelper";

import styles from "./FullScreenLoadingScreen.module.css";
import { FullScreenLoadingScreenStyles } from "./FullScreenLoadingScreenStyles";

export default function FullScreenLoadingScreen(): ReactElement {
  const { executeBypass } = useBypassIntake();

  const [loadingProgress, setLoadingProgress] = useState("");

  const [safariBypass, setSafariBypass] = useState(false);

  const classes = FullScreenLoadingScreenStyles();

  useEffect(() => {
    SHOWBOAT.ShowboatLoader.OnLoadScreenProgress.Add(
      handleLoadingProgressChange
    );

    //Check if we are doing a bypass and on Safari
    //If so, show join button first
    if (ServerHelper.bypass && isSafari) {
      setSafariBypass(true);
    }

    return function cleanup() {
      SHOWBOAT.ShowboatLoader.OnLoadScreenProgress.Remove(
        handleLoadingProgressChange
      );
    };
  }, []);

  const handleLoadingProgressChange = (progress: string) => {
    setLoadingProgress(progress);
  };

  const handleJoinClick = async () => {
    //Show normal spinner and execute bypass
    setSafariBypass(false);

    let bypassResult = await executeBypass();

    if (!bypassResult || bypassResult === "NoIntakeData") {
      //Refresh the page with no query param
      window.location.replace(
        UIHelper.removeQueryParam("bypass", window.location.href)
      );
    }
  };

  return (
    <Paper square className={classes.paper}>
      <div className={styles.loadingSpinnerHolder}>
        {safariBypass ? (
          <Button
            className={classes.joinButton}
            variant="contained"
            onClick={handleJoinClick}
          >
            JOIN
          </Button>
        ) : (
          <React.Fragment>
            <img
              src="assets/images/sb-logo.png"
              alt="showboat logo"
              className={styles.imageBack}
            ></img>
            <img
              src="assets/images/sb-spinner.png"
              alt="showboat loading spinner"
              className={styles.imageSpinner}
            ></img>

            <Typography variant="h1" className={classes.loadingProgress}>
              {loadingProgress}
            </Typography>
          </React.Fragment>
       )}
      </div>
    </Paper>
  );
}
