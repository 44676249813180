import React, { ReactElement, useEffect, useState } from 'react';

import styles from './MicTestModule.module.css';

interface Props {
  intake: boolean,
  useMic: boolean,
}

export default function MicTestModule(props: Props): ReactElement {

  const [audioLevel, setAudioLevel] = useState(0);

  useEffect(() => {
    
    //Register audio reactive
    SHOWBOAT.StreamingUserMedia.AddAudioListener(onAudioLevel);

    return function cleanup() {
      SHOWBOAT.StreamingUserMedia.RemoveAudioListener(onAudioLevel);
    }
    
  }, []);

  //Listen for muting mic in wrapper component, set audio level to 0
  useEffect(() => {

    if (!props.useMic) {
      setAudioLevel(0);
    }
    
  }, [props.useMic])

  //Audio level change handler
  const onAudioLevel = (level: number) => {
    setAudioLevel(level);
  }

  
  //Change class based on if intake version or not
  let microphoneTestWrapperClasses : string;

  if (props.intake) {
    microphoneTestWrapperClasses = `${styles.microphoneTestWrapper} ${styles.microphoneTestWrapperIntake}`;
  } else {
    microphoneTestWrapperClasses = `${styles.microphoneTestWrapper} ${styles.microphoneTestWrapperPopup}`;
  }

  let container1Style = {
    height: 
      props.intake
      ? 5 + audioLevel * 30 + 'px'
      : 3 + audioLevel * 75 + 'px',
    backgroundColor: props.intake ? "#00B6FF" : "#FFCE00",
  }

  let container2Style = {
    height: 
      props.intake
      ? 5 + audioLevel * 45 + 'px'
      : 3 + audioLevel * 110 + 'px',
    backgroundColor: props.intake ? "#00B6FF" : "#FFCE00",
  }

  let container3Style = {
    height:
      props.intake
      ? 5 + audioLevel * 30 + 'px'
      : 3 + audioLevel * 75 + 'px',
    backgroundColor: props.intake ? "#00B6FF" : "#FFCE00",
  }

  return (
    <div className={microphoneTestWrapperClasses}>
      <div className={styles.microphoneTestHolder}>
        <div 
          className={
            props.intake
            ? `${styles.micBarOne} ${styles.micBarOneIntake}`
            : styles.micBarOne
          } 
          style={container1Style}
          >
        </div>
        <div 
          className={
            props.intake 
            ? `${styles.micBarOneDown} ${styles.micBarOneDownIntake}`
            : styles.micBarOneDown

          } 
          style={container1Style}>
        </div>

        <div 
          className={
            props.intake
            ? `${styles.micBarTwo} ${styles.micBarTwoIntake}`
            : styles.micBarTwo
          } 
          style={container2Style}>
        </div>
        <div 
          className={
            props.intake
            ? `${styles.micBarTwoDown} ${styles.micBarTwoDownIntake}`
            : styles.micBarTwoDown
          } 
          style={container2Style}>
        </div>

        <div 
          className={
            props.intake
            ? `${styles.micBarThree} ${styles.micBarThreeIntake}`
            : styles.micBarThree
          } 
          style={container3Style}
        >
        </div>
        <div 
          className={
            props.intake
            ? `${styles.micBarThreeDown} ${styles.micBarThreeDownIntake}`
            : styles.micBarThreeDown
          } 
          style={container3Style}
        >
        </div>

      </div>
    </div>
  )
}
